.buyer-faq {
  /* Faq */
}
.buyer-faq .profile_section {
  position: relative;
  padding: 45px 0px;
}
.buyer-faq .profile_heading h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: #000;
}
.buyer-faq .faq_section {
  padding-right: 50px;
  margin-bottom: 25px;
}
.buyer-faq .faq_section .accordion-button {
  font-size: 18px;
  font-weight: 600;
  line-height: 29px;
}
.buyer-faq .faq_section .accordion-item {
  border-left: 0;
  border-right: 0;
  margin-bottom: 15px;
  border-bottom: 0px;
}
.buyer-faq .faq_section #headingOne .accordion-button.collapsed {
  border-top: 0px;
}
.buyer-faq .faq_section .accordion-header .accordion-button.collapsed {
  border: 1px #ddd solid;
  border-radius: 4px;
}
.buyer-faq .faq_section .accordion-collapse.collapse.show {
  box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.1215686275);
}
.buyer-faq .faq_section .accordion-button:not(.collapsed) {
  background: #183823;
  color: var(--var-white-color);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.buyer-faq .faq_section .accordion-button:not(.collapsed)::after {
  background-image: url(/public/assets/images/faq-1.png);
  transform: var(--bs-accordion-btn-icon-transform);
}
.buyer-faq .faq_section .accordion-button::after {
  background-image: url(/public/assets/images/faq-2.png);
}
.buyer-faq .faq_section .accordion-button:focus {
  z-index: 3;
  border-color: transparent;
  outline: 0;
  box-shadow: none;
}
.buyer-faq .faq_section .accordion-body p {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #475156;
}
.buyer-faq .faq_section .accordion-body ul {
  padding-left: 15px;
}
.buyer-faq .faq_section .accordion-body ul li {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--var-primary-text-color);
  margin-bottom: 12px;
}
.buyer-faq .faq_right {
  border: 1px solid rgba(24, 56, 35, 0.5019607843);
  background: rgba(24, 56, 35, 0.0784313725);
  border-radius: 4px;
  padding: 20px;
}
.buyer-faq .faq_right h2 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}
.buyer-faq .faq_right p {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #475156;
}
.buyer-faq .faq_right input {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #929FA5;
  border: 1px solid #183823;
  border-radius: 2px;
  height: 44px;
  width: 100%;
  margin-bottom: 25px;
  padding-left: 10px;
}
.buyer-faq .faq_right textarea {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #929FA5;
  border: 1px solid #183823;
  border-radius: 2px;
  height: 84px;
  width: 100%;
  margin-bottom: 25px;
  padding-left: 10px;
}
.buyer-faq .faq_right button {
  background: #183823;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.1215686275);
  padding: 15px 48px 15px 48px;
  border-radius: 5px;
  color: var(--var-white-color);
  border: 0px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20.08px;
  text-transform: uppercase;
}

@media (max-width: 767px) {
  .buyer-faq .faq_section {
    padding-right: 0px;
  }
}/*# sourceMappingURL=BuyerFaqContent.css.map */