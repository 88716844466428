.buyer-faq {

    .profile_section {
        position: relative;
        padding: 45px 0px;
    }

    .profile_heading h2 {
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        color: #000;
    }


    /* Faq */
    .faq_section {
        padding-right: 50px;
        margin-bottom: 25px;
    }

    .faq_section .accordion-button {
        font-size: 18px;
        font-weight: 600;
        line-height: 29px;

    }

    .faq_section .accordion-item {
        border-left: 0;
        border-right: 0;
        margin-bottom: 15px;
        border-bottom: 0px;
    }

    .faq_section #headingOne .accordion-button.collapsed {
        border-top: 0px;
    }

    .faq_section .accordion-header .accordion-button.collapsed {
        border: 1px #ddd solid;
        border-radius: 4px;
    }

    .faq_section .accordion-collapse.collapse.show {
        box-shadow: 0px 8px 40px 0px #0000001F;
    }

    .faq_section .accordion-button:not(.collapsed) {
        background: #183823;
        color: var(--var-white-color);
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    .faq_section .accordion-button:not(.collapsed)::after {
        background-image: url(/public/assets/images/faq-1.png);
        transform: var(--bs-accordion-btn-icon-transform);
    }

    .faq_section .accordion-button::after {
        background-image: url(/public/assets/images/faq-2.png);
    }

    .faq_section .accordion-button:focus {
        z-index: 3;
        border-color: transparent;
        outline: 0;
        box-shadow: none;
    }

    .faq_section .accordion-body p {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        color: #475156;

    }

    .faq_section .accordion-body ul {
        padding-left: 15px;
    }

    .faq_section .accordion-body ul li {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: var(--var-primary-text-color);
        margin-bottom: 12px;
    }

    .faq_right {
        border: 1px solid #18382380;
        background: #18382314;
        border-radius: 4px;
        padding: 20px;
    }

    .faq_right h2 {
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;

    }

    .faq_right p {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        color: #475156;
    }

    .faq_right input {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #929FA5;
        border: 1px solid #183823;
        border-radius: 2px;
        height: 44px;
        width: 100%;
        margin-bottom: 25px;
        padding-left: 10px;
    }

    .faq_right textarea {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #929FA5;
        border: 1px solid #183823;
        border-radius: 2px;
        height: 84px;
        width: 100%;
        margin-bottom: 25px;
        padding-left: 10px;
    }

    .faq_right button {
        background: #183823;
        box-shadow: 0px 0px 14px 0px #0000001F;
        padding: 15px 48px 15px 48px;
        border-radius: 5px;
        color: var(--var-white-color);
        border: 0px;
        font-size: 14px;
        font-weight: 500;
        line-height: 20.08px;
        text-transform: uppercase;
    }
}
@media (max-width:767px){
    .buyer-faq{
    .faq_section {
        padding-right: 0px;
    }
}
}