.footer-sec {
    background-color: #183823;
    color: #fff;
}

.logo-sec img {
    margin-bottom: 15px;
    width: 160px;
}

.search-sec {
    text-align: left;
    position: relative;
}

.search-sec h5 {
    font-size: 16px;
}

.search-sec input {
    background: transparent;
    border: 1px solid #fff;
    padding: 8px 35px;
    color: #fff;
    border-radius: 8px;
    width: 100%;
}

.footer-menu_1 {
    padding-left: 48px;
}

.search-sec input::placeholder {
    color: #fff;
}

.mail-box-sec {
    position: relative;
    margin-top: 15px;
}

.copywright_text p {
    color: #fff;
    font-size: 14px;
    margin-bottom: 0px;
}

.media-icons {
    display: flex;
    justify-content: flex-end;
}

.media-icons a {
    display: inline-block;
    margin: 0px 0px 0px 10px;
    color: #000;
}

.media-icons a i {
    background-color: #fff;
    width: 30px;
    height: 30px;
    line-height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 50%;
}

.mail-box-sec i {
    position: absolute;
    top: 12.5px;
    left: 13px;
    font-size: 18px;
}

.footer-menu h6 {
    font-size: 18px;
}

.footer-menu ul {
    list-style: none;
    padding: 0;
}

.footer-menu ul li {
    font-size: 14px;
    padding-top: 8px;
}

.media-icons img {
    padding-left: 15px;
}

.subs-btn-int {
    background-color: #fff !important;
    color: #183823 !important;
    font-weight: 700;
}

.subs-btn-loader {

    border: 1px solid #fff;
    padding: 8px 35px;

    border-radius: 8px;
    width: 100%;
    background-color: #fff !important;

    font-weight: 700;
}

@media (max-width:992px) {
    .footer-sec .container.py-4 {
        padding: 0px;
        margin: 0px;
    }

    .footer-menu_1 {
        padding-left: 0px;
    }
}

@media (max-width:767px) {
    .copywright_text p {
        font-size: 10px;
    }
}

@media (max-width:640px) {
    .media-icons img {
        padding-left: 1px;
        width: 15px;
        margin: 0px 0px 0px 4px;
    }

    .footer-sec .d-flex {
        display: grid !important;
        grid-template-columns: 1fr 1fr 1fr;
        align-items: center;
    }

    .footer-sec .d-flex h6 {
        font-size: 10px;
        margin-bottom: 0px;
    }
}

@media (max-width:575px) {
    .copywright_text p {
        text-align: center;
    }

    .media-icons {
        display: flex;
        justify-content: center;
        margin: 8px 0px;
    }
}