:root {
  --var-background-color: #183823;
  --var-light-background-color: #000000;
  --var-gray-color: #F3F3F3;
  --var-white-color: #fff;
  --var-light-blue-color: #183823;
  --var-primary-color: #18191A;
  --var-primary-text-color: #475156;
  --var-heading-color: #272727;
  --var-green-color: #183823;
  --var-dark-color: #000000;
}

.buyer-contact-us {
  /* Contact Us */
}
.buyer-contact-us .profile_section {
  position: relative;
  padding: 45px 0px;
}
.buyer-contact-us .profile_heading h2 {
  font-size: 30px;
  font-weight: 700;
  line-height: 24px;
  color: #000;
}
.buyer-contact-us .contact_us_section {
  position: relative;
  box-shadow: 0px 0px 60px 30px rgba(0, 0, 0, 0.031372549);
  border-radius: 10px;
  padding: 5px;
  border: 1px rgba(217, 217, 217, 0.5803921569) solid;
  margin: 0px 30px 0px 30px;
}
.buyer-contact-us .contact_us_section_left {
  border: 1px solid rgba(8, 170, 227, 0.5019607843);
  background: rgba(8, 170, 227, 0.1019607843);
  padding: 25px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}
.buyer-contact-us .contact_us_section_left h4 {
  font-size: 28px;
  font-weight: 600;
  line-height: 35.14px;
  color: var(--var-primary-color);
  margin-bottom: 20px;
}
.buyer-contact-us .contact_us_section_left p {
  font-size: 18px;
  font-weight: 400;
  line-height: 22.59px;
  margin-bottom: 35px;
}
.buyer-contact-us .contact_us_section_left ul {
  list-style: none;
  padding-left: 0px;
  padding-bottom: 0px;
}
.buyer-contact-us .contact_us_section_left ul li {
  font-size: 16px;
  font-weight: 400;
  line-height: 20.08px;
  color: var(--var-heading-color);
  position: relative;
  padding-left: 25px;
  margin-bottom: 35px;
}
.buyer-contact-us .contact_us_section_left ul li i {
  color: #08AAE3;
  position: absolute;
  left: 0;
  top: 5px;
}
.buyer-contact-us .contact_us_section_left .circle {
  background: url(/public/assets/images/circle.png);
  position: absolute;
  bottom: 0px;
  width: 150px;
  height: 150px;
  right: 0;
  background-position: 0% 0%;
  background-repeat: no-repeat;
}
.buyer-contact-us .social_media_icon {
  position: absolute;
  bottom: 20px;
  z-index: 9;
}
.buyer-contact-us .social_media_icon ul {
  list-style: none;
  margin-bottom: 0px;
}
.buyer-contact-us .social_media_icon ul li {
  display: inline-block;
  margin: 0px;
  color: #444444;
}
.buyer-contact-us .social_media_icon ul li a {
  color: #444444;
}
.buyer-contact-us .social_media_icon ul li:nth-child(1) {
  padding-left: 0px;
}
.buyer-contact-us .social_media_icon ul li i {
  position: relative;
  color: #444444;
}
.buyer-contact-us .radio_buttom_section {
  display: flex;
  justify-items: start;
}
.buyer-contact-us .radio_buttom_section .form-check:nth-child(1) {
  padding-left: 20px;
}
.buyer-contact-us .radio_buttom_section .form-check {
  padding-left: 40px;
}
.buyer-contact-us .contact_us_section_right_field label {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: var(--var-dark-color);
  width: 100%;
}
.buyer-contact-us .contact_us_section_right_field input {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 1px #ddd solid;
  width: 100%;
}
.buyer-contact-us .contact_us_section_right_field input::-moz-placeholder {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--var-dark-color);
}
.buyer-contact-us .contact_us_section_right_field input::placeholder {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--var-dark-color);
}
.buyer-contact-us .contact_us_section_right_field .message {
  margin: 30px 0px 20px 0px;
}
.buyer-contact-us .contact_us_section_right_field textarea {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 1px #ddd solid;
  width: 100%;
  height: 30px;
}
.buyer-contact-us .contact_us_section_right_field textarea::-moz-placeholder {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #8D8D8D;
}
.buyer-contact-us .contact_us_section_right_field textarea::placeholder {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #8D8D8D;
}
.buyer-contact-us input:focus-visible {
  outline: transparent;
}
.buyer-contact-us textarea:focus-visible {
  outline: transparent;
}
.buyer-contact-us .contact_us_section_right {
  padding: 25px 20px 25px 15px;
}
.buyer-contact-us .contact_us_section_right h2 {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: var(--var-dark-color);
}
.buyer-contact-us .contact_us_section_right .form-check-input:focus {
  box-shadow: none;
}
.buyer-contact-us .contact_us_section_right_field button {
  background: #183823;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.1215686275);
  padding: 15px 48px 15px 48px;
  border-radius: 5px;
  color: var(--var-white-color);
  border: 0px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20.08px;
  margin: 20px 0px 60px 0px;
}

@media (max-width: 992px) {
  .buyer-contact-us .contact_us_section_left h4 {
    font-size: 15px;
  }
  .buyer-contact-us .contact_us_section_left p {
    font-size: 14px;
  }
  .buyer-contact-us .contact_us_section_left ul li {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .buyer-contact-us .radio_buttom_section .form-check {
    padding-left: 30px;
  }
  .buyer-contact-us .radio_buttom_section .form-check-label {
    font-size: 13px;
  }
}
@media (max-width: 640px) {
  .buyer-contact-us .contact_us_section {
    margin: 0px 10px 0px 10px;
  }
  .buyer-contact-us .radio_buttom_section {
    display: block;
    float: left;
  }
  .buyer-contact-us .radio_buttom_section .form-check {
    width: 50%;
    float: left;
    display: flex;
    align-items: center;
  }
  .buyer-contact-us .radio_buttom_section .form-check:nth-child(1) {
    padding-left: 30px;
  }
  .buyer-contact-us .radio_buttom_section .form-check-label {
    font-size: 10px;
    padding-left: 4px;
    padding-top: 5px;
  }
  .buyer-contact-us .contact_us_section_right_field .text-end {
    text-align: center !important;
  }
  .buyer-contact-us .contact_us_section_right_field button {
    text-align: center !important;
  }
}/*# sourceMappingURL=BuyerContactUsContent.css.map */