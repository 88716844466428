.seller-checkout {
  /* ===========Checkout=============== */
  /* ===========Checkout============== */
}
.seller-checkout .bg_det_subscription {
  background-image: url(../../../../../public/assets/images/seller_images/bg_images_sub.png);
  background-size: 100% 100%;
}
.seller-checkout .main_detail_Plan {
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  padding: 15px;
  background: #fff;
  margin-bottom: 40px;
}
.seller-checkout .logo_login {
  text-align: center;
}
.seller-checkout .logo_login img {
  width: 150px;
  padding: 20px 0 30px;
}
.seller-checkout .Plan_form_sub h3 {
  color: var(--color-4);
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  text-align: center;
  margin-bottom: 10px;
}
.seller-checkout .Plan_form_sub p {
  color: var(--color-3);
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  text-align: center;
  padding-bottom: 20px;
}
.seller-checkout .bor_left_sec {
  border-left: 1px solid #ccc;
}
.seller-checkout .main_Checkout_Plan {
  text-align: left;
  padding: 30px;
}
.seller-checkout .main_Checkout_Plan h3 {
  text-align: left;
  font-weight: 500;
  font-size: 24px;
  color: var(--color-3);
  padding: 0 0 10px;
}
.seller-checkout .main_Checkout_Plan h5 {
  text-align: left;
  font-weight: 500;
  font-size: 18px;
  color: var(--main-color);
  padding-bottom: 0;
  cursor: pointer;
}
.seller-checkout .main_Checkout_Plan ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
  padding-bottom: 30px;
}
.seller-checkout .main_Checkout_Plan ul li {
  text-align: left;
  font-size: 14px;
  padding-bottom: 10px;
}
.seller-checkout .main_map_sub span {
  text-decoration: line-through;
  color: #a3a3a3;
  padding-right: 5px;
}
.seller-checkout .main_map_sub span img {
  padding-left: 8px;
}
.seller-checkout .main_select select {
  height: 43px;
  border-radius: 6px;
  width: 100%;
  border: 1px solid #ccc;
}
.seller-checkout .main_section_detail {
  display: flex;
}
.seller-checkout .main_section_detail select {
  width: auto;
  margin-right: 10px;
}
.seller-checkout .bor_left_sec {
  border-left: 1px solid #ccc;
}

@media (max-width: 767px) {
  .seller-checkout .bor_left_sec {
    border-left: 0px;
    border-top: 1px #ddd solid;
  }
}/*# sourceMappingURL=SellerCheckout.css.map */