.seller-checkout {
    .bg_det_subscription {
        background-image: url(../../../../../public/assets/images/seller_images/bg_images_sub.png);
        background-size: 100% 100%;
    }

    .main_detail_Plan {
        border: 1px solid #e6e6e6;
        border-radius: 10px;
        padding: 15px;
        background: #fff;
        margin-bottom: 40px;
    }

    .logo_login {
        text-align: center;
    }

    .logo_login img {
        width: 150px;
        padding: 20px 0 30px;
    }

    .Plan_form_sub h3 {
        color: var(--color-4);
        font-weight: 600;
        font-family: "Poppins", sans-serif;
        font-size: 40px;
        text-align: center;
        margin-bottom: 10px;
    }

    .Plan_form_sub p {
        color: var(--color-3);
        font-family: "Poppins", sans-serif;
        font-size: 15px;
        text-align: center;
        padding-bottom: 20px;
    }

    .bor_left_sec {
        border-left: 1px solid #ccc;
    }

    /* ===========Checkout=============== */
    .main_Checkout_Plan {
        text-align: left;
        padding: 30px;
    }

    .main_Checkout_Plan h3 {
        text-align: left;
        font-weight: 500;
        font-size: 24px;
        color: var(--color-3);
        padding: 0 0 10px;
    }

    .main_Checkout_Plan h5 {
        text-align: left;
        font-weight: 500;
        font-size: 18px;
        color: var(--main-color);
        padding-bottom: 0;
        cursor: pointer;
    }

    .main_Checkout_Plan ul {
        list-style: none;
        padding: 0;
        margin: 0;
        text-align: left;
        padding-bottom: 30px;
    }

    .main_Checkout_Plan ul li {
        text-align: left;
        font-size: 14px;
        padding-bottom: 10px;
    }

    .main_map_sub span {
        text-decoration: line-through;
        color: #a3a3a3;
        padding-right: 5px;
    }

    .main_map_sub span img {
        padding-left: 8px;
    }

    .main_select select {
        height: 43px;
        border-radius: 6px;
        width: 100%;
        border: 1px solid #ccc;
    }

    .main_section_detail {
        display: flex;
    }

    .main_section_detail select {
        width: auto;
        margin-right: 10px;
    }

    .bor_left_sec {
        border-left: 1px solid #ccc;
    }

    /* ===========Checkout============== */
}
@media (max-width:767px){
    .seller-checkout{
        .bor_left_sec {
            border-left: 0px;
            border-top: 1px #ddd solid;
        }
    }
}