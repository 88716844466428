:root {
  --var-background-color: #141920;
  --var-light-background-color: #252F3D;
  --var-gray-color: #F3F3F3;
  --var-white-color: #fff;
  --var-light-blue-color: #08AAE3;
  --var-primary-color: #18191A;
  --var-primary-text-color: #475156;
  --var-heading-color: #272727;
  --var-green-color: #0A8200;
  --var-dark-color: #000000;
}

.buyer-profile {
  /* Profile Section */
}
.buyer-profile .profile_section {
  position: relative;
  padding: 45px 0px;
}
.buyer-profile .profile_heading h2 {
  font-size: 30px;
  font-weight: 700;
  line-height: 24px;
  color: #000;
}
.buyer-profile .profile_man_box {
  position: relative;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  padding: 50px 120px 50px 120px;
  border-radius: 8px;
}
.buyer-profile .profile_inner_box_section {
  padding: 30px 10px 30px 10px;
  background: rgba(213, 213, 213, 0.08);
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: stretch;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px #ddd solid;
}
.buyer-profile .profile_inner_box_section:nth-last-of-type(1) {
  border-bottom: 0px;
}
.buyer-profile .profile_inner_box h6 {
  position: relative;
  font-size: 22px;
  font-weight: 700;
  line-height: 32.16px;
  color: var(--var-dark-color);
  padding-left: 65px;
}
.buyer-profile .profile_inner_box h6 span {
  font-size: 16px;
  font-weight: 700;
  line-height: 30.12px;
  color: var(--var-dark-color);
}
.buyer-profile .profile_inner_box h6 i {
  position: absolute;
  left: 0;
  top: 14px;
  font-size: 38px;
  color: #A8ACB1;
}
.buyer-profile .active_profile_icon {
  color: var(--var-light-blue-color) !important;
}
.buyer-profile .profile_inner_box a {
  border-radius: 19px;
  font-size: 14px;
  text-transform: none;
  padding: 6px 50px;
  float: right;
  text-decoration: none;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
  color: #848484;
  background: rgba(8, 170, 227, 0.0392156863);
  border: 1px solid #183823;
  transition: 0.4s;
}
.buyer-profile .profile_inner_box_section:hover {
  background: rgba(213, 213, 213, 0.31);
}
.buyer-profile .profile_inner_box_section:hover .profile_inner_box a {
  background-color: #183823;
  color: var(--var-white-color);
  transition: 0.4s;
}
.buyer-profile .profile_inner_box_section:hover .profile_inner_box h6 i {
  color: #183823;
}
.buyer-profile .profile_inner_new {
  border: 1px #ddd solid;
  border-radius: 8px;
}

@media (max-width: 992px) {
  .buyer-profile .profile_man_box {
    padding: 20px 25px 20px 25px;
  }
  .buyer-profile .profile_inner_box h6 {
    font-size: 14px;
    line-height: 16px;
    padding-left: 32px;
  }
  .buyer-profile .profile_inner_box h6 span {
    font-size: 11px;
    line-height: 20px;
  }
  .buyer-profile .profile_inner_box h6 i {
    top: 12px;
    font-size: 24px;
  }
  .buyer-profile .profile_inner_box a {
    font-size: 12px;
    padding: 6px 28px;
  }
  .buyer-profile .profile_inner_box a.active {
    font-size: 12px;
    padding: 6px 28px;
  }
}/*# sourceMappingURL=BuyerProfileContent.css.map */