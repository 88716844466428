.buyer-privacy-policy {

    /* Privacy Policy */
    .privacy_policy_section {
        position: relative;
        padding: 65px 0px;
        background: url(/public/assets/images/privacy_bg.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center bottom;
    }

    .page_titile h2 {
        font-size: 36px;
        font-weight: 700;
        line-height: 43.85px;
        color: var(--var-white-color);
        text-align: center;
    }

    .page_titile p {
        font-size: 20px;
        font-weight: 400;
        line-height: 45.18px;
        text-align: center;
        color: var(--var-white-color);
    }

    .page_titile_img img {
        width: 100%;
        height: 200px;
        object-fit: contain;
    }

    .policy_content {
        position: relative;
        padding: 45px 0px;
        background: url(/public/assets/images/policy_bg.png);
        background-repeat: no-repeat;
        background-position: center center;
    }

    .policy_content_inner h2 {
        font-size: 30px;
        font-weight: 800;
        line-height: 37.65px;
        color: var(--var-dark-color);
        margin-bottom: 20px;
    }

    .policy_content_inner ul {
        list-style: none;
        padding-left: 0px;
        margin-bottom: 0px;
    }

    .policy_content_inner ul li {
        box-shadow: 0px 4px 4px 0px #00000040;
        background: #D9D9D9;
        font-size: 20px;
        font-weight: 400;
        line-height: 22px;
        color: var(--var-dark-color);
        margin-bottom: 25px;
        border-radius: 6px;
        position: relative;
        padding: 8px 0px 8px 35px;
    }

    .policy_content_inner ul li a {
        display: block;
        padding: 10px 12px;
        color: #000;
        text-decoration: none;
    }

    .policy_content_inner ul li a img {
        position: absolute;
        left: 10px;
        top: 17px;
    }

    .policy_content_inner ul li:hover {
        background: var(--var-light-blue-color);
    }

    .policy_content_inner ul li:hover a {
        color: var(--var-white-color);
    }

    .policy_content_inner ul li .policy_img2 {
        display: none;
    }

    .policy_content_inner ul li:hover .policy_img1 {
        display: none;
    }

    .policy_content_inner ul li:hover .policy_img2 {
        display: block;
    }

    .policy_content_inner p {
        font-size: 18px;
        font-weight: 400;
        line-height: 29px;
        color: var(--var-dark-color);
    }

}