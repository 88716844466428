.buyer-orders {

    .nav {
        border-bottom: 1px #ddd solid;
    }

    .nav-pills .nav-link.active {
        background-color: white;
        color:
            #183823;
        border-bottom: 3px #183823 solid;
        border-radius: 0px;
    }

    .nav-pills .nav-link {
        color: #7B7B7B;
    }

    /* Your Orders */
    .order_section {
        position: relative;
        padding: 45px 0px 0px;
    }

    .order_section_inner {
        position: relative;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
        padding: 20px 10px 20px 10px;
    }

    .track_order_section_1 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px #ddd solid;
        padding-bottom: 20px;
    }


    .track_order_section_left a span {
        color: #848484;
    }



    .track_order_section_right a i {
        padding-right: 6px;
    }

    .track_order_details {
        display: grid;
        grid-template-columns: 7% 24% 15% 15% 15% 20%;
        grid-column-gap: 10px;
        grid-row-gap: 10px;
        justify-content: space-between;
        padding: 15px 10px 15px 10px;
        align-items: center;
    }

    .track_order_details_main p {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: var(--var-primary-color);
        margin-bottom: 0px;
    }

    .track_order_details_main h6 {
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        color: var(--var-primary-color);
    }

    .track_order_details_main h6 a {
        text-decoration: none;
        color: #08aae3;
    }

    .track_order_details_main .btn_primary {
        border-radius: 19px;
        font-size: 14px;
        text-transform: none;
        padding: 6px 12px;
    }

    .track_order_details_main .cancel_order {
        border: 1px solid #08AAE3;
        font-size: 14px;
        font-weight: 500;
        line-height: 22.59px;
        background: #08AAE312;
        color: var(--var-heading-color);
        text-decoration: none;
        padding: 6px 12px;
        border-radius: 19px;
        transition-duration: 0.4s;
    }

    .track_order_details_main .cancel_order:hover {
        background: var(--var-light-blue-color);
        color: var(--var-white-color);
        transition-duration: 0.4s;
    }

    /* Your Orders */


    /* Cancel Order */
    .cancel_order_heading {
        display: grid;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
        grid-template-columns: 70% 30%;
    }

    .cancel_order_heading_left {
        position: relative;
    }

    .cancel_order_heading_left h2 {
        font-size: 26px;
        font-weight: 700;
        line-height: 24px;
        color: var(--var-dark-color);
    }

    .cancel_order_search {
        position: relative;
    }

    .cancel_order_search input {
        border: 1px #ddd solid;
        padding-left: 30px;
        width: 100%;
        cursor: pointer;
        border-radius: 4px;
        height: 37px;
    }

    .cancel_order_search i {
        position: absolute;
        left: 10px;
        top: 13px;
        color: #898C8C;
        font-size: 12px;
    }

    .cancel_order_section {
        display: grid;
        grid-template-columns: 7% 25% 20% 20% 20%;
        grid-column-gap: 10px;
        grid-row-gap: 10px;
        justify-content: space-between;
        padding: 15px 10px 15px 10px;
        align-items: center;
    }
    .cancel_popup img {
        text-align: center;
        margin: 0 auto;
        display: block;
        width: 250px;
        height: 250px;
        object-fit: contain;
    }
     .cancel_popup h2 {
        font-size: 22px;
        font-weight: 700;
        color: #000;
        text-align: center;
    }
    .cancel_popup textarea {
        width: 100%;
        border: 1px #ddd solid;
        height: 80px;
        border-radius: 5px;
        padding: 0px 10px 0px 10px;
    }
    .cancel_popup p{
        font-size: 16px;
        color: #000;
    }
    .buyer-orders .cancel_order_popup {
        display: flex;
        justify-content: flex-start;
    }
   .cancel_order_popup button {
        border: 1px solid #183823;
        font-size: 14px;
        font-weight: 500;
        line-height: 22.59px;
        background: rgba(8, 170, 227, 0.0705882353);
        color: var(--var-heading-color);
        text-decoration: none;
        padding: 6px 12px;
        border-radius: 10px;
        transition-duration: 0.4s;
        margin: 0px 15px 0px 0px;
        transition-duration: 0.4s;
    }
    .cancel_order_popup button:hover{
        background: #183823;
        color: #fff;
        transition-duration: 0.4s;
    }
}

@media (max-width:992px) {
    .buyer-orders {
        .track_order_details {
            grid-template-columns: 7% 23% 14% 14% 14% 21%;
        }

        .track_order_details_main .cancel_order {
            font-size: 10px;
        }

        .track_order_details_main .btn_primary {
            font-size: 10px;
        }
    }
}

@media (max-width:767px) {
    .buyer-orders {
        .track_order_details {
            grid-template-columns: 7% 16% 15% 15% 15% 23%;
        }

        .track_order_details_main p {
            font-size: 10px;
            line-height: 14px;
        }

        .track_order_details_main h6 {
            font-size: 10px;
        }

        .track_order_details_main .cancel_order {
            font-size: 7px;
        }

        .track_order_details_main .btn_primary {
            font-size: 7px;
        }
         .nav-pills .nav-link {
            color: #7B7B7B;
            font-size: 9px;
        }
        .cancel_order_heading_left h2 {
            font-size: 20px;
        }
        .cancel_order_heading {
            grid-template-columns: 40% 57%;
        }
    }
}

@media (max-width:575px) {
    .buyer-orders {
        .track_order_details {
            grid-template-columns: 1fr 1fr;
        }

        .track_order_details_main.text-center {
            text-align: left !important;
        }

        .track_order_details_main.text-end {
            text-align: left !important;
        }

        .track_order_details_main p {
            font-size: 10px;
            line-height: 16px;
        }

        .track_order_details_main h6 {
            font-size: 10px;
            line-height: 16px;
            margin-bottom: 0px;
        }

        .track_order_section_left a {
            font-size: 9px;
            display: inline;
        }

        .track_order_section_right a {
            font-size: 10px;
            display: inline;
        }
    }
}

@media (max-width:320px) {
    .buyer-orders {
        .track_order_details_main .cancel_order {
            font-size: 6px;
        }

        .track_order_section_left a {
            font-size: 8px;
            display: inline;
        }

        .track_order_section_right a {
            font-size: 8px;
            display: inline;
        }
    }
}