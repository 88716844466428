.auth {
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    .login.form-section {
        height: 100%;
        width: 100%;
    }

    .field-icon {
        float: right;
        margin-left: -24px;
        margin-top: -30px;
        position: relative;
        z-index: 2;
        padding-right: 5px;
        cursor: pointer;
    }

    .login .fields-section {
        background-color: transparent;
        padding-left: 15%;
        padding-right: 15%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;

    }

    .login .welcome-back-sec h3,
    h1 {
        color: #062A41;

    }

    .login .welcome-back-sec p {
        color: #062A41;
        font-weight: 700;
    }

    .login .form-section .pass-email-field input {
        width: 100%;
        padding: 10px;
        border: 1px solid #D0D5DD;
        border-radius: 8px;
        background: transparent;
    }

    .login .form-section .pass-email-field label {
        padding: 10px 0px;
        color: #062A41;
        font-weight: 600;
    }

    .pass-email-field {
        position: relative;
    }

    .pass-email-field span {
        position: absolute;
        right: 0;
        transform: translate(-40%, -140%);
    }

    .forget-pass p a {
        color: #08AAE3;
        text-decoration: none;
    }

    .forget-pass h6 a {
        color: #08AAE3;
        text-decoration: none;
    }
.login{
    height: 100vh;
}
    .login-banner-sec {
        height: 100%;
    }
.welcome-login-main {
    // height: 100vh;
}
    .login-banner-sec img {
        // height: 100%;
        object-fit: cover;
        width: 100%;
        height: 800px;
    }
    .signin-goog-login-cls {
        // padding-bottom: 30px;
    }
    .or-sec {
        position: relative;
    }

    .or-sec p {
        padding: 4px;
        background-color: #fff;
        color: grey;
        position: absolute;
        top: -18px;
        left: 47%;
        margin: 0;
    }

    .or-sec p {
        padding: 4px;
        background-color: #fff;
        color: grey;
    }

    .create-account-sec {
        padding-top: 20px;
    }

    .create-account-sec a {
        text-decoration: none;
        color: #08AAE3;

    }

    .submit-btn button {
        border: none;
        width: 100%;
        padding: 10px;
        background: #183823;
        color: #fff;
        font-size: 17px;
        font-weight: 600;
        border-radius: 10px;
        margin-top: 25px;
    }

    .submit-btn-google button {
        border: 1px solid #D0D5DD !important;
        text-align: left ;
        width: 100% !important;
        padding: 10px !important;
        color: #000 !important;
        font-size: 17px !important;
        border-radius: 10px !important;
        background: transparent !important;
        margin-top: 25px !important;
        box-shadow: none !important;
    }

    .submit-btn-google span {
        padding-left: 26%;
    }

    .submit-btn-apple span {
        width: 100%;
        text-align: center;
    }
.apple_icon i{
    font-size: 20px;
    padding-right: 5px;
}
    .submit-btn-apple button {
        border: 1px solid #D0D5DD;
        background: transparent;
        text-align: left;
        width: 100%;
        padding: 10px;
        color: #000;
        font-size: 17px;
        border-radius: 10px;
        margin-top: 25px;
    }
.welcome-back-sec img{
    width: 130px;
}
    /* Reset Password */
    .reset_password_section.login .fields-section {
        margin-top: 0%;
    }

    .welcome-back-sec h1 {
        font-size: 36px;
        font-weight: 800;
        line-height: 60.24px;
    }

    .welcome-back-sec h3 {
        font-size: 18px;
        font-weight: 700;
        line-height: 22.59px;
        color: #062A41;
    }

    .reset_password h1 {
        text-align: left;
    }

    .reset_password p {
        text-align: left;
    }

    .welcome-back-sec p {
        font-size: 16px;
        font-weight: 400;
        line-height: 20.08px;
        margin-bottom: 15px;
    }

    .create_your_account p {
        font-size: 16px;
        font-weight: 700;
        line-height: 20.08px;
        color: #000;
        margin-top: 20px;
        text-align: center;
    }

    .create_your_account p a {
        text-decoration: none;
        color: #08AAE3;
    }
    

}

@media (max-width:992px) {
    .auth {
        .login .px-5 {
            padding: 15px !important;
        }
        // .welcome-login-main {
        //     height: auto;
        // }
        .login .fields-section {
            background-color: transparent;
            margin-top: 0px;
            padding-top: 50px;
            padding-left: 0%;
            padding-right: 0%;
        }

        .welcome-back-sec h1 {
            font-size: 30px;
            line-height: 40px;
            text-align: center;
        }

        .welcome-back-sec p {
            text-align: center;
        }
    }
}

@media (max-width:767px) {
    .auth {
        .login .px-5 {
            padding: 15px !important;
        }

        .login .fields-section {
            background-color: transparent;
            margin-top: 0;
            padding-top: 50px;
            padding-left: 5%;
            padding-right: 5%;
        }

        .welcome-back-sec h1 {
            font-size: 30px;
            line-height: 40px;
        }
        .login-banner-sec img {
            height: auto;
        }
    }
}

@media (max-width:640px) {
    .auth {
        .login-banner-sec img {
            display: none;
        }
        .signin-goog-login-cls {
            padding-bottom: 50px;
        }
    }
}
@media (max-width:575px) {
    .auth {
        .forget-pass.pt-3.d-flex.justify-content-between {
            display: block !important;
            text-align: center;
        }.auth .login-banner-sec img {
            height: auto;
            display: none;
        }
    }
}