.createaccount {
    .login .fields-section {
        background-color: transparent;
        padding-left: 15%;
        padding-right: 15%;
    }

    .create-account-sec {
        border: 1px solid #E4E7E9;
        border-radius: 4px;
        box-shadow: 0px 8px 40px 0px #0000001F;
        padding: 30px;
    }

    .welcome-back-sec h3 {
        font-size: 18px;
        font-weight: 700;
        line-height: 22.59px;
        color: #062A41;
    }
    .welcome-back-sec img{
        width: 130px;
    }
    .welcome-back-sec h1 {
        font-size: 36px;
        font-weight: 800;
        line-height: 60.24px;
        color: #062a41;
        margin-top: 10px;
    }

    .login .welcome-back-sec p {
        color: #062A41;
        font-weight: 700;
    }

    .create-account-sec {
        padding-top: 20px;
    }

    .create-account-sec {
        border: 1px solid #E4E7E9;
        border-radius: 4px;
        box-shadow: 0px 8px 40px 0px #0000001F;
        padding: 30px;
    }

    .pass-email-field {
        position: relative;
    }

    .login .form-section .pass-email-field label {
        padding: 10px 0px;
        color: #062A41;
        font-weight: 600;
    }

    .login .form-section .pass-email-field input {
        width: 100%;
        padding: 10px;
        border: 1px solid #D0D5DD;
        border-radius: 8px;
        background: transparent;
    }

    .forget-pass {
        padding-top: 15px;
    }

    .forget-pass .are-you-agree p {
        text-align: left;
        padding-left: 10px;
    }

    .forget-pass .are-you-agree p span {
        color: #08AAE3;
    }

    .forget-pass p a {
        color: #08AAE3;
        // text-decoration: none;
    }

    .create-account-sec a {
        // text-decoration: none;
        color: #08AAE3;
    }

    .submit-btn button {
        border: none;
        width: 100%;
        padding: 10px;
        background: #183823;
        color: #fff;
        font-size: 17px;
        font-weight: 600;
        border-radius: 10px;
        margin-top: 25px;
    }

    .or-sec {
        position: relative;
    }

    .or-sec p {
        padding: 4px;
        background-color: #fff;
        color: grey;
        position: absolute;
        top: -18px;
        left: 47%;
        margin: 0;
    }

    .create-account-sec a {
        // text-decoration: none;
        color: #08AAE3;
    }

    .submit-btn-google button {
        border: 1px solid #D0D5DD !important;
        text-align: left!important;
        width: 100%!important;
        padding: 10px!important;
        color: #000!important;
        font-size: 17px!important;
        border-radius: 10px!important;
        background: transparent!important;
        margin-top: 25px!important;
        box-shadow: none !important;
    }

    .submit-btn-google span {
        padding-left: 26%;
    }
    .apple_icon i{
        font-size: 20px;
        padding-right: 5px;
    }
    .submit-btn-apple button {
        border: 1px solid #D0D5DD;
        background: transparent;
        text-align: left;
        width: 100%;
        padding: 10px;
        color: #000;
        font-size: 17px;
        border-radius: 10px;
        margin-top: 25px;
    }

    .submit-btn-apple span {
        padding-left: 26%;
    }

    .login-banner-sec {
        height: 100%;
    }

    .login-banner-sec img {
        height: 100%;
        object-fit: cover;
    }

    .password_input {
        position: relative;
    }

    .password_input i {
        position: absolute;
        top: 15px;
        right: 10px;
    }

    .create_your_account p {
        font-size: 16px;
        font-weight: 700;
        line-height: 20.08px;
        color: #000;
        margin-top: 20px;
    }

    .create_your_account p a {
        text-decoration: none;
        color: #08AAE3;
    }

}
@media only screen and (min-width: 767px) and (max-width: 1250px) {
    .createaccount {
    .login .fields-section{
        padding-left: 1%;
        padding-right: 1%;
    }
}
  }
@media (max-width:992px){
    .createaccount {
    .login .px-5{
        padding: 15px !important;
    }
    .login .fields-section {
        background-color: transparent;
        margin-top: 0;
        padding-block: 50px;
        padding-left: 0%;
        padding-right: 0%;
    }
    .welcome-back-sec h1 {
        font-size: 30px;
        line-height: 40px;
        text-align: center;
    }
    .welcome-back-sec p {
        text-align: center;
    }
}
}
@media (max-width:767px){
    .createaccount {
    .login .px-5{
        padding: 15px !important;
    }
    .login .fields-section {
        background-color: transparent;
        margin-top: 0;
        padding-block: 50px;
        padding-left: 0%;
        padding-right: 0%;
    }
    .welcome-back-sec h1 {
        font-size: 30px;
        line-height: 40px;
    }
    .submit-btn-google span {
        padding-left: 5%;
    }
    .submit-btn-apple span {
        padding-left: 5%;
    }
    .submit-btn-google .d-flex {
        display: flex!important;
        justify-content: center;
    }
    .submit-btn-apple .d-flex {
        display: flex!important;
        justify-content: center;
    }
}
}
@media only screen and (max-width: 670px) {
    .createaccount {
    .login .fields-section{
        padding-left: 1%;
        padding-right: 1%;
    }
}
  }
  @media (max-width:640px){
    .createaccount{
        .login-banner-sec img {
            display: none;
        }
        .welcome-back-sec h1 {
            margin-top: 8px;
        }
    }
  }
  