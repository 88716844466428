.auth {
  /* Reset Password */
}
.auth * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.auth .login.form-section {
  height: 100%;
  width: 100%;
}
.auth .field-icon {
  float: right;
  margin-left: -24px;
  margin-top: -30px;
  position: relative;
  z-index: 2;
  padding-right: 5px;
  cursor: pointer;
}
.auth .login .fields-section {
  background-color: transparent;
  padding-left: 15%;
  padding-right: 15%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
.auth .login .welcome-back-sec h3,
.auth h1 {
  color: #062A41;
}
.auth .login .welcome-back-sec p {
  color: #062A41;
  font-weight: 700;
}
.auth .login .form-section .pass-email-field input {
  width: 100%;
  padding: 10px;
  border: 1px solid #D0D5DD;
  border-radius: 8px;
  background: transparent;
}
.auth .login .form-section .pass-email-field label {
  padding: 10px 0px;
  color: #062A41;
  font-weight: 600;
}
.auth .pass-email-field {
  position: relative;
}
.auth .pass-email-field span {
  position: absolute;
  right: 0;
  transform: translate(-40%, -140%);
}
.auth .forget-pass p a {
  color: #08AAE3;
  text-decoration: none;
}
.auth .forget-pass h6 a {
  color: #08AAE3;
  text-decoration: none;
}
.auth .login {
  height: 100vh;
}
.auth .login-banner-sec {
  height: 100%;
}
.auth .login-banner-sec img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 800px;
}
.auth .or-sec {
  position: relative;
}
.auth .or-sec p {
  padding: 4px;
  background-color: #fff;
  color: grey;
  position: absolute;
  top: -18px;
  left: 47%;
  margin: 0;
}
.auth .or-sec p {
  padding: 4px;
  background-color: #fff;
  color: grey;
}
.auth .create-account-sec {
  padding-top: 20px;
}
.auth .create-account-sec a {
  text-decoration: none;
  color: #08AAE3;
}
.auth .submit-btn button {
  border: none;
  width: 100%;
  padding: 10px;
  background: #183823;
  color: #fff;
  font-size: 17px;
  font-weight: 600;
  border-radius: 10px;
  margin-top: 25px;
}
.auth .submit-btn-google button {
  border: 1px solid #D0D5DD !important;
  text-align: left;
  width: 100% !important;
  padding: 10px !important;
  color: #000 !important;
  font-size: 17px !important;
  border-radius: 10px !important;
  background: transparent !important;
  margin-top: 25px !important;
  box-shadow: none !important;
}
.auth .submit-btn-google span {
  padding-left: 26%;
}
.auth .submit-btn-apple span {
  width: 100%;
  text-align: center;
}
.auth .apple_icon i {
  font-size: 20px;
  padding-right: 5px;
}
.auth .submit-btn-apple button {
  border: 1px solid #D0D5DD;
  background: transparent;
  text-align: left;
  width: 100%;
  padding: 10px;
  color: #000;
  font-size: 17px;
  border-radius: 10px;
  margin-top: 25px;
}
.auth .welcome-back-sec img {
  width: 130px;
}
.auth .reset_password_section.login .fields-section {
  margin-top: 0%;
}
.auth .welcome-back-sec h1 {
  font-size: 36px;
  font-weight: 800;
  line-height: 60.24px;
}
.auth .welcome-back-sec h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 22.59px;
  color: #062A41;
}
.auth .reset_password h1 {
  text-align: left;
}
.auth .reset_password p {
  text-align: left;
}
.auth .welcome-back-sec p {
  font-size: 16px;
  font-weight: 400;
  line-height: 20.08px;
  margin-bottom: 15px;
}
.auth .create_your_account p {
  font-size: 16px;
  font-weight: 700;
  line-height: 20.08px;
  color: #000;
  margin-top: 20px;
  text-align: center;
}
.auth .create_your_account p a {
  text-decoration: none;
  color: #08AAE3;
}

@media (max-width: 992px) {
  .auth .login .px-5 {
    padding: 15px !important;
  }
  .auth .login .fields-section {
    background-color: transparent;
    margin-top: 0px;
    padding-top: 50px;
    padding-left: 0%;
    padding-right: 0%;
  }
  .auth .welcome-back-sec h1 {
    font-size: 30px;
    line-height: 40px;
    text-align: center;
  }
  .auth .welcome-back-sec p {
    text-align: center;
  }
}
@media (max-width: 767px) {
  .auth .login .px-5 {
    padding: 15px !important;
  }
  .auth .login .fields-section {
    background-color: transparent;
    margin-top: 0;
    padding-top: 50px;
    padding-left: 5%;
    padding-right: 5%;
  }
  .auth .welcome-back-sec h1 {
    font-size: 30px;
    line-height: 40px;
  }
  .auth .login-banner-sec img {
    height: auto;
  }
}
@media (max-width: 640px) {
  .auth .login-banner-sec img {
    display: none;
  }
  .auth .signin-goog-login-cls {
    padding-bottom: 50px;
  }
}
@media (max-width: 575px) {
  .auth .forget-pass.pt-3.d-flex.justify-content-between {
    display: block !important;
    text-align: center;
  }
  .auth .auth .login-banner-sec img {
    height: auto;
    display: none;
  }
}/*# sourceMappingURL=Auth.css.map */