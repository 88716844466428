.otp-verification-email {
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }


    body {
        font-family: "Mulish", sans-serif;
    }

    .login.form-section {
        height: 100%;
        width: 100%;
    }

    .welcome-login-main {
        align-items: center;
    }

    .fields-section {
        text-align: center;
        background-color: transparent;
        padding-block: 50px;
        padding-left: 15%;
        padding-right: 15%;
    }

    .image-sec img {
        height: 100%;
        width: 100%;
    }

    .opt-number-sec {
        margin-left: 12%;
        margin-right: 12%;
        background: #08AAE314;
        border: 1px solid #08AAE3;
        box-shadow: 0px 2px 5.9px 2px #00000040;
        padding: 0px 30px 0px 30px;
    }

    .opt-number-sec h3 {
        padding-top: 15%;
    }

    .otp .fields-section {
        text-align: center;
        background-color: transparent;
        margin-top: 5%;
        padding-block: 50px;
        padding-left: 15%;
        padding-right: 15%;
    }

    .resend-sec {
        padding-bottom: 25px;
    }

    .resend-sec a {
        text-decoration: none;
        color: #08AAE3;
    }

    .enter-opt {
        padding-top: 10px;
        padding-bottom: 20px;
    }

    .enter-opt .opt-num {
        padding: 10px 20px;
        border-radius: 20px;
        border: 1px solid #8E8E8E;
        margin: 10px;
        font-weight: 700;
    }

    .verify-btn {
        padding-bottom: 10px;
    }

    .otp_section_inner input {
        border-radius: 50px;
        border-radius: 20px;
        border: 1px solid #8E8E8E;
        width: 52px;
        height: 42px;
        background: transparent;
        text-align: center;
        margin: 0px 3px 0px 3px;
    }

    /* Forgot Password */
    .forgot_password {
        position: relative;

    }

    .forgot_password .row {
        height: 100vh;
    }

    .forgot_password_img img {
        padding: 60px;
    }

    .forgot_password_img h2 {
        font-size: 30px;
        font-weight: 700;
        line-height: 46.99px;
        margin-bottom: 20px;
    }

    .forgot_password_img label {
        width: 100%;
        font-size: 16px;
        font-weight: 600;
    }

    .forgot_password_img input {
        width: 80%;
        border: 1px solid #D0D5DD;
        padding: 8px;
        border-radius: 8px;
        margin: 10px 0px;
    }

    input {
        outline: none;
    }

    .forgot_password_img button {
        background: #183823;
        display: inline-block;
        color: #fff;
        padding: 6px 21px;
        width: 65%;
        box-shadow: 0px 4px 8px 0px #D3262640;
        border-radius: 25px;
        text-decoration: none;
        text-align: center;
        font-size: 20px;
        font-weight: 700;
        margin-top: 10px;
        border: 0px;
    }

    /* Otp Section */
    .otp_section {
        height: 100vh;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .otp_section_inner {
        background: #08AAE314;
        border: 1px solid #08AAE3;
        box-shadow: 0px 2px 5.9px 2px #00000040;
        text-align: center;
        padding: 100px 30px 100px 30px;
    }

    /* remove the up/down spinner in number input
     source - https://css-tricks.com/snippets/css/turn-off-number-input-spinners/
  */
    .otp_section input[type=number]::-webkit-inner-spin-button,
    .otp_section input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .otp_section_inner {
        background: #08AAE314;
        border: 1px solid #08AAE3;
        box-shadow: 0px 2px 5.9px 2px #00000040;
    }

    .otp_section_inner p {
        font-size: 18px;
        font-weight: 400;
        line-height: 21px;

    }

    .otp_section_inner input {
        border-radius: 50px;
        border-radius: 20px;
        border: 1px solid #8E8E8E;
        width: 52px;
        height: 42px;
        background: transparent;
        text-align: center;
    }

    .otp_section_inner h5 {
        font-size: 18px;
        font-weight: 400;
        line-height: 21px;
        color: #333;
        margin-top: 20px;
        margin-bottom: 15px;
    }

    .otp_section_inner h2 {
        font-size: 30px;
        font-weight: 700;
        line-height: 37.65px;
        color: #000;
        margin-bottom: 20px;

    }

    .verify-btn button {
        padding: 8px 16px;
        border: none;
        background: #183823;
        color: #fff;
        font-weight: 600;
        border-radius: 10px;
        width: 100%;
    }
    .forgot_popup h1{
        font-size: 22px !important;
        font-weight: 700;
        color: #000;
    }
    .resend_otp_heading {
        font-weight: 700;
        font-size: 19px;
    }
}

@media (max-width:1100px) {
    .otp-verification-email {
        .forgot_password_img h2 {
            font-size: 20px;
        }

        .forgot_password_img a {
            font-size: 13px;
        }

        .forgot_password_img img {
            padding: 0px;
        }
        
    }
}

@media (max-width:767px) {
    .otp {
        .forgot_password_img button{
            font-size: 13px;
            margin: 0 auto;
            text-align: center;
            display: inline-block;
            width: 66%;
        }

        .forgot_password_img h2 {
            font-size: 22px;
            font-weight: 700;
            line-height: 33.99px;
            margin-bottom: 5px;
        }

        .forgot_password_img img {
            padding: 5px;
        }

        .welcome-login-main .px-5 {
            padding-right: 0rem !important;
            padding-left: 0rem !important;
        }

        .welcome-login-main {
            padding-bottom: 45px;
        }

        .otp_section_inner {
            margin: 20px;
        }
    }
}

@media (max-width:576px) {
    .otp {
        .forgot_password_img img {
            padding: 15px;
        }

        .forgot_password_img img {
            padding: 15px;
            width: 100%;
        }
       .forgot_pass {
            text-align: center;
        }

        .otp_section_inner input {
            width: 46px;
            height: 36px;
        }
        .forgot_password_img h2 {           
            text-align: center;
        }
       .forgot_password_img label {
            text-align: center;
        }
    }
}