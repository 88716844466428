.buyer-add-new-address {

    .profile_section {
        position: relative;
        padding: 45px 0px;
    }

    .profile_heading h2 {
        font-size: 26px;
        font-weight: 700;
        line-height: 24px;
        color: #000;
    }

    .profile_man_box {
        position: relative;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
        padding: 20px 100px 20px 100px;
    }

    .profile_inner_box_section {
        padding: 30px 10px 30px 10px;
        background: #08AAE30A;
        border: 1px solid #08AAE3;
        border-radius: 12px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-items: stretch;
        justify-content: space-between;
        align-items: center;
    }

    .add_new_address {
        border: 1px solid #18382380;
        background: #1838230A;
        position: relative;
        padding: 30px 20px 30px 20px;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    }

    .add-new-address_btn {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .add_new_address_btn_left a {
        background: #183823;
        color: var(--var-white-color);
        text-decoration: none;
        padding: 8px 24px 8px 24px;
        border-radius: 4px;
        margin-right: 25px;
        display: inline-block;
    }

    .add_new_address_btn_right button {
        color: var(--var-heading-color);
        text-decoration: none;
        padding: 8px 24px 8px 24px;
        border-radius: 4px;
        background: #18382314;
        border: 1px solid #18382380;
        display: inline-block;
    }

    .add_new_address_right_field label {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        color: #191C1F;
        width: 100%;
        margin-bottom: 10px;
    }

    .add_new_address_right_field .form-control {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #929FA5;
        border: 1px solid #979797;
        border-radius: 2px;
        height: 44px;
        width: 100%;
        margin-bottom: 25px;
        padding-left: 10px;
    }

    .add_new_address_right_field select {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #929FA5;
        border: 1px solid #979797;
        border-radius: 2px;
        height: 44px;
        width: 100%;
        margin-bottom: 25px;
        padding-left: 10px;
    }

    .radio_buttom_section {
        display: flex;
        justify-items: start;
        margin-bottom: 5%;
    }

    .radio_buttom_section .form-check:nth-child(1) {
        padding-left: 20px;
    }

    .radio_buttom_section .form-check {
        padding-left: 40px;
    }

    .form-check-input[type=radio] {
        border-radius: 0px;
    }


    .order_section {
        position: relative;
        padding: 45px 0px;
    }

    .order_section_inner {
        position: relative;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
        padding: 20px 10px 20px 10px;
    }

}