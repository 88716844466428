.seller {
  /* =============main head=============== */
  @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
  /* ==============main head end============== */
  /* ==============main head start============== */
  /* ==============main head end============== */
  /* ============banner================ */
  /* ============banner================ */
  /* =========Why Seller============== */
  /* =========Why Seller============== */
  /* ==========How to Sell===================== */
  /* ==========How to Sell===================== */
  /* ===========Seller Success Stories========== */
  /* ===========Seller Success Stories========== */
  /* ========footer=============== */
  /* ========footer=============== */
  /* ===============log in================= */
  /* =========footer============== */
  /* ==========footer=========== */
  /* ===============log in================= */
  /* ===========Create Account=========== */
  /* ==========Create Account============== */
  /* ==============Billing/Business Address=============== */
  /* ================ */
  /* ==============Billing/Business Address=============== */
  /* ==============Shipping/Pickup Address=============== */
  /* ==============Shipping/Pickup Address=============== */
  /* ===============Identification Number================== */
  /* ===============Identification Number================== */
  /* ===========Subscription Plan============= */
  /* ==========Subscription Plan============== */
  /* ===========Checkout=============== */
  /* ===========Checkout============== */
  /* ==============dashboard================== */
  /* .sidebar-wrapper .sidebar-header .user-pic {
    float: left;
    width: 35px;
    padding: 2px;
    border-radius: 12px;
    margin-right: 15px;
    overflow: hidden;
  } */
  /* ==============Main_section============= */
  /* ===========Main_section============== */
  /* ===============dashboard================= */
  /* ===============orders all================= */
  /* ===============orders all================= */
  /* ===========delivered_view_information================== */
  /*  */
  /*  */
  /* ============delivered_view_information============ */
  /* ==============product_identity================ */
  /* #multi-step-form{
    overflow-x: hidden;
  } */
  /* ===========PRODUCT IDENTITY============ */
  /* ========PRODUCT IDENTITY========= */
  /* ===============product_identity=============== */
  /* ====================== */
  /* ====================== */
  /* ============ */
  /* ====================== */
  /* ==================== */
  /* ========Contact Information============= */
  /* =======Contact Information=========== */
  /*============Manage_orders_new=============  */
  /*============Manage_orders_new=============  */
  /*====================  */
  /* ============ */
  /*  */
  /* ==============Faq======================= */
  /* Faq */
}
.seller body {
  color: black;
  font-family: "Poppins", sans-serif;
  margin: 0;
  box-sizing: border-box;
  padding: 0;
}
.seller .no-padding {
  padding: 0;
}
.seller h1,
.seller h2,
.seller h3,
.seller h4,
.seller h5,
.seller h6 {
  font-family: "Poppins", sans-serif;
}
.seller :root {
  --main-color: #FF8901;
  --secondary-color: #FF8901;
  --color-3: #183822;
  --color-4: rgba(24, 56, 35, 1);
  --color-5: rgba(40, 116, 240, 1);
}
.seller a {
  text-decoration: none;
}
.seller .padding-b-t-50 {
  padding: 50px 0;
}
.seller .padding-b-50 {
  padding-bottom: 50px;
}
.seller .padding-t-50 {
  padding-top: 50px;
}
.seller .bg-primary-black {
  background: var(--color-3);
}
.seller .but_start_header {
  display: inline-block;
  border-radius: 6px;
  border: 2px solid #ffffff;
  background: var(--color-4);
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  padding: 10px 12px;
  font-size: 15px;
  letter-spacing: 1px;
}
.seller .but_start_header:hover {
  border: 2px solid var(--secondary-color);
  background: var(--secondary-color);
}
.seller .logo_main img {
  width: 130px;
}
.seller .header_padd_20 {
  padding: 0px 0;
}
.seller .banner_images img {
  width: 100%;
  max-height: 450px;
  padding: 0 5vw;
}
.seller .aboutus_content_banner h2 {
  font-size: 40px;
  font-weight: 700;
  color: #141414;
  font-family: "Poppins", sans-serif;
  padding-bottom: 30px;
}
.seller .aboutus_content_banner p {
  padding-top: 10px;
  padding-bottom: 30px;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  color: #565656;
}
.seller .color_bazar {
  color: var(--main-color);
}
.seller .main_banner_button a {
  display: inline-block;
  border-radius: 10px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 10px;
  font-size: 15px;
  letter-spacing: 1px;
}
.seller .Start-color {
  border: 1px solid var(--color-4);
  background: var(--color-4);
  color: #fff;
  margin-right: 10px;
}
.seller .more_color {
  border: 2px solid #DADADA;
  background: #DADADA;
  color: var(--color-3);
}
.seller .more_color:hover,
.seller .Start-color:hover {
  background: var(--secondary-color);
  color: #fff;
  border: 1px solid var(--secondary-color);
}
.seller .aboutus_content_banner {
  padding: 0 8vw 0 0;
}
.seller .why_three-col-wrap {
  text-align: left;
  padding: 10px 12px 15px 10px;
  border-radius: 20px;
  z-index: 1;
  background: #fff;
  border: 1px solid #fff;
  min-height: 220px;
  margin: 10px 0;
}
.seller .why_three-col-wrap:hover {
  border: 1px solid #aad3ef;
  background: #fff;
}
.seller .why_three-col-wrap img {
  width: 170px;
  height: 110px;
}
.seller .book_area_why h4 {
  font-weight: 600;
  color: #141414;
  margin: 0 0 10px;
  font-family: "Poppins", sans-serif;
  font-size: 22px;
}
.seller .book_area_why p {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: #565656;
  text-align: justify;
  margin: 0;
}
.seller .main_heading_sec h3 {
  font-size: 30px;
  font-weight: bold;
  color: #141414;
  font-family: "Poppins", sans-serif;
  padding-bottom: 20px;
}
.seller .Step_three-col-wrap img {
  width: 70px;
  padding-bottom: 10px;
}
.seller .book_area_Sell h5 {
  font-size: 15px;
  color: var(--secondary-color);
}
.seller .book_area_Sell h6 {
  font-size: 15px;
  color: var(--color-3);
}
.seller .field-icon {
  color: rgb(6, 42, 65);
  padding-top: 10px;
  padding-top: 7px;
}
.seller .seller_succes_bg {
  background: var(--color-4);
}
.seller .Seller_heading_sec {
  font-size: 30px;
  font-weight: bold;
  color: #fff;
  font-family: "Poppins", sans-serif;
  padding-bottom: 20px;
}
.seller .team_images_sec img {
  width: 100%;
}
.seller .book_image_icon img {
  width: 20px !important;
}
.seller .book_image_icon {
  padding: 20px 0;
}
.seller .head_slider_team h2 {
  font-weight: 600;
  color: #fff;
  margin: 0 0 10px;
  font-family: "Poppins", sans-serif;
  text-align: justify;
  font-size: 18px;
}
.seller .img_slider_team {
  padding: 20px 0;
  display: flex;
  align-items: center;
}
.seller .img_section img {
  border-radius: 100%;
  width: 40px !important;
  height: 40px !important;
}
.seller .text_section {
  padding-left: 10px;
}
.seller .text_section h3 {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: bold;
  color: #fff;
}
.seller .text_section p {
  font-size: 16px;
  margin-bottom: 0;
  font-weight: normal;
  color: #fff;
}
.seller .head_slider_team {
  padding: 26px 0;
}
.seller .main_detail_team {
  padding: 0 26vw 0 0;
}
.seller .main_v_slider .slider-counter {
  text-align: right;
  color: #fff;
  font-size: 17px;
  padding-right: 34px;
}
.seller .slider-main_team .owl-nav {
  position: absolute;
  top: -67px;
  right: 0;
  height: 33px;
  background: transparent;
  font-size: 18px;
  outline: none;
  cursor: pointer;
}
.seller .slider-main_team .owl-nav .owl-prev {
  border-radius: 50%;
  font-size: 27px !important;
  line-height: 30px !important;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.27);
  left: -70px;
  position: relative;
  width: 20px;
  height: 20px;
}
.seller .slider-main_team .owl-nav .owl-next {
  border-radius: 50%;
  font-size: 27px !important;
  line-height: 30px !important;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.27);
  position: relative;
  width: 20px;
  height: 20px;
}
.seller .main_Seller_new {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 15px 0;
}
.seller .bg_footer_sec {
  background: var(--color-4);
  padding: 10px 0;
}
.seller .main_footer_logo img {
  width: 120px;
}
.seller .copy_right_main a {
  font-size: 14px;
  color: #fff;
}
.seller .main_listing_footer ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.seller .main_listing_footer ul li {
  padding: 0 10px;
}
.seller .main_listing_footer ul li a {
  color: #fff;
  font-size: 15px;
}
.seller .copy_right_main {
  text-align: right;
}
.seller .main_bg_detail {
  background-image: url(../../../public/assets/images/seller_images/bg_images_log.png);
  background-repeat: no-repeat;
  background-size: auto;
}
.seller .main_login_img {
  padding: 0;
}
.seller .main_login_img img {
  width: 100%;
  height: 100%;
}
.seller .logo_login {
  text-align: center;
}
.seller .logo_login img {
  width: 150px;
  padding: 20px 0 30px;
}
.seller .main_login_detail {
  width: 100%;
  max-width: 500px;
  margin: 5vw auto;
}
.seller .detail_form_log {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
.seller .detail_form_log h3 {
  color: var(--color-4);
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  text-align: center;
  margin-bottom: 10px;
}
.seller .detail_form_log p {
  color: var(--color-3);
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  text-align: center;
  padding-bottom: 20px;
}
.seller .log_in_main {
  margin-bottom: 15px;
}
.seller .log_in_main label {
  display: inline-block;
  width: 100%;
  margin-bottom: 5px;
  font-weight: 800;
}
.seller .log_in_main input {
  height: 43px;
  border-radius: 6px;
}
.seller .log_in_main_button button {
  background: var(--color-3);
  border: none;
  font-size: 20px;
  border-radius: 8px;
  width: 100%;
  color: white;
  height: 50px;
}
.seller .log_in_main_button button:hover {
  background: var(--secondary-color);
  color: #fff;
}
.seller .forget_new_page {
  text-align: right;
  margin-bottom: 15px;
}
.seller .forget_new_page a {
  color: var(--color-3);
  margin-bottom: 15px;
}
.seller .text_login_p p {
  font-size: 16px;
  padding: 15px 0;
  text-align: center;
}
.seller .main_login_newbazar a {
  background: rgb(0, 0, 0);
  border: none;
  font-size: 16px;
  border-radius: 8px;
  color: #fff;
  height: 46px;
  padding: 12px 20px;
  text-align: center;
  margin: 0 auto;
}
.seller .main_login_newbazar {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 45px;
}
.seller .main_login_newbazar p {
  position: relative;
}
.seller .main_login_newbazar p::after {
  content: "";
  background: #ccc;
  width: 100%;
  height: 3px;
  position: absolute;
  top: 15px;
  right: 0;
  z-index: -1;
}
.seller .main_login_newbazar span {
  background: white;
  padding: 0 40px;
  font-weight: 500;
  font-size: 19px;
}
.seller .main_login_newbazar a:hover {
  background: var(--secondary-color);
  color: #fff;
}
.seller .log_in_main {
  position: relative;
}
.seller .main_show_eyes {
  position: absolute;
  top: 2.2rem;
  right: 10px;
}
.seller .main_footer_icon {
  padding: 0 0 14px;
  margin-top: -40px;
}
.seller .main_footer_icon a {
  font-size: 14px;
  color: #ccc;
}
.seller .main_footer_icon a:hover {
  color: var(--secondary-color);
}
.seller .detail_form_careate {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}
.seller .main_detail_address {
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  padding: 15px;
  background: #fff;
  margin-bottom: 15px;
}
.seller .detail_address_button {
  text-align: center;
}
.seller .detail_address_button button {
  background: var(--color-4);
  border: none;
  font-size: 20px;
  border-radius: 8px;
  color: #fff;
  height: 50px;
  padding: 0 20px;
  max-width: 300px;
  width: 100%;
}
.seller .detail_address_button button:hover {
  background: var(--secondary-color);
  color: #fff;
}
.seller .address_here_detail .input-radio {
  box-shadow: 0px 0px 0px 1px #6d6d6d;
  font-size: 3em;
  width: 30px;
  height: 23px;
  margin-right: 7px;
  border: 4px solid #fff;
  background-clip: border-box;
  border-radius: 50%;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  transition: background-color 0.3s, box-shadow 0.3s;
}
.seller .input-radio.on:checked {
  box-shadow: 0px 0px 0px 4px var(--color-3);
  background-color: var(--color-3);
  width: 30px;
  height: 23px;
}
.seller .input-radio.off:checked {
  box-shadow: 0px 0px 0px 4px var(--color-3);
  background-color: var(--color-3);
  width: 30px;
  height: 23px;
}
.seller .address_here_detail label {
  display: flex;
  align-items: unset;
}
.seller .address_here_detail label span {
  padding-left: 10px;
}
.seller .address_here_detail {
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}
.seller .flex_detail_blog {
  display: flex;
  align-items: flex-start;
}
.seller .button_home_add a {
  background: #eef4fe;
  border: none;
  font-size: 12px;
  border-radius: 8px;
  color: rgb(40, 116, 240);
  padding: 8px 20px;
}
.seller .main_add_section {
  color: var(--color-3);
  font-size: 14px;
}
.seller .main_add_section a {
  color: var(--color-3);
  font-size: 14px;
  margin-top: 10px;
  display: block;
}
.seller .edit_butt_detail a {
  background: #EFEFEF;
  border: none;
  font-size: 12px;
  border-radius: 8px;
  color: var(--color-3);
  padding: 12px 20px;
  font-weight: 500;
}
.seller .edit_butt_detail {
  margin: 20px 0;
  text-align: right;
}
.seller .main-nav-det {
  text-align: right;
}
.seller .main-nav-det a {
  color: #000;
  font-size: 14px;
}
.seller .button_home_add a:hover {
  color: #fff;
  background: var(--secondary-color);
}
.seller .edit_butt_detail a:hover {
  color: #fff;
  background: var(--main-color);
}
.seller .detail_form_Shipping {
  width: 100%;
  max-width: 600px;
}
.seller .Shipping_detail label {
  cursor: pointer;
}
.seller .Shipping_detail .input-radio {
  box-shadow: 0px 0px 0px 1px #6d6d6d;
  font-size: 3em;
  width: 20px;
  height: 20px;
  margin-right: 7px;
  border: 4px solid #fff;
  background-clip: border-box;
  border-radius: 50%;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  transition: background-color 0.3s, box-shadow 0.3s;
}
.seller .Shipping_detail .input-radio.on:checked {
  box-shadow: 0px 0px 0px 4px var(--color-3);
  background-color: var(--color-3);
  width: 20px;
  height: 20px;
}
.seller .Shipping_detail .input-radio.off:checked {
  box-shadow: 0px 0px 0px 4px var(--color-3);
  background-color: var(--color-3);
  width: 20px;
  height: 20px;
}
.seller .Shipping_detail {
  display: flex;
  align-items: center;
  margin: 14px 0;
}
.seller .Shipping_detail label {
  font-size: 14px;
  display: flex;
  align-items: center;
  padding-right: 20px;
}
.seller .Registra_heading_detail h4 {
  color: var(--color-3);
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  text-align: center;
  padding: 10px 0 15px;
}
.seller .Registra_modul_Request {
  max-width: 700px;
}
.seller .Registra_heading_detail p {
  color: #222;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  text-align: center;
  padding-bottom: 10px;
}
.seller .request_send {
  text-align: center;
}
.seller .request_send button {
  background-color: var(--main-color);
  color: var(--color-3);
  border: 0;
}
.seller .request_send button:hover {
  background-color: var(--secondary-color);
  color: #fff;
}
.seller .file-input > [type=file] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 10;
  cursor: pointer;
}
.seller .folder_main_bro {
  height: 45px;
  width: 150px;
  border-radius: 5px;
  background: #ffffff;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid var(--color-5);
  color: var(--color-5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.seller .folder_main_bro span {
  padding-right: 10px;
}
.seller .file-input {
  padding-bottom: 10px;
}
.seller .Front_and_Back {
  color: #FF0000;
  font-size: 14px;
  padding: 10px 0 0;
  border-top: 1px solid #ccc;
  display: block;
}
.seller .new_detail_id {
  position: relative;
}
.seller .main_date_detail {
  position: absolute;
  top: 2.3rem;
  right: 10px;
}
.seller .bg_det_subscription {
  background-image: url(../../../public/assets/images/seller_images/bg_images_sub.png);
  background-size: 100% 100%;
}
.seller .sub_section_main {
  padding-bottom: 40px;
}
.seller .Plan_form_sub h3 {
  color: var(--color-4);
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  text-align: center;
  margin-bottom: 10px;
}
.seller .Plan_form_sub p {
  color: var(--color-3);
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  text-align: center;
  padding-bottom: 20px;
}
.seller .code_sub_main {
  /* background: linear-gradient(0deg, #fff9f3 0%,  #f5f8fe 100%); */
  padding: 30px 30px;
  text-align: center;
  border-radius: 10px;
  margin: 0 1vw;
  margin-bottom: 20px;
  background: linear-gradient(180deg, rgba(255, 137, 1, 0.048) 0%, rgba(40, 116, 240, 0.048) 100%);
}
.seller .code_sub_main h3 {
  text-align: center;
  font-weight: 500;
  font-size: 24px;
  color: var(--color-3);
  padding: 0 0 10px;
}
.seller .code_sub_main h4 {
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  color: var(--color-5);
  padding-bottom: 30px;
}
.seller .code_sub_main h5 {
  text-align: left;
  font-weight: 500;
  font-size: 18px;
  color: var(--color-3);
  padding-bottom: 0;
}
.seller .code_sub_main ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
  padding-bottom: 30px;
}
.seller .code_sub_main ul li {
  text-align: left;
  font-size: 16px;
  padding-bottom: 10px;
}
.seller .code_sub_main h2 {
  text-align: center;
  font-weight: 500;
  font-size: 24px;
  color: var(--color-3);
  padding: 0 0 10px;
}
.seller .code_sub_main button {
  background: var(--main-color);
  border: none;
  font-size: 20px;
  border-radius: 8px;
  color: var(--color-3);
  height: 50px;
  padding: 0 20px;
}
.seller .code_sub_main button:hover {
  background: var(--secondary-color);
  color: #fff;
}
.seller .pb-main {
  padding-bottom: 5rem;
}
.seller .main_detail_Plan {
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  padding: 15px;
  background: #fff;
  margin-bottom: 40px;
}
.seller .main_Checkout_Plan {
  text-align: left;
  padding: 30px;
}
.seller .main_Checkout_Plan h3 {
  text-align: left;
  font-weight: 500;
  font-size: 24px;
  color: var(--color-3);
  padding: 0 0 10px;
}
.seller .main_Checkout_Plan h5 {
  text-align: left;
  font-weight: 500;
  font-size: 18px;
  color: var(--main-color);
  padding-bottom: 0;
  cursor: pointer;
}
.seller .main_Checkout_Plan ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
  padding-bottom: 30px;
}
.seller .main_Checkout_Plan ul li {
  text-align: left;
  font-size: 14px;
  padding-bottom: 10px;
}
.seller .main_map_sub span {
  text-decoration: line-through;
  color: #a3a3a3;
  padding-right: 5px;
}
.seller .main_map_sub span img {
  padding-left: 8px;
}
.seller .main_select select {
  height: 43px;
  border-radius: 6px;
  width: 100%;
  border: 1px solid #ccc;
}
.seller .main_section_detail {
  display: flex;
}
.seller .main_section_detail select {
  width: auto;
  margin-right: 10px;
}
.seller .bor_left_sec {
  border-left: 1px solid #ccc;
}
.seller .main-nav {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  box-shadow: 0 2px 7px rgba(30, 30, 30, 0.1);
  width: 100%;
}
@keyframes swing {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(10deg);
  }
  30% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(0deg);
  }
  60% {
    transform: rotate(5deg);
  }
  70% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes sonar {
  0% {
    transform: scale(0.9);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
.seller .page-wrapper .sidebar-wrapper,
.seller .sidebar-wrapper .sidebar-brand > a,
.seller .sidebar-wrapper .sidebar-dropdown > a:after,
.seller .sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before,
.seller .sidebar-wrapper ul li a i,
.seller .page-wrapper .page-content,
.seller .sidebar-wrapper .sidebar-search input.search-menu,
.seller .sidebar-wrapper .sidebar-search .input-group-text,
.seller .sidebar-wrapper .sidebar-menu ul li a,
.seller #show-sidebar,
.seller #close-sidebar {
  transition: all 0.3s ease;
}
.seller .page-wrapper {
  height: 100vh;
}
.seller .page-wrapper .theme {
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 4px;
  margin: 2px;
}
.seller .page-wrapper .theme.chiller-theme {
  background: #1e2229;
}
.seller .page-wrapper.toggled .sidebar-wrapper {
  left: 0px;
}
.seller #show-sidebar {
  position: fixed;
  left: 0;
  top: 10px;
  border-radius: 0 4px 4px 0px;
  width: 35px;
  transition-delay: 0.3s;
}
.seller .page-wrapper.toggled #show-sidebar {
  left: -40px;
}
.seller .sidebar-wrapper {
  width: 260px;
  height: 100%;
  max-height: 100%;
  position: fixed;
  top: 0;
  left: -300px;
  z-index: 999;
}
.seller .sidebar-wrapper ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.seller .sidebar-wrapper a {
  text-decoration: none;
}
.seller .sidebar-content {
  max-height: calc(100% - 30px);
  height: calc(100% - 30px);
  overflow-y: auto;
  position: relative;
}
.seller .sidebar-content.desktop {
  overflow-y: hidden;
}
.seller .posi_section {
  cursor: pointer;
}
.seller .sidebar-wrapper .sidebar-brand {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.seller .sidebar-wrapper .sidebar-brand > a {
  text-transform: uppercase;
  font-weight: bold;
  flex-grow: 1;
}
.seller .sidebar-wrapper .sidebar-brand #close-sidebar {
  cursor: pointer;
  font-size: 20px;
}
.seller .sidebar-wrapper .sidebar-header {
  padding: 10px 20px 10px 20px;
  overflow: hidden;
}
.seller .sidebar-wrapper .sidebar-header .user-pic img {
  width: 130px;
}
.seller .sidebar-wrapper .sidebar-header .user-info {
  float: left;
}
.seller .sidebar-wrapper .sidebar-header .user-info > span {
  display: block;
}
.seller .sidebar-wrapper .sidebar-header .user-info .user-role {
  font-size: 12px;
}
.seller .sidebar-wrapper .sidebar-header .user-info .user-status {
  font-size: 11px;
  margin-top: 4px;
}
.seller .sidebar-wrapper .sidebar-header .user-info .user-status i {
  font-size: 8px;
  margin-right: 4px;
  color: #5cb85c;
}
.seller .sidebar-wrapper .sidebar-search > div {
  padding: 10px 20px;
}
.seller .sidebar-wrapper .sidebar-menu {
  padding-bottom: 10px;
}
.seller .sidebar-wrapper .sidebar-menu .header-menu span {
  font-weight: bold;
  font-size: 14px;
  padding: 15px 20px 5px 20px;
  display: inline-block;
}
.seller .sidebar-wrapper .sidebar-menu ul li a {
  display: inline-block;
  width: 100%;
  text-decoration: none;
  position: relative;
  padding: 8px 30px 8px 20px;
}
.seller .sidebar-wrapper .sidebar-menu ul li a i {
  margin-right: 10px;
  font-size: 12px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 4px;
}
.seller .sidebar-wrapper .sidebar-menu ul li a:hover > i::before {
  display: inline-block;
  animation: swing ease-in-out 0.5s 1 alternate;
}
.seller .sidebar-wrapper .sidebar-menu .sidebar-dropdown > a:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f105";
  font-style: normal;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background: 0 0;
  position: absolute;
  right: 15px;
  top: 14px;
}
.seller .sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu ul {
  padding: 5px 0;
}
.seller .sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li {
  padding-left: 25px;
  font-size: 13px;
}
.seller .sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before {
  content: "\f111";
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
  font-style: normal;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 10px;
  font-size: 8px;
}
.seller .sidebar-wrapper .sidebar-menu ul li a span.label,
.seller .sidebar-wrapper .sidebar-menu ul li a span.badge {
  float: right;
  margin-top: 8px;
  margin-left: 5px;
}
.seller .sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .badge,
.seller .sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .label {
  float: right;
  margin-top: 0px;
}
.seller .sidebar-wrapper .sidebar-menu .sidebar-submenu {
  display: none;
}
.seller .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active > a:after {
  transform: rotate(90deg);
  right: 17px;
}
.seller .sidebar-footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  display: flex;
}
.seller .sidebar-footer > a {
  flex-grow: 1;
  text-align: center;
  height: 30px;
  line-height: 30px;
  position: relative;
}
.seller .sidebar-footer > a .notification {
  position: absolute;
  top: 0;
}
.seller .badge-sonar {
  display: inline-block;
  background: #980303;
  border-radius: 50%;
  height: 8px;
  width: 8px;
  position: absolute;
  top: 0;
}
.seller .badge-sonar:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border: 2px solid #980303;
  opacity: 0;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  animation: sonar 1.5s infinite;
}
.seller .page-wrapper .page-content {
  display: inline-block;
  width: 100%;
  padding-left: 0px;
  padding-top: 0;
}
.seller ::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}
.seller ::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
.seller ::-webkit-scrollbar-thumb {
  background: #525965;
  border: 0px none #ffffff;
  border-radius: 0px;
}
.seller ::-webkit-scrollbar-thumb:hover {
  background: #525965;
}
.seller ::-webkit-scrollbar-thumb:active {
  background: #525965;
}
.seller ::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none #ffffff;
  border-radius: 50px;
}
.seller ::-webkit-scrollbar-track:hover {
  background: transparent;
}
.seller ::-webkit-scrollbar-track:active {
  background: transparent;
}
.seller ::-webkit-scrollbar-corner {
  background: transparent;
}
.seller .chiller-theme .sidebar-wrapper {
  background: var(--color-4);
}
.seller .chiller-theme .sidebar-wrapper .sidebar-header,
.seller .chiller-theme .sidebar-wrapper .sidebar-search {
  border-top: 1px solid #F6F6F6;
}
.seller .chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.seller .chiller-theme .sidebar-wrapper .sidebar-search .input-group-text {
  border-color: transparent;
  box-shadow: none;
}
.seller .chiller-theme .sidebar-wrapper .sidebar-header .user-info .user-role,
.seller .chiller-theme .sidebar-wrapper .sidebar-header .user-info .user-status,
.seller .chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.seller .chiller-theme .sidebar-wrapper .sidebar-search .input-group-text,
.seller .chiller-theme .sidebar-wrapper .sidebar-brand > a,
.seller .chiller-theme .sidebar-wrapper .sidebar-menu ul li a,
.seller .chiller-theme .sidebar-footer > a {
  color: #fff;
}
.seller .chiller-theme .sidebar-wrapper .sidebar-menu ul li:hover > a,
.seller .chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active > a,
.seller .chiller-theme .sidebar-wrapper .sidebar-header .user-info,
.seller .chiller-theme .sidebar-wrapper .sidebar-brand > a:hover,
.seller .chiller-theme .sidebar-footer > a:hover i {
  color: var(--main-color);
}
.seller .page-wrapper.chiller-theme.toggled #close-sidebar {
  color: #fff;
  padding: 3px 10px;
  height: 34px;
  background: var(--secondary-color);
  width: 34px;
  text-align: center;
  border-radius: 5px;
  position: absolute;
  top: 10px;
  right: 5px;
}
.seller .page-wrapper.chiller-theme.toggled #close-sidebar:hover {
  color: #fff;
  background: var(--main-color);
}
.seller .chiller-theme .sidebar-wrapper ul li:hover a i,
.seller .chiller-theme .sidebar-wrapper .sidebar-dropdown .sidebar-submenu li a:hover:before,
.seller .chiller-theme .sidebar-wrapper .sidebar-search input.search-menu:focus + span,
.seller .chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active a i {
  color: #16c7ff;
  text-shadow: 0px 0px 10px rgba(22, 199, 255, 0.5);
}
.seller .chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.seller .chiller-theme .sidebar-wrapper .sidebar-search .input-group-text {
  background: #f6f6f6;
}
.seller .chiller-theme .sidebar-wrapper .sidebar-menu .header-menu span {
  color: #6c7b88;
}
.seller .chiller-theme .sidebar-footer {
  background: #3a3f48;
  box-shadow: 0px -1px 5px #282c33;
  border-top: 1px solid #464a52;
}
.seller .chiller-theme .sidebar-footer > a:first-child {
  border-left: none;
}
.seller .chiller-theme .sidebar-footer > a:last-child {
  border-right: none;
}
.seller .main_icon_hover i img {
  filter: sepia(1);
}
.seller .main_icon_hover:hover {
  color: var(--main-color) !important;
}
.seller .main_icon_hover:hover i img {
  filter: none;
}
.seller .user-name {
  color: #222;
}
.seller .das_bord_slider .active a {
  background: #fff;
  border: 1px solid var(--main-color);
  color: var(--main-color);
}
.seller .das_bord_slider .active a i img {
  filter: none;
}
.seller .das_bord_slider .active a {
  color: var(--main-color);
}
.seller #show-sidebar {
  background: var(--main-color);
  border: 1px solid var(--main-color);
}
.seller .sticky {
  position: sticky;
  top: 0;
  z-index: 99;
}
.seller .page-content-main {
  background-color: #fff !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.34);
  padding: 0 10px !important;
}
.seller .main-nav-start {
  padding: 0 10px;
}
.seller .main-nav-start {
  width: 100%;
}
.seller .head-title-main {
  color: #333333;
  font-weight: 600;
  font-size: 20px;
}
.seller .header_detail_nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.seller .pro_nav_icon img {
  width: 18px;
}
.seller .posi_section {
  position: relative;
}
.seller .email_color {
  background: var(--main-color);
}
.seller .bell_color {
  background: var(--secondary-color);
}
.seller .header_detail_nav ul {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
}
.seller .header_detail_nav ul li {
  padding-left: 20px;
}
.seller .pro_nav_icon {
  padding: 0 20px;
}
.seller .pro_nav_icon-span {
  max-height: 20px;
  max-width: 20px;
  height: 100%;
  width: 100%;
  border-radius: 100%;
  display: block;
  color: var(--color-3);
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  line-height: 1.8;
  position: absolute;
  top: -9px;
  left: 14px;
}
.seller .profile_detail a {
  color: var(--color-3);
  font-size: 16px;
}
.seller .profile_detail a span {
  padding-right: 10px;
}
.seller .main-nav {
  box-shadow: none;
}
.seller .main_drop_new_week {
  width: 171px;
  right: 0;
  padding: 0;
  top: 10px !important;
}
.seller .main_drop_new_week li {
  padding-left: 0 !important;
}
.seller .main_drop_new_week li a {
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 2.43;
  color: #767676;
  border-bottom: 1px solid #eee;
  text-align: left;
  display: block;
  padding: 5px;
}
.seller .nav-user-dropdown {
  display: block !important;
}
.seller .main-nav-Order h2 {
  font-weight: 600;
  font-size: 30px;
  line-height: 1.42;
  color: #000;
}
.seller .main-nav-Order {
  padding: 15px 10px;
}
.seller .stat-cards-item {
  padding: 15px 10px 0;
  background-color: #fff;
  box-shadow: 0px 0px 3px 1px rgba(197, 197, 197, 0.5803921569);
  border-radius: 5px;
  height: 100%;
  cursor: pointer;
  margin-bottom: 15px;
}
.seller .daai_icon_img {
  display: flex;
  align-items: baseline;
}
.seller .stat-cards-info p {
  color: #000000;
  font-size: 20px;
  font-weight: 600;
  padding: 0 0 20px;
  margin: 0;
}
.seller .stat-cards-info__num {
  color: #000000;
  font-size: 20px;
  font-weight: 600;
}
.seller .stat-cards-info-right img {
  border-radius: 10px;
  width: 45px;
}
.seller .stat-cards-info-right {
  margin-right: 20px;
}
.seller .stat-cards-info__title {
  color: var(--secondary-color) !important;
  font-size: 25px !important;
  padding-bottom: 0 !important;
}
.seller .stat-cards-info__1 {
  color: var(--main-color) !important;
}
.seller .stat-cards-info__2 {
  color: #7476ed !important;
}
.seller .stat-cards-info__3 {
  color: #00ff29 !important;
}
.seller .overall_sales {
  padding: 7px 15px 0;
  background-color: #fff;
  box-shadow: 0px 0px 3px 1px rgba(197, 197, 197, 0.5803921569);
  border-radius: 5px;
  height: 100%;
}
.seller .over_sales_main {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-bottom: 10px;
}
.seller .month_this_button {
  display: inline-block;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 2.43;
  background-color: #e6e6e6;
  color: var(--color-3);
  border-bottom: 1px solid #eee;
  text-align: left;
  padding: 4px 10px;
  border-radius: 10px;
}
.seller .month_this_button:hover {
  color: #fff;
  background-color: var(--secondary-color);
}
.seller .main_drop_new_week ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.seller .main_drop_new_week ul li a {
  padding: 0 10px;
  color: var(--color-3);
}
.seller .main_detail_sales h3 {
  padding: 5px 0;
  font-size: 24px;
  color: var(--color-3);
  margin-bottom: 0;
}
.seller .main_detail_sales h6 {
  padding: 5px 0;
  font-size: 18px;
  margin-bottom: 0;
}
.seller .users-table table {
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  transition: none;
}
.seller .users-table-info-new th {
  font-weight: 500;
  padding: 10px;
  color: #333;
}
.seller .td_section_text td {
  font-weight: normal;
  padding: 10px;
  color: #000;
}
.seller .pages-table-img {
  display: flex;
  align-items: center;
}
.seller .pages-table-img picture {
  padding: 0 5px;
  border-radius: 100%;
}
.seller .users-table-text {
  text-align: center;
}
.seller .main-nav-det-button {
  text-align: right;
  margin-top: 27px;
  margin-bottom: 20px;
}
.seller .main-nav-det-button a {
  padding: 10px 20px;
  border-radius: 3x;
  font-size: 15px;
  font-weight: 600;
  margin-left: 10px;
  color: #000;
  background: #ccc;
}
.seller .users-table-tariff th {
  padding: 15px 10px;
}
.seller .table_detail_tariff .posts-table td {
  padding: 10px;
  vertical-align: baseline;
}
.seller .table_detail_tariff .posts-table {
  width: 100%;
  border: 1px solid #f0f0f0;
}
.seller .main_table_row_new {
  border-bottom: 1px solid #f0f0f0;
}
.seller .users-table-tariff {
  background-color: #F0F0F0;
  color: #000;
}
.seller .selling_products {
  padding: 20px 0;
  font-size: 20px;
  font-weight: 600;
  color: #000;
}
.seller .img_detail_new a img {
  width: 20px;
  margin-left: 10px;
}
.seller .xs_logo_show_detail {
  display: none;
}
.seller .main-nav-det-button a:hover {
  background: var(--secondary-color);
  color: #fff;
}
.seller .img_detail_new {
  display: flex;
}
.seller .card_order_main {
  padding: 10px 0;
}
.seller .all_order_main {
  border-bottom: 0 !important;
}
.seller .all_order_main button {
  border: 0 !important;
  color: var(--color-3);
  border-bottom: 2px solid #fff;
}
.seller .all_order_main .active {
  color: var(--color-5) !important;
  border-bottom: 2px solid var(--color-5) !important;
}
.seller .filter_button_section {
  text-align: right;
}
.seller .filter_button_section a {
  font-weight: 500;
  font-size: 14px;
  line-height: 2.43;
  background-color: #e6e6e6;
  color: var(--color-3);
  border-bottom: 1px solid #eee;
  text-align: left;
  padding: 10px;
  border-radius: 10px;
}
.seller .filter_button_section a:hover {
  background-color: var(--secondary-color);
  color: #fff;
}
.seller .filter_button_section a span {
  margin-right: 10px;
}
.seller .product_images_sec img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
}
.seller .main_detail_butt {
  background: #ecfaf7;
  padding: 10px 18px;
  color: #16C098;
}
.seller .main_detail_butt_1 {
  background: #fffbeb;
  padding: 10px 18px;
  color: #ffc700;
}
.seller .main_detail_butt span {
  background: #16C098;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  display: inline-flex;
}
.seller .main_detail_butt_1 span {
  background: #ffc700;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  display: inline-flex;
}
.seller .main_detail_butt_2 span {
  background: #ff0000;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  display: inline-flex;
}
.seller .main_detail_butt_2 {
  background: #ffebeb;
  padding: 10px 18px;
  color: #ff0000;
}
.seller .text-cen {
  text-align: center;
}
.seller .main_detail_numbar ul {
  justify-content: end;
  margin: 20px 0;
  display: flex;
  list-style: none;
  padding: 0;
}
.seller .pagination .disabled a {
  color: #777;
  cursor: pointer;
  background-color: #fff;
  border-color: #ddd;
  border: 1px solid;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.seller .main_detail_numbar .pagination_main li {
  margin: 0 5px;
}
.seller .main_detail_numbar .pagination_main li a {
  color: #777;
  cursor: pointer;
  background-color: #fff;
  border-color: #ddd;
  border: 1px solid;
  height: 38px;
  width: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.seller .main_detail_numbar .pagination_main li a:hover {
  background-color: var(--secondary-color) !important;
  border-color: var(--secondary-color) !important;
  color: #fff !important;
}
.seller .pagination_main .active a {
  background-color: var(--color-4) !important;
  border-color: var(--color-4) !important;
  color: #fff !important;
}
.seller .main-nav-Order h2 span {
  color: #7B7B7B;
}
.seller .all_Information_main {
  border-bottom: 0 !important;
  display: flex;
  justify-content: space-between;
}
.seller .main-nav-Order h2 a img {
  width: 20px;
  margin-right: 10px;
}
.seller .all_Information_main {
  border-bottom: 0 !important;
}
.seller .all_Information_main button {
  border: 0 !important;
  padding: 20px 0 10px;
}
.seller .all_Information_main img {
  border-radius: 5px;
}
.seller .main_view_detail {
  border: 0;
}
.seller .main_view_detail img {
  width: 100%;
  border-radius: 10px;
  max-height: 270px;
}
.seller .height_auto {
  height: auto;
  padding-bottom: 10px;
}
.seller .td_section_text_order td {
  font-weight: normal;
  padding: 5px;
  color: #7B7B7B;
}
.seller .td_section_text_order .table-text-color {
  color: var(--color-3);
}
.seller .panding_color {
  color: var(--main-color);
}
.seller .rating-container {
  max-width: 330px;
}
.seller .rating-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.seller .stars {
  font-size: 24px;
  color: var(--main-color) !important;
  padding-right: 15px;
}
.seller .total-ratings {
  font-size: 14px;
  color: #555;
}
.seller .rating-bars {
  display: flex;
  flex-direction: column;
}
.seller .rating-bar {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.seller .rating-label {
  width: 50px;
  font-size: 14px;
  color: #555;
}
.seller .bar {
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  flex-grow: 1;
  margin: 0 20px;
  height: 25px;
}
.seller .fill {
  height: 100%;
  background-color: var(--main-color);
}
.seller .percentage {
  width: 40px;
  font-size: 14px;
  color: #555;
  text-align: right;
}
.seller .rating-bar.active span {
  color: var(--secondary-color);
}
.seller .bor_right_main {
  border-right: 1px solid #ccc;
}
.seller .feedbacks_detail_section {
  max-width: 600px;
  margin: 0 0 0 auto;
}
.seller .user-image {
  width: 50px;
  height: 50px;
}
.seller .review-text {
  font-size: 14px;
  color: #555;
  margin: 5px 0;
}
.seller .verified-seller {
  font-size: 14px;
}
.seller .verified-seller:hover {
  text-decoration: underline;
}
.seller .all_Information_main button {
  width: 30%;
}
.seller .all_Information_main button img {
  width: 100%;
  height: 110px;
}
.seller .delivered_color {
  color: #16C098;
}
.seller .cancelled_color {
  color: #FF0000;
}
.seller .round_Products {
  position: relative;
}
.seller .round_Products label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px;
}
.seller .round_Products label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 5px;
  opacity: 0;
  position: absolute;
  top: 6px;
  transform: rotate(-45deg);
  width: 12px;
}
.seller .round_Products input[type=checkbox] {
  visibility: hidden;
}
.seller .round_Products input[type=checkbox]:checked + label {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}
.seller .round_Products input[type=checkbox]:checked + label:after {
  opacity: 1;
}
.seller .add_button_product {
  text-align: right;
}
.seller .add_button_product a {
  font-weight: 500;
  font-size: 14px;
  line-height: 2.43;
  background-color: var(--color-4);
  color: #fff;
  border-bottom: 1px solid var(--color-4);
  text-align: left;
  padding: 10px;
  border-radius: 10px;
}
.seller .add_button_product a:hover {
  background-color: var(--main-color);
  color: #fff;
  border-bottom: 1px solid var(--main-color);
}
.seller .add_button_product a span {
  padding-right: 10px;
}
.seller .step-container {
  position: relative;
  text-align: center;
  transform: translateY(-155%);
}
.seller .step-line {
  position: absolute;
  top: 16px;
  left: 50px;
  width: calc(100% - 100px);
  height: 2px;
  background-color: #007bff;
  z-index: -1;
}
.seller .progress {
  background-color: #ddd;
  border-radius: 5px;
}
.seller .progress-bar {
  height: 3px;
  background-color: #007bff;
}
.seller .step-container {
  margin-top: 20px;
}
.seller .step-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}
.seller .step-circle.active {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  border: 5px solid var(--secondary-color);
}
.seller .step.active {
  display: block;
}
.seller .btn-primary:hover {
  background-color: #0056b3;
}
.seller .btn-success {
  background-color: #28a745;
  border: none;
  padding: 10px 20px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}
.seller .btn-success:hover {
  background-color: #218838;
}
.seller .m_right {
  left: -4.5rem !important;
}
.seller .m_left {
  right: 0;
  left: 0 !important;
}
.seller .step-label {
  position: absolute;
  top: calc(100% + 8px);
  left: 50%;
  transform: translateX(-50%) perspective(1000px);
  white-space: nowrap;
  font-weight: 600;
  transition: 0.3s ease;
}
.seller .active .step-label {
  color: #0056b3 !important;
}
.seller .main_detail_save {
  padding: 20px 0;
  border-top: 1px solid #ccc;
  text-align: right;
}
.seller .btn-Save-Draft {
  border: 1px solid #9A9A9A;
  color: #333;
}
.seller .main_detail_identity {
  padding: 20px 0;
}
.seller .please_fill_main {
  padding: 20px 0;
  font-size: 16px;
  font-weight: 600;
  border-bottom: 2px solid #ccc;
}
.seller .please_fill_main h6 {
  font-size: 16px;
  font-weight: 600;
  padding: 0;
  margin: 0;
}
.seller .variations h4 {
  font-size: 16px;
  font-weight: 600;
  padding: 0;
  margin: 0;
}
.seller .variations small {
  font-weight: normal;
  color: #ccc;
}
.seller .round_Products-new input {
  width: 20px;
  height: 20px;
  position: relative;
  top: 5px;
}
.seller .add_button_detail {
  text-align: left;
  margin-top: 40px;
}
.seller .add_button_detail a {
  background: #dedede;
  padding: 5px 10px;
  border: 1px solid #9A9A9A;
  border-radius: 10px;
  color: var(--color-3);
}
.seller .add_button_detail img {
  padding-right: 10px;
}
.seller .input_box_section textarea {
  resize: none;
}
.seller .input_box_section textarea {
  border: 1px solid #b3b3b3;
  padding: 5px 10px;
  width: 100%;
  background-color: #fff;
  margin-top: 10px;
  border-radius: 5px;
}
.seller .choices__inner {
  background-color: #fff;
  border-radius: 10px;
}
.seller .choices__input {
  background-color: #fff;
  border-radius: 10px;
}
.seller .main_select_color {
  position: relative;
}
.seller .main_select label {
  font-weight: 800;
}
.seller .main_select_color span {
  position: absolute;
  top: 37px;
  z-index: 99;
  right: 10px;
}
.seller .product_cemra img {
  width: 30px;
}
.seller .product_cemra {
  position: relative;
  cursor: pointer;
}
.seller .product_cemra > [type=file] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 10;
  cursor: pointer;
}
.seller .main_input_price {
  height: 43px;
  border-radius: 6px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  width: 180px;
}
.seller .main_input_price span {
  font-weight: bold;
  padding: 0 5px;
  color: #333;
}
.seller .main_input_price input {
  width: 130px;
  height: 43px;
  border-radius: 0 6px 6px 0;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  padding: 0 5px;
  text-align: center;
}
.seller .main_input_price input:focus {
  outline: none;
  border: 1px solid #ccc;
}
.seller .input_price_main input {
  height: 43px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 120px;
  padding: 0 5px;
  text-align: center;
}
.seller .card-add-product {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #d2d2dc;
  border-radius: 0;
}
.seller .card-add-product .card-title {
  color: #000000;
  margin-bottom: 0.625rem;
  font-size: 0.875rem;
  font-weight: 500;
}
.seller .card-add-product .card-description {
  margin-bottom: 0.875rem;
  font-weight: 400;
  color: #76838f;
}
.seller .table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.seller .jsgrid .jsgrid-table {
  width: 100%;
  max-width: 100%;
  background-color: transparent;
}
.seller .main_detail_section {
  margin-bottom: 0;
}
.seller .main_detail_section .table thead th,
.seller .jsgrid .jsgrid-table thead th {
  border-top: 0;
  border-bottom-width: 1px;
  font-weight: 600;
  font-size: 0.875rem;
  text-transform: uppercase;
}
.seller .main_detail_section .table td,
.seller .jsgrid .jsgrid-table td {
  font-size: 0.875rem;
  padding: 0.475rem 0.4375rem;
}
.seller .badge-danger {
  color: #fff;
  background-color: #dc3545;
  cursor: pointer;
}
.seller .badge-success {
  color: #fff;
  background-color: #28a745;
}
.seller .badge {
  border-radius: 0;
  font-size: 12px;
  line-height: 1;
  font-weight: normal;
  border: none;
  padding: 10px;
  border-radius: 6px;
}
.seller .badge_add {
  border-radius: 0;
  font-size: 12px;
  line-height: 1;
  font-weight: normal;
  border: none;
  padding: 10px;
  border-radius: 6px;
}
.seller .folder_main_img {
  height: 45px;
  /* width: 150px; */
  border-radius: 5px;
  background: #ffffff;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid var(--color-5);
  color: var(--color-5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.seller #signUpForm {
  margin: 40px auto;
  border-radius: 12px;
}
.seller #signUpForm .form-header {
  gap: 5px;
  text-align: center;
  font-size: 0.9em;
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
}
.seller #signUpForm .form-header .stepIndicator {
  position: relative;
  flex: 1;
  font-weight: 600;
}
.seller #signUpForm .form-header .stepIndicator.active {
  font-weight: 600;
  color: var(--secondary-color);
}
.seller #signUpForm .form-header .stepIndicator.finish {
  font-weight: 600;
  color: #009688;
}
.seller #signUpForm .form-header .stepIndicator::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 30px;
  transform: translateX(-50%);
  z-index: 9;
  width: 20px;
  height: 20px;
  background-color: #ddd;
  border-radius: 50%;
  border: 3px solid #ddd;
}
.seller #signUpForm .form-header .stepIndicator.active::before {
  background-color: #fff;
  border: 3px solid var(--secondary-color);
}
.seller #signUpForm .form-header .stepIndicator.finish::before {
  background-color: var(--secondary-color);
  border: 3px solid var(--secondary-color);
}
.seller #signUpForm .form-header .stepIndicator::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 37px;
  width: 100%;
  height: 3px;
  background-color: #f3f3f3;
}
.seller #signUpForm .form-header .stepIndicator.active::after {
  background-color: var(--secondary-color);
}
.seller #signUpForm .form-header .stepIndicator.finish::after {
  background-color: var(--secondary-color);
}
.seller #signUpForm .form-header .stepIndicator:last-child:after {
  display: none;
}
.seller #signUpForm .form-footer {
  overflow: auto;
  gap: 20px;
  justify-content: flex-end;
}
.seller #signUpForm .form-footer button:hover {
  opacity: 0.8;
}
.seller .dasbord-main_model {
  width: 100%;
  min-width: 800px;
  margin: 40px auto;
  z-index: 999;
}
.seller .dasbord-main_model .modal-content {
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 11px;
  border-bottom: 7px solid #c4c0c0;
}
.seller .smart-box-section {
  background-color: #fff;
  border-radius: 12px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
}
.seller .main_hading_popup {
  margin-top: 15px;
}
.seller .upload-box-inner {
  background: #e2eced;
  text-align: center;
  font-size: 20px;
  border-radius: 10px;
  width: 150px;
  height: 124px;
  margin-bottom: 25px !important;
  margin: 10px;
  position: relative;
}
.seller .upload-box-inner .file-element {
  position: absolute;
  inset: 0;
  z-index: 2;
  opacity: 0;
  cursor: pointer;
}
.seller .upload-box-inner .placeholder-icon {
  width: 80px;
  aspect-ratio: 1;
}
.seller .upload-box-main {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-height: 250px;
  overflow-y: scroll;
}
.seller .upload-box-inner span {
  background: #e2eced;
  text-align: center;
  font-size: 20px;
  border-radius: 10px;
}
.seller .upload-box-inner h3 {
  font-size: 17px;
  font-weight: bold;
  color: #003033;
}
.seller .main_hading_popup h3 {
  font-size: 17px;
  font-weight: bold;
  color: #003033;
}
.seller .main_detail_head h5 {
  font-size: 15px;
  color: #000;
}
.seller .main_detail_head h6 {
  font-size: 15px;
  color: #1b77fd;
}
.seller .main_detail_head p {
  font-size: 15px;
  color: #000;
}
.seller .main-img-icon {
  margin-top: 15px;
  width: 40px;
  margin-bottom: 6px;
}
.seller .product_identity {
  background: #fff;
  box-shadow: 0px 0px 5px 1px #f3f3f3;
  border-radius: 10px;
}
.seller .product_identity h4 {
  background: #E8E8E8;
  padding: 10px;
  border-radius: 10px 10px 0 0;
}
.seller .detail_product_ident {
  padding: 0 10px;
}
.seller .dis-detail-new {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.seller .form-footer-button {
  text-align: right;
}
.seller .Back-button {
  background: #fff;
  color: #000;
  border-radius: 5px;
  border: 1px solid #9A9A9A;
}
.seller .send-approval-butt {
  background: var(--color-4);
  color: #fff;
  border-radius: 5px;
  border: 1px solid var(--color-4);
}
.seller .send-approval-butt:hover {
  background: var(--main-color);
  color: #fff;
  border-radius: 5px;
  border: 1px solid var(--main-color);
}
.seller .Back-button:hover {
  background: var(--main-color);
  color: #fff;
  border-radius: 5px;
  border: 1px solid var(--main-color);
}
.seller .request_send_for_modul {
  text-align: center;
}
.seller .request_send_for_modul h6 {
  font-size: 20px;
  color: #000;
  padding-bottom: 15px;
}
.seller .request_send_for_modul p {
  font-size: 14px;
  color: #ccc;
  padding-bottom: 15px;
}
.seller .pro_detail_main {
  display: flex;
  align-items: flex-start;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.34);
  background: linear-gradient(90deg, rgba(40, 116, 240, 0.04) 0%, rgba(255, 137, 1, 0.04));
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 15px;
}
.seller .col-img-main {
  margin-right: 15px;
}
.seller .col-text-main h4 {
  font-size: 18px;
  color: #000;
  margin-bottom: 20px;
}
.seller .col-text-main p {
  font-size: 15px;
  color: #646464;
}
.seller .pro_bg_main {
  height: 100%;
  position: relative;
}
.seller .pro_bg_main img {
  height: 100%;
  width: 100%;
  max-height: 300px;
}
.seller .pro_bg_main span img {
  width: 70px;
  height: 70px;
  border-radius: 100%;
  border: 3px solid var(--secondary-color);
  position: relative;
  margin-top: -65px;
  left: 10px;
}
.seller .name_profile_main h4 {
  font-size: 18px;
}
.seller .name_profile_main ul {
  display: flex;
  list-style: none;
  padding: 0;
}
.seller .name_profile_main ul li {
  padding-right: 10px;
}
.seller .name_profile_main ul li a span {
  padding-right: 6px;
}
.seller .main_detail_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid rgb(227, 227, 227);
}
.seller .m_d_bottom {
  border-bottom: 2px solid rgb(227, 227, 227) !important;
}
.seller .main_account_butt {
  background: rgb(218, 79, 79);
  padding: 8px 15px;
  border-radius: 8px;
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 600;
  color: #fff;
}
.seller .heightmainsection {
  height: 100%;
  min-height: 350px;
  border-bottom: 2px solid rgb(227, 227, 227);
  margin-bottom: 10px;
}
.seller .main-in-popup-space {
  margin-top: 6rem;
}
.seller .btn-close-into {
  position: absolute;
  top: -50px;
  opacity: 1;
  background: #ffffff;
  right: 0;
  line-height: 2.4;
  height: 32px;
  width: 32px;
  text-align: center;
  border-radius: 100%;
}
.seller .input_detail_section {
  margin-bottom: 15px;
}
.seller .input_detail_section {
  display: flex;
  align-items: baseline;
}
.seller .input_detail_section h6 {
  width: 220px;
  color: #000;
  font-size: 16px;
  padding-right: 20px;
}
.seller .text-right {
  text-align: right;
}
.seller .main-nav-Order h2 a {
  font-weight: 600;
  font-size: 30px;
  line-height: 1.42;
  color: #000;
}
.seller .detail_heading {
  padding: 20px 0 15px;
}
.seller .detail_heading h4 {
  font-size: 18px;
  color: #000;
}
.seller .detail_heading p {
  font-size: 15px;
  color: #000;
  margin-bottom: 0;
}
.seller .folder_main_bro_main {
  height: 43px;
  width: 100%;
  display: flex;
  border-radius: 5px;
  background: #ffffff;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid #dee2e6;
  color: #595c5f;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.seller .main_turn_inventory label {
  font-family: "Montserrat", sans-serif;
  font-size: 1.2rem;
  cursor: pointer;
  display: block;
  margin: 6px;
}
.seller .main_turn_inventory label > input {
  display: none;
}
.seller .main_turn_inventory label span {
  color: #000;
}
.seller .main_turn_inventory {
  text-align: right;
}
.seller .main_turn_inventory label i {
  display: inline-block;
  width: 56px;
  height: 28px;
  border-radius: 20px;
  vertical-align: middle;
  transition: 0.25s 0.09s;
  position: relative;
  background: #deeff7;
}
.seller .main_turn_inventory label i:after {
  content: " ";
  display: block;
  width: 22px;
  height: 23px;
  top: 2px;
  left: 5px;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.4);
  transition: 0.15s;
}
.seller .main_turn_inventory label > input:checked + i {
  background: var(--color-4);
}
.seller .main_turn_inventory label > input:checked + i + span {
  color: #29316b;
}
.seller .main_turn_inventory label > input:checked + i:after {
  transform: translateX(25px);
}
.seller .product-card {
  background: #fff;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin: 10px 0;
}
.seller .product-card-main {
  background: #fff;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 10px 0;
}
.seller .product_card_image img {
  width: 100%;
}
.seller .product_card_detail h6 {
  font-size: 14px;
  font-weight: normal;
  color: rgb(125, 127, 136);
}
.seller .main_list_product {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.seller .main_list_product {
  padding: 10px;
}
.seller .main_hide_section a {
  padding-right: 15px;
}
.seller .main_hide_section {
  padding-bottom: 10px;
}
.seller .main_hide_section a {
  color: #000;
}
.seller .red_icon_main {
  color: rgb(255, 0, 0);
}
.seller .main_button_low {
  text-align: center;
}
.seller .main_button_low a {
  background: #ffe2d8;
  color: rgba(255, 0, 0, 0.6);
  border: 0px;
  font-size: 15px;
  padding: 5px 10px;
  border-radius: 0;
}
.seller .hide_butt a {
  color: #000;
}
.seller .hide_butt a i {
  color: var(--secondary-color);
}
.seller .product-card:hover {
  background: #fff6eb;
}
.seller .restock_butt_now {
  text-align: right;
}
.seller .text_main_left {
  text-align: left;
}
.seller .low_info_inven span {
  height: 7px;
  width: 7px;
  background: rgb(255, 0, 0);
  border-radius: 100%;
  display: inline-block;
}
.seller .main_tracker h4 {
  font-size: 16px;
}
.seller .main_tracker p {
  font-size: 16px;
  margin-bottom: 10px;
}
.seller .border-right {
  border-right: 1px solid #ccc;
}
.seller .rangeslider-wrap {
  padding: 23px 23px 0;
}
.seller .rangeslider {
  position: relative;
  height: 4px;
  border-radius: 5px;
  width: 100%;
  background-color: rgb(224, 224, 224);
}
.seller .rangeslider__handle {
  transition: background-color 0.2s;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: rgb(255, 0, 0);
  touch-action: pan-y;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  z-index: 3;
  top: -8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5), inset 0 0 0 2px white;
}
.seller .rangeslider__handle__value {
  transition: background-color 0.2s, box-shadow 0.1s, transform 0.1s;
  box-sizing: border-box;
  width: 90px;
  text-align: center;
  padding: 10px;
  background-color: transparent;
  border-radius: 5px;
  color: rgb(255, 0, 0);
  left: -35px;
  top: -38px;
  position: absolute;
  white-space: nowrap;
}
.seller .rangeslider__handle__value:after {
  content: "Left";
}
.seller .rangeslider__fill {
  position: absolute;
  top: 0;
  z-index: 1;
  height: 100%;
  background-color: rgb(255, 0, 0);
  border-radius: 5px;
}
.seller .rangeslider__labels {
  position: absolute;
  width: 100%;
  z-index: 2;
  display: flex;
  justify-content: space-between;
}
.seller .rangeslider__labels__label {
  font-size: 0.75em;
  position: relative;
  padding-top: 15px;
  color: gray;
}
.seller .rangeslider__labels__label:before {
  position: absolute;
  top: -7px;
  left: 50%;
  transform: translateX(-50%);
  content: "";
  width: 1px;
  height: 9px;
  border-radius: 1px;
  background-color: rgba(128, 128, 128, 0.5);
}
.seller .rangeslider__labels__label:first-child:before,
.seller .rangeslider__labels__label:last-child:before {
  height: 18px;
  width: 18px;
  border-radius: 100%;
}
.seller .rangeslider__labels__label:first-child:before {
  background-color: rgb(40, 116, 240);
}
.seller .rangeslider__labels__label:last-child:before {
  background-color: rgb(40, 116, 240);
}
.seller .rangeslider__labels__label:first-child {
  transform: translateX(-48%);
}
.seller .rangeslider__labels__label:last-child {
  transform: translateX(48%);
}
.seller .rangeslider.rangeslider--active .rangeslider__handle,
.seller .rangeslider.rangeslider--active .rangeslider__handle * {
  background-color: transparent;
}
.seller .rangeslider.rangeslider--active .rangeslider__handle *:before {
  border-top-color: #33adff;
}
.seller .rangeslider.rangeslider--active .rangeslider__handle__value {
  transform: translateY(-5px);
  box-shadow: 0 -3px 2px transparent;
}
.seller .main_tracker_edit {
  text-align: right;
}
.seller .main_tracker_edit a {
  font-size: 14px;
  color: var(--secondary-color);
}
.seller .mobile_email_icon {
  color: #000;
  font-size: 14px;
  border-bottom: 1px solid rgb(224, 224, 224);
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.seller .main_Verified {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.seller .main_Verified span {
  font-size: 14px;
}
.seller .main_Verified span img {
  margin-right: 10px;
}
.seller .text_detail_new p {
  font-size: 14px;
}
.seller .text_detail_new h6 {
  font-size: 14px;
}
.seller .text_detail_new h5 {
  font-size: 14px;
}
.seller .text_detail_new h5 span img {
  padding-right: 10px;
}
.seller .main_contact_info h4 {
  font-size: 16px;
}
.seller .main_contact_info p {
  font-size: 14px;
}
.seller .main_contact_info a {
  font-size: 14px;
  display: block;
  width: 100%;
  margin-bottom: 10px;
  color: #000;
  padding-left: 28px;
  position: relative;
}
.seller .main_contact_info a span {
  position: absolute;
  left: 0;
}
.seller .oder_list_detail li a {
  border: 0 !important;
  background: transparent !important;
  color: #000;
}
.seller .active .main_icon_hover span {
  color: var(--main-color);
}
.seller .main_icon_active {
  color: var(--main-color) !important;
}
.seller .active_hover {
  color: var(--main-color) !important;
}
.seller .search-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 8px 5px;
  width: 100%;
  max-width: 275px;
  margin: 0 0 0 auto;
  border: 1px solid #ccc;
  position: relative;
}
.seller .search-container input[type=text] {
  border: none;
  outline: none;
  border-radius: 25px;
  width: 100%;
  font-size: 16px;
  margin-right: 10px;
  padding-left: 30px;
}
.seller .search-container span {
  position: absolute;
  left: 10px;
}
.seller .hide_detail_new a {
  margin-right: 10px;
}
.seller .hide_detail_new span {
  margin-right: 10px;
}
.seller .hide_detail_new a {
  font-weight: 500;
  font-size: 14px;
  line-height: 2.43;
  background-color: var(--secondary-color);
  color: #fff;
  border-bottom: 1px solid var(--secondary-color);
  text-align: left;
  padding: 8px 10px;
  border-radius: 10px;
}
.seller .Orders-main {
  color: #333;
  font-size: 14px;
  font-weight: bold;
}
.seller .last_day_main {
  color: #ccc;
  font-size: 16px;
  font-weight: bold;
}
.seller .search-container span i {
  font-size: 18px;
  color: rgb(224, 224, 224);
}
.seller .main_color_red {
  color: rgb(255, 0, 0);
}
.seller .main_acsept_butt {
  text-align: center;
  margin-top: 10px;
}
.seller .main_acsept_butt_2 {
  color: #fff;
  background: var(--color-4);
  border: 0;
}
.seller .main_acsept_butt_1 {
  color: #fff;
  background: rgb(255, 0, 0);
  border: 0;
}
.seller .main_acsept_butt_2:hover {
  background: var(--main-color);
  color: #fff;
}
.seller .main_acsept_butt_1:hover {
  background: var(--main-color);
  color: #fff;
}
.seller .progress-container-1 {
  margin-top: 20px;
}
.seller .progress-bar-1 {
  position: relative;
  width: 100%;
  height: 3px;
  background-color: #0d6efd;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.seller .progress {
  position: absolute;
  width: 100%;
  height: 10px;
  background-color: #007bff;
  border-radius: 5px;
  z-index: -1;
}
.seller .circle {
  position: relative;
  width: 20px;
  height: 20px;
  background-color: #007bff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #007bff;
  font-size: 12px;
}
.seller .circle::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  background-color: white;
  border-radius: 50%;
}
.seller .color_form_to {
  color: var(--secondary-color) !important;
}
.seller .start_new,
.seller .end_new {
  font-size: 14px;
  color: #000;
  font-weight: normal;
  transform: translateY(10px);
}
.seller .main_flex_detail {
  display: flex;
  justify-content: space-between;
}
.seller .main_img_ship {
  display: flex;
}
.seller .images_icon_ship img {
  width: 30px;
  margin-right: 10px;
}
.seller .main_color_gree {
  color: var(--secondary-color);
}
.seller .main_border_padd {
  padding-bottom: 10vw;
  border-bottom: 2px solid #ccc;
}
.seller .main_img_order {
  margin: 10px 0;
  text-align: center;
}
.seller .padd_none p {
  padding-bottom: 0 !important;
}
.seller .main_img_order img {
  width: 24px;
}
.seller .request_send_text {
  font-size: 15px;
  color: #000;
  text-align: left;
  padding-bottom: 0;
}
.seller .dasbord-main_new {
  width: 100%;
  min-width: 600px;
  margin: 40px auto;
  z-index: 999;
}
.seller .main_detail_unshiped {
  text-align: left;
}
.seller .status {
  margin-right: 10px;
}
.seller .unshipped {
  color: var(--main-color);
}
.seller .return_order {
  position: relative;
  background-color: var(--color-4);
  border-radius: 5px;
  border: 1px solid var(--color-4);
  justify-content: center;
  align-items: center;
  color: #fff;
  display: block;
  padding: 7px 10px;
  font-size: 14px;
  margin-bottom: 10px;
}
.seller .return_order:hover {
  background-color: var(--main-color);
  border: 1px solid var(--main-color);
  color: #fff;
}
.seller .view_details {
  position: relative;
  background-color: rgb(227, 227, 227);
  border-radius: 5px;
  border: 1px solid rgb(227, 227, 227);
  justify-content: center;
  align-items: center;
  color: #000;
  padding: 7px 10px;
  display: block;
  font-size: 14px;
}
.seller .view_details:hover {
  background-color: var(--main-color);
  border: 1px solid var(--main-color);
  color: #fff;
}
.seller .table_mar_retun .posts-table {
  width: 100%;
}
.seller .table_mar_retun .posts-table th {
  padding: 10px;
  border: 1px solid #e5e5e5;
}
.seller .table_mar_retun .posts-table td {
  padding: 10px;
  border: 1px solid #e5e5e5;
}
.seller .pad_detail_text p {
  color: rgb(123, 123, 123);
  font-size: 14px;
}
.seller .pad_detail_text p span {
  color: #000;
  font-size: 14px;
}
.seller .main_left_space a {
  margin-left: 10px;
}
.seller .main_turn_detail label {
  font-family: "Montserrat", sans-serif;
  font-size: 1.2rem;
  cursor: pointer;
  display: block;
  margin: 6px;
}
.seller .main_turn_detail label > input {
  display: none;
}
.seller .main_turn_detail label span {
  color: #000;
}
.seller .main_turn_detail {
  text-align: right;
}
.seller .main_turn_detail label i {
  display: inline-block;
  width: 45px;
  height: 22px;
  border-radius: 20px;
  vertical-align: middle;
  transition: 0.25s 0.09s;
  position: relative;
  background: #deeff7;
}
.seller .main_turn_detail label i:after {
  content: " ";
  display: block;
  width: 16px;
  height: 16px;
  top: 3px;
  left: 2px;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.4);
  transition: 0.15s;
}
.seller .main_turn_detail label > input:checked + i {
  background: var(--color-4);
}
.seller .main_turn_detail label > input:checked + i + span {
  color: #29316b;
}
.seller .main_turn_detail label > input:checked + i:after {
  transform: translateX(25px);
}
.seller .maindetail_input input {
  max-width: 88px;
  background: #ededed;
}
.seller .stat-cards-item {
  display: block;
  position: relative;
  border-radius: 4px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
}
.seller .stat-cards-item:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -16px;
  right: -16px;
  height: 32px;
  width: 32px;
  border-radius: 32px;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 0.7s ease-out;
  background: #66b9dd;
  box-shadow: 0px 10px 18px 0px #ffdff8;
}
.seller .stat-cards-item:hover:before {
  transform: scale(21);
}
.seller .daai_icon_img p {
  font-size: 20px;
  font-weight: 600;
}
.seller .main_detail_form {
  display: flex;
  align-items: flex-end;
}
.seller .main_detail_form .form-group {
  width: 230px;
  margin-right: 10px;
}
.seller .btn-warning {
  background: var(--main-color);
}
.seller .btn-warning-main-button {
  background: var(--color-4);
  border: none;
  font-size: 20px;
  border-radius: 8px;
  color: #fff;
  height: 50px;
  padding: 10px 20px;
}
.seller .btn-warning-main-button:hover {
  background: var(--main-color);
  color: #fff;
}
.seller .classmain_log h6 {
  color: var(--color-4);
  padding-bottom: 0;
}
.seller .classmain_log_button {
  background: var(--color-4);
  color: #fff;
}
.seller .classmain_log_button:hover {
  background: var(--main-color);
  color: #fff;
}
.seller .classmain_log_modul {
  max-width: 300px;
}
.seller .classmain_log p {
  color: #000;
}
.seller .log_in_main_button a {
  background: var(--color-4);
  border: none;
  font-size: 20px;
  border-radius: 8px;
  width: 100%;
  color: #fff;
  height: 50px;
  display: block;
  text-align: center;
  line-height: 2.5;
}
.seller .log_in_main_button a:hover {
  background: var(--secondary-color);
  border: none;
  color: #fff;
}
.seller .detail_address_button a {
  background: var(--color-4);
  border: none;
  font-size: 20px;
  border-radius: 8px;
  color: #fff;
  height: 50px;
  padding: 0 20px;
  max-width: 300px;
  width: 100%;
  display: block;
  text-align: center;
  line-height: 2.5;
  margin: 0 auto;
}
.seller .detail_address_button a:hover {
  background: var(--secondary-color);
  border: none;
  color: #fff;
}
.seller .code_sub_main a {
  background: var(--color-4);
  border: none;
  font-size: 20px;
  border-radius: 8px;
  color: #fff;
  height: 50px;
  padding: 0 20px;
  display: block;
  max-width: 200px;
  margin: 0 auto;
  line-height: 2.5;
}
.seller .code_sub_main a:hover {
  background: var(--secondary-color);
  border: none;
  color: #fff;
}
.seller .main_section_main {
  margin: 0 auto;
}
.seller .main_detail_Plan_img img {
  margin: 0 auto;
  width: 100%;
  max-width: 500px;
  display: block;
}
.seller .main_detail_butt_5 {
  background-color: #fff6eb;
  color: #ff8901;
  padding: 10px 18px;
}
.seller .main_detail_butt_5 span {
  background: #ff8901;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  display: inline-flex;
}
.seller .maon_detail_sec_new {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0;
  flex-wrap: wrap;
  margin-bottom: 15px;
}
.seller .padd_7 {
  padding-bottom: 7px;
}
.seller .xs-dis-blog {
  display: none !important;
}
.seller .btn-yellow {
  background: var(--color-4);
  color: #fff;
}
.seller .btn-yellow:hover {
  background: var(--secondary-color);
  color: #fff;
}
.seller .text_login_p a:hover {
  text-decoration: underline;
}
.seller .mt-100 {
  margin-top: 8vw;
}
.seller .pt-100 {
  padding-top: 8vw;
}
.seller .pb-100 {
  padding-bottom: 8vw;
}
.seller .padd_das_main {
  padding: 10px 0 15px;
}
.seller .text_detail_best {
  color: var(--color-3);
  font-size: 14px;
  font-weight: bold;
}
.seller .main_flex_div {
  display: flex;
  align-items: center;
}
.seller .all_Info_height img {
  width: 100%;
  height: 100px !important;
}
.seller .img_detail_new {
  justify-content: center;
}
.seller a.main_active_detail {
  display: flex;
  align-items: center;
}
.seller a.main_active_detail span {
  height: 16px;
  width: 15px;
  display: block;
  background: rgb(255, 0, 0);
  border-radius: 100%;
  margin-right: 5px;
  border: 4px solid #ffe2d8;
}
.seller a.main_active_detail.active span {
  height: 16px;
  width: 15px;
  display: block;
  background: rgb(22, 192, 152);
  border-radius: 100%;
  margin-right: 5px;
  border: 4px solid #b3fff0;
}
.seller .product_card_detail p {
  color: #000;
}
.seller .Start-color-sellers {
  color: #000;
  font-weight: normal;
  font-size: 15px;
}
.seller .main_images_section {
  margin: 14px 0;
  display: flex;
  align-items: baseline;
}
.seller .main_images_section span {
  padding-right: 10px;
}
.seller .btn-primary {
  color: #fff;
  background: var(--color-4);
  border: 1px solid var(--color-4);
}
.seller .btn-primary:hover {
  color: #fff;
  background: var(--main-color);
  border: 1px solid var(--main-color);
}
.seller .okay_color_main {
  color: #fff;
  background: var(--color-4);
  border: 1px solid var(--color-4);
}
.seller .return_order-main-new {
  background-color: var(--color-4);
  border-radius: 5px;
  border: 1px solid var(--color-4);
  color: #fff;
  padding: 7px 10px;
  margin: 10px 0;
  font-size: 22px;
  margin-bottom: 10px;
  text-align: center;
  display: inline-block;
}
.seller .border-buttom {
  padding-bottom: 10px;
  border-bottom: 2px solid rgb(227, 227, 227);
}
.seller .main_detail_section p {
  font-size: 16px;
  color: #000;
  margin-bottom: 0;
  font-weight: 500;
}
.seller .main_det_header {
  font-size: 25px;
  font-weight: bold;
  color: var(--color-5);
}
.seller .posts-table-b {
  padding: 10px;
  background: var(--color-4);
  color: #fff;
}
.seller .posts-table-w {
  padding: 10px;
  background: var(--main-color);
  color: #fff;
}
.seller .posts-table-w h4 {
  font-weight: bold;
  color: #000;
  font-size: 18px;
}
.seller .main_access_head p {
  padding: 10px;
  font-weight: 500;
  color: #fff;
}
.seller .posts-detail-text p {
  padding: 0;
  padding-left: 2vw;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  text-align: left;
}
.seller .mt-main-10 {
  margin-top: 10vw;
}
.seller .mxs-top-main {
  background: #ffc487;
  padding: 7px 30px;
  border-radius: 6px;
}
.seller .fix_header_main {
  position: sticky;
  top: 0;
  z-index: 999;
}
.seller .privacy_policy_section {
  position: relative;
  padding: 45px 0px;
  background: url(../../../public/assets/images/seller_images/privacy_bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
}
.seller .page_titile h2 {
  font-size: 50px;
  font-weight: 700;
  line-height: 43.85px;
  color: #fff;
  text-align: center;
}
.seller .page_titile h2 {
  font-size: 50px;
  font-weight: 700;
  line-height: 43.85px;
  color: #fff;
  text-align: center;
}
.seller .page_titile p {
  font-size: 24px;
  font-weight: 400;
  line-height: 45.18px;
  text-align: center;
  color: #fff;
}
.seller .page_titile_img img {
  width: 70%;
}
.seller .policy_content {
  position: relative;
  padding: 45px 0px;
  background: url(../../../public/assets/images/seller_images/privacy_bg.png);
  background-repeat: no-repeat;
  background-position: center center;
}
.seller .policy_content_inner h2 {
  font-size: 30px;
  font-weight: 800;
  line-height: 37.65px;
  color: var(--var-dark-color);
  margin-bottom: 20px;
}
.seller .policy_content_inner ul {
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0px;
}
.seller .policy_content_inner ul li {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
  background: #D9D9D9;
  font-size: 20px;
  font-weight: 400;
  line-height: 22px;
  color: var(--var-dark-color);
  margin-bottom: 25px;
  border-radius: 6px;
  position: relative;
  padding: 8px 0px 8px 35px;
}
.seller .policy_content_inner ul li a {
  display: block;
  padding: 10px 12px;
  color: #000;
  text-decoration: none;
}
.seller .policy_content_inner ul li a img {
  position: absolute;
  left: 10px;
  top: 17px;
}
.seller .policy_content_inner ul li:hover {
  background: #183822;
}
.seller .policy_content_inner ul li:hover a {
  color: #fff;
}
.seller .policy_content_inner ul li .policy_img2 {
  display: none;
}
.seller .policy_content_inner ul li:hover .policy_img1 {
  display: none;
}
.seller .policy_content_inner ul li:hover .policy_img2 {
  display: block;
}
.seller .policy_content_inner p {
  font-size: 18px;
  font-weight: 400;
  line-height: 29px;
  color: var(--var-dark-color);
}
.seller .breadcrumb_section {
  position: relative;
  background-color: #EDEDED;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
  padding: 10px 0px;
}
.seller .breadcrumb_section_content ul {
  list-style-type: none;
  padding-left: 0px;
  margin-bottom: 0px;
}
.seller .breadcrumb_section_content ul li {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
}
.seller .breadcrumb_section_content ul li:nth-child(1) a {
  text-decoration: none;
  color: #5F6C72 !important;
}
.seller .breadcrumb_section_content ul li:nth-last-child(1) a {
  text-decoration: none;
  color: #183823;
}
.seller .profile_section {
  position: relative;
  padding: 45px 0px;
}
.seller .profile_heading h2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  color: #000;
}
.seller .faq_section {
  padding-right: 50px;
  margin-bottom: 25px;
}
.seller .faq_section .accordion-button {
  font-size: 16px;
  font-weight: 500;
  line-height: 29px;
}
.seller .faq_section .accordion-item {
  border-left: 0;
  border-right: 0;
  margin-bottom: 15px;
  border-bottom: 0px;
}
.seller .faq_section #headingOne .accordion-button.collapsed {
  border-top: 0px;
}
.seller .faq_section .accordion-header .accordion-button.collapsed {
  border: 1px #ddd solid;
  border-radius: 4px;
}
.seller .faq_section .accordion-collapse.collapse.show {
  box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.1215686275);
}
.seller .faq_section .accordion-button:not(.collapsed) {
  background: #183823;
  color: #fff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.seller .faq_section .accordion-button:not(.collapsed)::after {
  background-image: url(../../../public/assets/images/seller_images/faq-1.png);
  transform: var(--bs-accordion-btn-icon-transform);
}
.seller .faq_section .accordion-button::after {
  background-image: url(../../../public/assets/images/seller_images/faq-2.png);
}
.seller .faq_section .accordion-button:focus {
  z-index: 3;
  border-color: transparent;
  outline: 0;
  box-shadow: none;
}
.seller .faq_section .accordion-body p {
  font-size: 15px;
  font-weight: 500;
  line-height: 27px;
  color: #475156;
}
.seller .faq_section .accordion-body ul {
  padding-left: 15px;
}
.seller .faq_section .accordion-body ul li {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--var-primary-text-color);
  margin-bottom: 12px;
}
.seller .faq_right {
  border: 1px solid rgba(24, 56, 35, 0.5019607843);
  background: rgba(24, 56, 35, 0.0784313725);
  border-radius: 4px;
  padding: 20px;
}
.seller .faq_right h2 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}
.seller .faq_right p {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #475156;
}
.seller .faq_right input {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #929FA5;
  border: 1px solid #183823;
  border-radius: 2px;
  height: 44px;
  width: 100%;
  margin-bottom: 25px;
  padding-left: 10px;
}
.seller .faq_right textarea {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #929FA5;
  border: 1px solid #183823;
  border-radius: 2px;
  height: 84px;
  width: 100%;
  margin-bottom: 25px;
  padding-left: 10px;
}
.seller .faq_right button {
  background: #183823;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.1215686275);
  padding: 15px 48px 15px 48px;
  border-radius: 5px;
  color: #fff;
  border: 0px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20.08px;
  text-transform: uppercase;
}
.seller .return_order_button {
  background-color: var(--color-4);
  border-radius: 5px;
  border: 1px solid var(--color-4);
  color: #fff;
  padding: 7px 10px;
  font-size: 14px;
  margin-bottom: 10px;
}
.seller .table-row-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.seller .table-row-wrapper .return_order_button {
  margin-left: 10px;
}
.seller .table-row-wrapper .return_order_button:first-child {
  margin-left: 0;
  /* Remove margin from the first button */
}
.seller .ck-label.ck-voice-label {
  display: none;
}

@media screen and (min-width: 1200px) {
  .seller .height_fix_main {
    max-height: 390px;
    height: 100%;
  }
}
@media screen and (max-width: 1200px) {
  .seller .main_detail_butt {
    white-space: nowrap;
  }
}
@media screen and (min-width: 992px) {
  .seller .col-text-main span {
    display: block;
  }
}
@media screen and (max-width: 991px) {
  .seller .mxs-top-main {
    padding-top: 10px;
  }
  .seller .main_a_detail a {
    font-size: 14px;
    padding: 6px 5px;
  }
  .seller .main-nav-Order h2 {
    font-size: 25px;
  }
  .seller .main-nav-Order h2 a {
    font-size: 26px;
  }
  .seller #signUpForm .form-header .stepIndicator::before {
    bottom: 50px;
  }
  .seller #signUpForm .form-header .stepIndicator::after {
    bottom: 58px;
  }
  .seller .main_detail_butt {
    white-space: nowrap;
  }
  .seller .main_detail_butt_2 {
    white-space: nowrap;
  }
  .seller .main_login_img {
    display: none;
  }
  .seller .main_footer_icon {
    margin-top: 0;
    text-align: center;
  }
  .seller .aboutus_content_banner {
    padding: 0;
  }
  .seller .aboutus_content_banner h2 {
    font-size: 30px;
    padding-bottom: 8px;
  }
  .seller .banner_images img {
    padding: 0;
  }
  .seller .book_area_why h4 {
    text-align: unset;
  }
  .seller .copy_right_main {
    text-align: center;
  }
  .seller .mar_xs_5 {
    margin-bottom: 15px;
  }
  .seller .feedbacks_detail_section {
    margin: unset;
  }
  .seller .bor_right_main {
    border-right: 0 solid #ccc;
  }
}
@media screen and (min-width: 767px) {
  .seller .page-wrapper.toggled .page-content {
    padding-left: 270px;
  }
}
@media screen and (max-width: 767px) {
  .seller .main_detail_order.main_detail_section.text-left {
    text-align: center;
  }
  .seller .main_detail_order.main_detail_section.text-right {
    text-align: center;
  }
  .seller .input_box_section label {
    width: 100%;
  }
  .seller .body-highlighted {
    overflow-y: hidden !important;
  }
  .seller .product-card-main {
    padding: 15px 15px 40px;
  }
  .seller #show-sidebar {
    z-index: 999;
  }
  .seller .xs-dis-blog {
    display: block !important;
  }
  .seller .product_card_image img {
    width: 100%;
    max-width: 250px;
    margin: 0 auto;
    display: block;
  }
  .seller .main_detail_butt_1 {
    white-space: nowrap;
  }
  .seller .page-wrapper.toggled #show-sidebar {
    left: 0;
  }
  .seller .new_xs {
    display: none;
  }
  .seller .header_detail_nav ul {
    justify-content: center;
  }
  .seller .col_xs_logo {
    display: flex;
    justify-content: space-between;
  }
  .seller .xs_logo_show_detail {
    display: block;
  }
  .seller .xs_logo_show_detail img {
    display: none;
  }
  .seller .bor_left_sec {
    border-left: 0px solid #ccc;
  }
  .seller .header_padd_20 {
    padding: 0;
  }
  .seller .padding-b-t-50 {
    padding: 26px 0;
  }
  .seller .input-radio.off:checked {
    width: 23px;
    height: 23px;
  }
  .seller .flex_detail_blog {
    flex-wrap: wrap;
  }
  .seller .address_here_detail label {
    margin-bottom: 10px;
  }
  .seller .address_here_detail label span {
    width: 80%;
  }
  .seller .address_here_detail .input-radio {
    width: 23px;
  }
  .seller .main_row_xs {
    flex-direction: column-reverse;
  }
  .seller .aboutus_content_banner {
    text-align: center;
  }
  .seller .all_order_main {
    border-bottom: 0 !important;
    flex-flow: nowrap;
    overflow: hidden;
    overflow-x: scroll;
    white-space: nowrap;
  }
  .seller .main_detail_order {
    white-space: nowrap;
  }
  .seller .main-nav-Order h2 {
    font-size: 24px;
  }
  .seller .add_button_product {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 576px) {
  .seller .main_a_detail a {
    margin-bottom: 10px;
  }
  .seller .mxs-top-main {
    padding-top: 20px;
  }
  .seller .border-right {
    border-right: 0 solid #ccc;
  }
  .seller .name_profile_main ul {
    white-space: nowrap;
    overflow-y: scroll;
  }
  .seller #signUpForm .form-header .stepIndicator {
    font-size: 12px;
  }
  .seller .main_footer_logo {
    text-align: center;
  }
  .seller .slider-main_team .owl-nav {
    position: absolute;
    top: -40px;
    right: 15px;
  }
  .seller .main_v_slider .slider-counter {
    text-align: right;
    color: #fff;
    font-size: 17px;
    padding-right: 45px;
  }
  .seller .header_detail_nav ul li {
    padding-left: 8px;
  }
}
@media screen and (max-width: 480px) {
  .seller .but_start_header {
    padding: 7px 4px;
    font-size: 12px;
  }
  .seller .logo_main img {
    width: 160px;
  }
  .seller .detail_form_log h3 {
    font-size: 28px;
  }
  .seller .Plan_form_sub h3 {
    font-size: 28px;
  }
  .seller .main-nav-start {
    padding: 0 0 0 10px;
  }
  .seller .main_detail_form {
    flex-direction: column;
  }
  .seller .main_detail_form .form-group {
    width: 100%;
    margin-bottom: 10px;
  }
}/*# sourceMappingURL=Seller.css.map */