.buyer-product-details {

    body {
        font-family: "Mulish", sans-serif;
    }

    :root {
        --var-background-color: #141920;
        --var-light-background-color: #252F3D;
        --var-gray-color: #F3F3F3;
        --var-white-color: #fff;
        --var-light-blue-color: #08AAE3;
        --var-primary-color: #18191A;
        --var-primary-text-color: #475156;
        --var-heading-color: #272727;
        --var-green-color: #0A8200;
    }

    h1 {
        font-size: 36px;
    }

    .btn_primary {
        text-decoration: none;
        background: var(--var-light-blue-color);
        color: var(--var-white-color);
        text-transform: uppercase;
        padding: 10px 16px;
    }

    .btn_primary:hover {
        background: var(--var-light-background-color);
        color: var(--var-white-color);
    }

    input,
    select {
        outline: transparent;
    }

    .breadcrumb_section {
        position: relative;
        background-color: #EDEDED;
        box-shadow: 0px 4px 4px 0px #00000040;
        padding: 10px 0px;
    }

    .breadcrumb_section_content h4 {
        font-size: 14px;
        color: var(--var-primary-color);

    }

    .breadcrumb_section_content ul {
        list-style-type: none;
        padding-left: 0px;
        margin-bottom: 0px;
    }

    .breadcrumb_section_content ul li {
        display: inline-block;
        font-size: 14px;
        font-weight: 400;
    }

    .breadcrumb_section_content ul li a {
        text-decoration: none;
        color: #5F6C72;
    }

    .breadcrumb_section_content ul li:nth-child(1) a {
        text-decoration: none;
        color: #5F6C72 !important;
    }

    .breadcrumb_section_content ul li:nth-last-child(1) a {
        text-decoration: none;
        color: var(--var-light-blue-color);
    }

    .product_details {
        position: relative;
        padding: 45px 0px;
    }

    .product_details_left.product-left {
        position: relative;
        overflow: hidden;
    }

    .swiper-container.product-slider img {
        width: 100%;
    }

    .swiper-container.product-thumbs {
        position: relative;
    }

    .swiper-container.product-thumbs img {
        border: 1px #ddd solid;
    }

    .swiper-container .swiper-button-next:after,
    .swiper-button-prev:after {
        display: none;
    }

    .product-slider .swiper-slide {
        border: 1px #ddd solid;
        border-radius: 4px;
    }

    .product-slider .swiper-slide img {
        border-radius: 4px;
    }

    .button_section .swiper-button-next i,
    .button_section .swiper-button-prev i {
        background-color: var(--var-light-blue-color);
        width: 40px;
        height: 40px;
        line-height: 40px;
        color: var(--var-white-color);
        text-align: center;
        border-radius: 50%;
    }

    .button_section .swiper-button-prev i {
        margin-left: -20px;
    }

    .button_section .swiper-button-next i,
    .button_section .swiper-button-prev i {
        width: 35px;
        height: 35px;
        line-height: 35px;
    }

    .button_section .swiper-button-next i,
    .button_section .swiper-button-prev i {
        margin-right: 5px;
    }

    .button_section {
        display: flex;
        justify-content: space-between;
        direction: rtl;
        margin-top: -9%;
        position: absolute;
        width: 100%;
        margin-bottom: 15px;
        left: 0;
    }

    .swiper-button-next:after,
    .swiper-container-rtl .swiper-button-prev:after {
        content: 'next';
        display: none;
    }

    .product-thumbs .swiper-slide {
        padding: 0px 10px 0px 10px;
    }

    .slider_product_details {
        position: relative;
    }

    .slider_product_details .swiper-button-next,
    .slider_product_details .swiper-button-prev {
        position: relative;
        justify-content: space-between;
    }

    .swiper-button-next,
    .swiper-button-prev {
        width: auto !important;
    }

    .product-thumbs .swiper-slide-active img {
        border: 1px var(--var-light-blue-color) solid !important;
    }

    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
        left: 32px !important;
    }

    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
        right: 20px !important;
    }

    .thuimb_iiner_img {
        margin: 4px;
    }

    /* Slider wrapper */
    .slider {
        width: 100%;
        overflow: hidden;
        /* Ensure items are inline-flex for horizontal display */
    }

    /* Slick Slider Item */
    .slider__item {
        display: inline-flex;
        margin-right: 20px !important;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        padding: 0px 0px 0px 30px;
    }
    .product_main_slider img{
        width: 640px !important;
        height: 480px !important;
        object-fit: cover;
    }
    /* Ensure padding is consistent */


    /* Custom Slider */
    .custom-slider {
        width: 100%;
        margin: auto;
    }

    /* Slick Slider Navigation */
    .slick-prev,
    .slick-next {
        position: absolute;
        line-height: 0;
        top: 50%;
        width: 30px;
        height: 30px;
        padding: 0;
        transform: translate(0, -50%);
        cursor: pointer;
        color: transparent;
        border: none;
        outline: none;
        border-radius: 50px;
        background: #043e46;
        display: none !important;
        /* Remove this if you want to display arrows */
    }

    .slick-next {
        right: -30px;
    }

    .slick-prev {
        left: -30px;
    }

    .slick-next:before {
        content: '\003e';
        font-size: 1.2em;
        font-weight: 1000;
        padding-left: 12px;
        color: white;
    }

    .slick-prev:before {
        content: '\003c';
        font-size: 1.2em;
        font-weight: 1000;
        padding-left: 9px;
        color: white;
    }

    .slick-slider {
        margin: 0 -15px;
    }

    /* Ensure Slick Track and Slides are displayed correctly */
    .slick-track {
        position: relative;
        top: 0;
        left: 0;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .slick-slide {
        display: inline-block;
        float: none !important;
        /* Ensures no float styling affects it */
        height: auto !important;
        /* Ensures auto height */
        // border: 1px #ddd solid;
        // border-radius: 4px;
    }

    .product-details_left.product-left {
        position: relative;
        overflow: hidden;
    }

    /* Prevent user selection on the slider */
    .slick-slider {
        user-select: none;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        overflow: hidden;
        list-style: none;
        padding: 0;
        z-index: 1;

    }

    .slider__item:focus-visible {
        outline: none !important;
    }

    /* Optional: adjust padding/margins as needed */
    // .slick-slider .slick-list {
    //     padding: 0 10% 0 0 !important;
    // }

    .slick-slider .slick-list {
        position: relative;
        display: block;
        overflow: hidden;
        margin: 0;
        padding: 0;
    }

    .slider-sec {
        margin-top: 10px;
    }

    .slider__item {
        margin-right: 0px !important;
        padding: 0px 0px 0px 0px;
    }

    .slide.slider__item.mx-5 {
        margin: 0px !important;
        padding-left: 0px !important;
    }

    /* Optional: to always show navigation buttons */
    .slick-prev,
    .slick-next {
        display: block !important;
    }

    //   .slick-prev:before,
    .slick-next:before {
        color: #000;
        content: "\f054";
        font-family: FontAwesome;
    }

    .slick-prev:before {
        color: #000;
        content: "\f053";
        font-family: FontAwesome;
    }

    .slick-slide {
        scale: 0.8;
    }

    .slick-current {
        scale: 1;
    }

    .slick-track .slick-slide.slick-active.slick-current {
        border: 1px var(--var-light-blue-color) solid !important;
        border-radius: 4px;
        padding: 5px;
    }
    .slick-track .slick-slide.slick-active.slick-current img{
        width: 90px;
        height: 70px;
        object-fit: cover;
    }
    .slider-nav .slick-current .card {
        border: 0px;
    }
    .img-sec{
        text-align: center;
    }
    .img-sec img{
        width: 80px;
        height: 65px;
        object-fit: contain !important;
    }
    .slider-nav .card {
        border: 1px #ddd solid;
    }

    .product_details_content ul {
        list-style: none;
        padding-left: 0px;
        margin-bottom: 5px;
    }

    .product_details_content ul li {
        display: inline-block;
        color: #FA8232;
        font-size: 12px;
    }

    .product_details_content ul li:nth-last-child(1) {
        color: var(--var-primary-color);
        font-size: 14px;
        font-weight: 700;
    }

    .product_details_content ul li:nth-last-child(1) span {
        color: #5F6C72;
        font-size: 14px;
        font-weight: 400;
    }

    .product_details_content h3 {
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
        color: var(--var-primary-color);
    }

    .sku_section table {
        width: 100%;
    }

    .sku_section table td {
        color: #5F6C72;
        font-size: 14px;
        font-weight: 400;
    }

    .sku_section table td strong {
        color: #191C1F;
        font-weight: 700;
    }

    .green_stock {
        color: #2DB224 !important;
    }

    .red_stock {
        color: red !important;
    }

    .product_details_price {
        padding: 20px 0px 20px 0px;
        border-bottom: 1px #E4E7E9 solid;
    }

    .product_details_price h2 {
        font-size: 24px;
        font-weight: 700;
        color: var(--var-light-blue-color);
    }

    .product_details_price del {
        font-size: 18px;
        font-weight: 400;
        color: #77878F;
    }

    .product_details_price span {
        background: #EFD33D;
        font-size: 14px;
        font-weight: 600;
        color: var(--var-primary-color);
        padding: 5px 10px 5px 10px;
    }

    .product-details_color {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 10px;
        grid-row-gap: 10px;
    }

    .details_color_left p {
        font-size: 14px;
        font-weight: 400;
        color: var(--var-primary-color);
    }

    .details_color_left ul {
        list-style: none;
        padding-left: 0px;
        margin-bottom: 0px;
    }

    .details_color_left ul li {
        display: inline-block;
        padding: 0px 5px 0px 0px;
    }

    .details_color_left ul li span.active {
        width: 35px;
        height: 35px;
        background-color: #E0E1E1;
        border-radius: 50%;
        display: block;
        line-height: 35px;
        border: 4px #fff solid;
        padding: 6px;
        outline: 2px var(--var-light-blue-color) solid;
    }

    .details_color_left ul li span {
        width: 35px;
        height: 35px;
        background-color: #E0E1E1;
        border-radius: 50%;
        display: block;
        line-height: 35px;
        padding: 6px;
        outline: none;
    }

    .details_color_right p {
        font-size: 14px;
        font-weight: 400;
        color: var(--var-primary-color);
    }

    .details_color_right select {
        height: 44px;
        padding: 12px 16px 12px 16px;
        border-radius: 2px 0px 0px 0px;
        border: 1px #E4E7E9 solid;
        width: 100%;
    }

    .quantity_number {
        display: flex;
        border-radius: 0px;
        justify-content: space-between;
        border: 1px #ddd solid;
        min-height: 40px;
        border-radius: 4px;
    }

    .quantity_number .btn {
        cursor: pointer;
        padding: 4px 6px 4px 6px;
    }

    .quantity_number .quantity {
        border: none;
        text-align: center;
        font-size: 16px;
        color: #43484D;
        font-weight: 300;
        background: transparent;
        width: 25px;
    }

    .quantity_number .btn {
        cursor: pointer;
        padding: 4px 6px 4px 6px;
        font-size: 22px;
    }

    .quantity_section {
        display: grid;
        grid-template-columns: 27% 50% 20%;
        align-items: center;
        grid-column-gap: 10px;
        grid-row-gap: 10px;
        margin-top: 25px;
        justify-content: space-between;
    }

    .add_to_cart a {
        background-color: #183823;
        color: var(--var-white-color);
        text-align: center;
        text-decoration: none;
        width: 100%;
        display: block;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        padding: 12px 0px;
        min-height: 40px;
        border-radius: 4px;
        cursor: pointer;
    }

     .buy_now_btn a {
        color: var(--var-primary-text-color);
        text-align: center;
        text-decoration: none;
        width: 100%;
        display: block;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        padding: 12px 0px;
        min-height: 40px;
        border: 1px #ddd solid;
        cursor: pointer;
        border-radius: 4px;
    }
    .add_to_cart a:hover{
        color: #fff;
    }
    .payment_option p {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }

    .payment_option {
        border: 1px solid #E4E7E9;
        margin-top: 35px;
        padding: 20px;
    }



    .description_section {
        position: relative;
        padding: 45px 0px;
        border-top: 1px #ddd solid;
        border-bottom: 1px #ddd solid;
    }

    .description_content h2 {
        font-size: 32px;
        font-weight: 700;
        line-height: 24px;
    }

    .description_content p {
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
    }

    .vender_list h4 {
        color: var(--var-primary-color);
        font-size: 18px;
        font-weight: 800;
        margin-top: 15px;
    }

    .description_list ul {
        padding-left: 0px;
        list-style: none;
    }

    .description_list ul li {
        position: relative;
        padding-left: 15px;
        margin-bottom: 10px;
    }

    .description_list ul li::before {
        content: "";
        width: 6px;
        height: 6px;
        background: #7a7a7a;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 10px;
    }

    /* Related Products */

    .related_products {
        position: relative;
        padding: 45px 0px;
    }
    .related_products_inner {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        height: 100%;
        width: 100%;
        align-content: space-between;
        margin-bottom: 20px;
        align-content: center;
        margin-bottom: 20px;
        flex-direction: column;
    }
    .related_products_heading h2 {
        font-size: 32px;
        font-weight: 700;
        line-height: 24px;
        color: var(--var-primary-color);
    }
    .related_products_inner p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
    }
    .related_products_inner img{
        width: 272px;
        height: 208px;
        object-fit: contain;
        max-width: 100%;
    }
    .related_products .slick-track .slick-slide.slick-active.slick-current img{
        width: 272px;
        height: 208px;
        object-fit: contain;
        max-width: 100%;
        border: 0px;
    }
    .related_products .slider .slick-list {
        padding: 0 0px 0 15px !important;
        margin: 0px 30px 0px 0px !important;
    }
    .related_products .slick-track .slick-slide.slick-active.slick-current {
        border:0px !important;
        border-radius: 4px;
        padding: 5px;
    }
    .related_products .slick-current {
        scale:0.8;
    }
    .related_products_add_to_cart {
        display: grid;
        grid-template-columns: 80% 20%;
        justify-content: center;
        align-items: center;
        justify-items: stretch;
        grid-column-gap: 10px;
        width: 100%;
    }

   .product_details_wish_list {
        text-align: center;
        border: 1px #FFE7D6 solid;
        height: 43px;
        line-height: 46px;
        border-radius: 4px;
    }
    .size_chart p a img{
        padding-right: 5px;
        width: 34px;
    }
    .size_chart p a{
        color: #333;
        text-decoration: none;
    }
    .product_details_wish_list i {
        font-size: 24px;
    }

    /* Frequently Bought */
    .frequently_bought {
        position: relative;
        padding: 45px 0px;
        border-top: 1px #ddd solid;
    }

    .frequently_bought_main_section {
        // display: grid;
        // grid-template-columns: 22% 3% 22% 3% 22% 22%;
        // grid-column-gap: 10px;
        // grid-row-gap: 10px;
        align-items: center;
        justify-items: center;
    }

    .plus_icon i {
        color: var(--var-primary-color);
        font-size: 30px;
    }

    .frequently_bought_inner .add_product {
        width: 100%;
        display: inline-block;
        text-align: center;
        text-decoration: none;
        background: #183823;
        color: var(--var-white-color);
        font-size: 16px;
        font-weight: 700;
        line-height: 26.65px;
        padding: 8px;
        border-radius: 10px;
        margin: 0 auto;
    }

    .add_product_text {
        padding-left: 45px !important;
    }

    .frequently_bought_inner p {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        color: var(--var-primary-color);
        margin-bottom: 0px;
    }

    .frequently_bought_inner .see_more_btn {
        font-size: 16px;
        color: #183823;
        text-decoration: none;
    }

    /* Features */

    .features_section {
        position: relative;
        width: 100%;
        padding: 45px 0px;
        border-top: 1px #ddd solid;
        border-bottom: 1px #ddd solid;
    }

    .features_section_inner h2 {
        font-size: 32px;
        font-weight: 700;
        line-height: 24px;
        color: var(--var-primary-color);
    }

    .features_section_inner ul {
        list-style: none;
        padding-left: 0px;
        margin-top: 15px;
    }

    .features_section_inner ul li {
        position: relative;
        padding-left: 30px;
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 7px;
    }

    .features_section_inner ul li i {
        position: absolute;
        top: 4px;
        left: 0px;
        color: var(--var-light-blue-color);
    }

    .features_section_table h2 {
        font-size: 32px;
        font-weight: 700;
        line-height: 40.16px;
        color: #191C1F;
    }

    .features_section_table table {
        width: 100%;
        background-color: #E8E8E8;
    }

    .features_section_table table th {
        font-size: 25px;
        font-weight: 700;
        line-height: 24px;
        text-align: center;
        color: var(--var-primary-color);
        padding: 12px;
    }

    .features_section_table table tbody tr {
        border: 0.5px solid var(--var-primary-color);
    }

    .features_section_table table tbody tr td:nth-child(1) {
        background-color: var(--var-gray-color);
        background-color: #D9D9D9;
        width: 30%;
    }

    .features_section_table table td strong {
        color: var(--var-primary-color);
    }

    .features_section_table table td b {
        color: var(--var-primary-color);
        font-weight: 700;
        font-size: 24px;
    }

    .table_padding td {
        padding-bottom: 20px !important;
    }

    .features_section_table table td {
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        color: var(--var-primary-color);
        padding: 5px 5px 5px 15px;
    }

    /* Customer reviews */
    .customer_reviews {
        position: relative;
        padding: 45px 0px;
    }

    .customer_reviews_left h2 {
        font-size: 32px;
        font-weight: 700;
        line-height: 32px;
        color: var(--var-primary-color);
    }

    .customer_reviews_left ul {
        list-style: none;
        padding-left: 0px;
        margin-bottom: 5px;
    }

    .customer_reviews_left ul li {
        display: inline-block;
        color: #FA8232;
        font-size: 28px;
    }

    .customer_reviews_left ul li:nth-last-child(1) {
        color: var(--var-primary-color);
        font-size: 20px;
        font-weight: 700;
    }

    .progress_bar table {
        width: 100%;
    }

    .progress_bar table td {
        padding-bottom: 10px;
    }

    .progress_bar table td:nth-child(1) {
        width: 20%;
    }

    .progress_bar table td:nth-child(3) {
        width: 60%;
    }

    .progress_bar table td:nth-child(3) {
        width: 20%;
    }

    .reviews_btn a {
        box-shadow: 0px 4px 4px 0px #00000040;
        text-decoration: none;
        padding: 10px 12px;
        display: block;
        text-align: center;
        background: #F3F2F280;
        color: var(--var-primary-color);
        border-radius: 4px;
        width: 75%;
    }

    .customer_says h2 {
        font-size: 32px;
        font-weight: 700;
        line-height: 24px;
        color: var(--var-primary-color);
    }

    .customer_reviews_main {
        display: flex;
        justify-content: space-between;
        align-content: flex-start;
        margin-top: 20px;
    }

    .customer_reviews_user h3 {
        font-size: 22px;
        font-weight: 700;
        color: #133240;
        margin-top: 10px;
    }

    .customer_reviews_user p {
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        color: #133240;
        margin-bottom: 5px;
    }

    .customer_reviews_user_right ul {
        list-style: none;
        padding-left: 0px;
        margin-bottom: 5px;
    }

    .customer_reviews_user_right ul li {
        display: inline-block;
        color: #FA8232;
        font-size: 18px;
    }

    .view-more {
        box-shadow: 0px 4px 4px 0px #00000040;
        background: #F3F2F280;
        color: #000;
        text-decoration: none;
        padding: 10px 24px;
        display: inline-block;
        border-radius: 4px;
        margin-top: 8px;
    }

    .view-more i {
        color: #A7A7A7;
    }


    .shop-page-product {
        position: relative;
        background: var(--var-white-color);
        padding: 10px;
        border: 1px #E4E7E9 solid;
        border-radius: 3px;
        margin-bottom: 20px;
    }

    .shop-page-product a {
        text-decoration: none;
    }

    .shop-page-product img {
        width: 288px;
        height: 235px;
        object-fit: contain;
        max-width: 100%;
    }

    .hot_product {
        background: #EFD33D;
        position: absolute;
        top: 6px;
        left: 6px;
        font-size: 12px;
        font-weight: 600;
        color: var(--var-primary-color);
        padding: 5px 10px 5px 10px;
    }

    .shop-page-product ul {
        list-style: none;
        padding-left: 0px;
        margin-bottom: 5px;
    }

    .shop-page-product ul li {
        display: inline-block;
        color: #FA8232;
        font-size: 12px;
    }

    .shop-page-product ul li:nth-last-child(1) {
        color: #77878F;
    }

    .shop-page-product h4 {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: var(--var-primary-color);
    }

    .shop-page-product p {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #929FA5;
    }

    .shop-page-product p span {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #183823;
    }






}

@media(max-width:1320px) {
    .buyer-product-details {
        .details_color_right select {
            font-size: 8px;
        }
    }

}


@media (max-width:1120px) {
    .buyer-product-details {
        .frequently_bought_inner .add_product {
            font-size: 15px;
            padding: 7px;
        }

        .frequently_bought_main_section {
            grid-template-columns: 20% 5% 20% 5% 20% 21%;
        }
    }
}
@media (max-width:1024px) {
    .buyer-product-details {
         .related_products_inner img {
            object-fit: contain;
        }
         .shop-page-product img {
            object-fit: contain;
            width: 100%;
            height: 155px;
        }
    }
}
@media (max-width:992px) {
    .buyer-product-details {
        .buy_now_btn a {
            font-size: 12px;
        }

        .add_to_cart a {
            font-size: 14px;
        }

        .frequently_bought_inner .add_product {
            font-size: 10px;
            line-height: 20px;
        }

        .add_product_text {
            padding-left: 7px !important;
        }

        .quantity_section {
            grid-template-columns: 27% 48% 20%;
        }

        .button_section {
            margin-top: -10%;
        }

        .details_color_right select {
            font-size: 10px;
        }

        .shop-page-product h4 {
            font-size: 12px;
        }
        .img-sec img {
            width: 100%;
            height: 40px;
            object-fit: contain !important;
        }
        .slick-track .slick-slide.slick-active.slick-current img {
            width: 100%;
            height: 40px;
            object-fit: cover;
        }
    }
}

@media (max-width:767px) {
    .buyer-product-details {
        .reviews_btn {
            margin-bottom: 25px;
        }

        .footer-sec .container.py-4 {
            padding: 0px;
            margin: 0px;
        }

        .button_section {
            margin-top: -6%;
        }

        .details_color_right select {
            font-size: 14px;
        }

        .reviews_btn a {
            width: 100%;
        }
    }
}

@media (max-width:640px) {
    .buyer-product-details {
        .frequently_bought_main_section {
            grid-template-columns: 59% 1fr;
        }

        .add_product_text {
            padding-left: 0px !important;
        }

        .product-thumbs .swiper-slide {
            padding: 0px 0px 0px 0px;
        }

        .customer_reviews_left h2 {
            font-size: 22px;
        }

        .customer_says h2 {
            font-size: 24px;
        }

        .product_details_price span {
            font-size: 10px;
        }

        .product-details_color {
            grid-template-columns: 1fr;
        }

        .button_section {
            margin-top: -7%;
        }

        .description_content h2 {
            font-size: 24px;
        }

        .progress_bar table td {
            font-size: 12px;
        }
        .product_main_slider img {
            width:100% !important;
            height: 300px !important;
            object-fit: cover;
        }
    }
}

@media (max-width:576px) {
    .buyer-product-details {
        .quantity_section {
            grid-template-columns: 26% 47% 20%;
            justify-content: space-between;
        }

        .related_products_heading h2 {
            line-height: 30px;
            font-size: 22px;
        }

        .buy_now_btn a {
            font-size: 10px;
            line-height: 20px;
            height: 44px;
        }

        .frequently_bought_inner .add_product {
            font-size: 10px;
            padding: 0px;
        }

        .product_details_price h2 {
            font-size: 18px;
        }

        .button_section {
            margin-top: -30px;
        }

        .button_section .swiper-button-next i,
        .button_section .swiper-button-prev i {
            width: 26px;
            height: 26px;
            line-height: 26px;
        }

        .button_section {
            margin-top: -8%;
        }
        .plus_icon {
            text-align: center;
        }
        .plus_icon i {
            font-size: 15px;
        }
    }
}

@media (max-width:480px) {
    .buyer-product-details {        

        .button_section {
            margin-top: -10%;
        }
    }
}
@media (max-width:430px) {
    .buyer-product-details {        

        .button_section {
            margin-top: -12%;
        }
    }
}