.buyer-profile-change-name {

    /* Profile Section */
    .profile_section {
        position: relative;
        padding: 45px 0px;
    }

    .profile_heading h2 {
        font-size: 30px;
        font-weight: 700;
        line-height: 24px;
        color: #000;
    }

    .profile_man_box {
        position: relative;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
        padding: 20px 100px 20px 100px;
    }

    .profile_inner_box_section {
        padding: 30px 10px 30px 10px;
        background: #08AAE30A;
        border: 1px solid #08AAE3;
        border-radius: 12px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-items: stretch;
        justify-content: space-between;
        align-items: center;
    }

    .profile_inner_box h6 {
        position: relative;
        font-size: 24px;
        font-weight: 700;
        line-height: 32.16px;
        color: var(--var-dark-color);
        padding-left: 65px;
    }

    .profile_inner_box h6 span {
        font-size: 20px;
        font-weight: 700;
        line-height: 30.12px;
        color: var(--var-dark-color);
    }

    .profile_inner_box h6 i {
        position: absolute;
        left: 0;
        top: 14px;
        font-size: 38px;
        color: #A8ACB1;
    }

    .active_profile_icon {
        color: var(--var-light-blue-color) !important;
    }

    .profile_inner_box a {
        border-radius: 19px;
        font-size: 14px;
        text-transform: none;
        padding: 6px 50px;
        float: right;
        text-decoration: none;
        box-shadow: 0px 4px 4px 0px #00000040;
        color: #848484;
        background: #08AAE30A;
        border: 1px solid #08AAE380;
        transition: 0.4s;
    }

    .profile_inner_box a:hover {
        background-color: #08AAE3;
        color: var(--var-white-color);
        transition: 0.4s;
    }

    .profile_change_name p {
        font-size: 20px;
        font-weight: 300;
        line-height: 26px;
        color: var(--var-dark-color);
    }
    .profile_change_name input {
        border: 1px solid #848484;
        width: 100%;
        border-radius: 2px;
        padding: 8px;
        margin: 2px 0px 30px 0px;
        color: #77878F;
        font-size: 14px;
        border-radius: 8px;
    }

    .profile_change_name label {
        font-size: 18px;
        font-weight: 600;
        line-height: 20px;
        width: 100%;
        text-align: left;
    }
    

    .profile_change_name button {
        border: 1px solid rgba(8, 170, 227, 0.5019607843);
        padding: 12px 24px 12px 24px;
        border-radius: 5px;
        text-decoration: none;
        display: inline-block;
        margin-top: 5px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
        background: #183823;
        color: #fff;
    }

    .iti__flag {
        display: none;
    }

    .change-primary-number {
        margin: 15px 0px 20px 0px;
    }

    .change-primary-number input {
        width: 100%;
        border: 1px solid #848484;
        border-radius: 2px;
        padding: 8px;
        margin: 10px 0px 20px 70px !important;
        padding-left: 5px !important;
        border-radius: 8px;
    }

    .change-primary-number .iti--separate-dial-code .iti__selected-flag {
        background-color: rgb(255 255 255 / 5%);
        border: 1px solid #848484;
    }

    .field-icon {
        float: right;
        margin-left: -24px;
        margin-top: -58px;
        position: relative;
        z-index: 2;
        padding-right: 5px;
        cursor: pointer;
    }
    .edit_profile_main{
        padding: 30px;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    }
    .edit_profile_main h2{
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 15px;
    }
    .change_pass_rel{
        position: relative;
    }
    .change_pass_rel {
        width: 50%;
        position: relative;
        text-align: center;
        margin: 0 auto;
    }
    .edit_profile_main h3{
        font-size: 26px;
        font-weight: 700;        
    }
}
@media (max-width:992px) {
    .buyer-profile-change-name {
        .profile_change_name input {
            width: 100%;
        }
        .change_pass_rel {
            width: 100%;
            position: relative;
            text-align: center;
            margin: 0 auto;
        }
    }
}
@media (max-width:640px) {
    .buyer-profile-change-name {
        .profile_change_name input {
            width: 100%;
        }
    }
}