.buyer-return-order-list {
  /* Your Orders */
  /* Your Orders */
  /* Your Orders */
}
.buyer-return-order-list .order_section {
  position: relative;
  padding: 45px 0px;
}
.buyer-return-order-list .order_section_inner {
  position: relative;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 7px 0px;
  padding: 0px 0px 0px 0px;
}
.buyer-return-order-list .order_section_inner .inner_sec_heading {
  margin: 15px;
}
.buyer-return-order-list .order_section_inner .review_section_main {
  display: flex;
  justify-content: space-between;
}
.buyer-return-order-list .order_section_inner .review_section_main .cancel_order {
  border: 1px solid #183823;
  font-size: 14px;
  font-weight: 500;
  line-height: 22.59px;
  background: rgba(8, 170, 227, 0.0705882353);
  color: var(--var-heading-color);
  text-decoration: none;
  padding: 6px 12px;
  border-radius: 19px;
  transition-duration: 0.4s;
}
.buyer-return-order-list .order_section_inner .review_section_main .cancel_order:hover {
  background: var(--var-light-blue-color);
  color: var(--var-white-color);
  transition-duration: 0.4s;
}
.buyer-return-order-list .track_order_section_1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #E0E0E0;
  padding: 5px 10px 5px 10px;
}
.buyer-return-order-list .track_order_section_left a {
  font-size: 18px;
  font-weight: 400;
  line-height: 22.59px;
  color: var(--var-heading-color);
  text-decoration: none;
  border-radius: 14px;
  display: inline-block;
  text-align: right;
}
.buyer-return-order-list .track_order_section_left a span {
  color: #848484;
}
.buyer-return-order-list .track_order_section_left p {
  text-align: right;
  margin-bottom: 0px;
}
.buyer-return-order-list .track_order_section_left p span {
  font-size: 14px;
  font-weight: 600;
  color: #272727;
  margin: 0px 6px 0px 6px;
}
.buyer-return-order-list .track_order_section_right a {
  font-size: 16px;
  font-weight: 600;
  line-height: 25.1px;
  color: black;
  border-radius: 14px;
  text-decoration: none;
  display: inline-block;
  margin-right: 15px;
}
.buyer-return-order-list .track_order_section_right a .heading-sm {
  font-size: 14px;
  color: #475156;
}
.buyer-return-order-list .track_order_section_right a i {
  padding-right: 6px;
}
.buyer-return-order-list .track_order_details {
  display: grid;
  grid-template-columns: 12% 41% 15% 15% 15%;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: space-between;
  padding: 15px 10px 15px 10px;
  align-items: center;
}
.buyer-return-order-list .track_order_details .track_order_details_main .main_date {
  margin-top: 2%;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: #191C1F;
}
.buyer-return-order-list .track_order_details .track_order_details_main .main_buttons {
  margin-top: 2%;
}
.buyer-return-order-list .track_order_details .track_order_details_main .main_buttons .button-white {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  background: white;
  color: #000000;
  border-radius: 6px;
  padding: 6px 12px;
  text-decoration: none;
  display: inline-block;
  margin-right: 15px;
  border: 1px solid #BDBDBD;
  transition: 0.4s;
}
.buyer-return-order-list .track_order_details .track_order_details_main .main_buttons .button-white:hover {
  background-color: #183823;
  color: #fff;
  transition: 0.4s;
}
.buyer-return-order-list .track_order_details .track_order_details_main .main_buttons .button-color {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  background: var(--var-light-blue-color);
  color: var(--var-white-color);
  border-radius: 6px;
  padding: 6px 12px;
  text-decoration: none;
  display: inline-block;
  border: 1px #BDBDBD solid;
  margin: 2%;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
}
.buyer-return-order-list .track_order_details .track_order_details_main img {
  width: 154px;
}
.buyer-return-order-list .track_order_details .track_order_details_main p {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  color: var(--var-primary-color);
  margin-bottom: 0px;
}
.buyer-return-order-list .track_order_details .track_order_details_main h6 {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: #08AAE3;
}
.buyer-return-order-list .track_order_details_main h6 a {
  text-decoration: none;
  color: #08aae3;
}
.buyer-return-order-list .track_order_details_main .btn_primary {
  border-radius: 19px;
  font-size: 14px;
  text-transform: none;
  padding: 6px 12px;
}
.buyer-return-order-list .track_order_details_main .cancel_order {
  border: 1px solid #08AAE3;
  font-size: 14px;
  font-weight: 500;
  line-height: 22.59px;
  background: rgba(8, 170, 227, 0.0705882353);
  color: var(--var-heading-color);
  text-decoration: none;
  padding: 6px 12px;
  border-radius: 19px;
  transition-duration: 0.4s;
}
.buyer-return-order-list .track_order_details_main .cancel_order:hover {
  background: var(--var-light-blue-color);
  color: var(--var-white-color);
  transition-duration: 0.4s;
}

@media (max-width: 992px) {
  .buyer-return-order-list .track_order_details {
    grid-template-columns: 18% 34% 10% 10% 13%;
  }
  .buyer-return-order-list .track_order_details_main .cancel_order {
    font-size: 10px;
  }
  .buyer-return-order-list .track_order_details_main .btn_primary {
    font-size: 10px;
  }
  .buyer-return-order-list .track_order_details .track_order_details_main p {
    font-size: 12px;
    line-height: 18px;
  }
  .buyer-return-order-list .track_order_details .track_order_details_main .main_buttons .button-white {
    font-size: 10px;
    padding: 6px 10px;
    margin-right: 8px;
  }
  .buyer-return-order-list .track_order_section_left a {
    font-size: 15px;
  }
  .buyer-return-order-list .buyer-your-orders .track_order_details .track_order_details_main h6 {
    font-size: 10px;
    line-height: 15px;
  }
}
@media (max-width: 767px) {
  .buyer-return-order-list .track_order_details {
    grid-template-columns: 18% 34% 10% 10% 13%;
  }
  .buyer-return-order-list .track_order_details_main p {
    font-size: 10px;
    line-height: 14px;
  }
  .buyer-return-order-list .track_order_details_main h6 {
    font-size: 10px;
  }
  .buyer-return-order-list .track_order_details_main .cancel_order {
    font-size: 7px;
  }
  .buyer-return-order-list .track_order_details_main .btn_primary {
    font-size: 7px;
  }
  .buyer-return-order-list .track_order_section_right a {
    font-size: 12px;
    line-height: 19.1px;
    padding: 6px 12px 2px 0px;
    margin-right: 1px;
  }
  .buyer-return-order-list .track_order_section_left a {
    font-size: 12px;
    line-height: 8.59px;
  }
  .buyer-return-order-list .track_order_details .track_order_details_main h6 {
    font-size: 10px;
    line-height: 12px;
  }
  .buyer-return-order-list .track_order_details .track_order_details_main .main_date {
    font-size: 10px;
    line-height: 18px;
  }
  .buyer-return-order-list .order_section_inner .review_section_main .cancel_order {
    font-size: 9px;
    padding: 6px 10px;
  }
}
@media (max-width: 640px) {
  .buyer-return-order-list .track_order_details {
    grid-template-columns: 1fr 1fr;
  }
  .buyer-return-order-list .track_order_section_1 {
    display: block;
  }
  .buyer-return-order-list .track_order_section_left p {
    text-align: left;
    margin-bottom: 10px;
  }
  .buyer-return-order-list .review_section_main {
    display: grid !important;
  }
  .buyer-return-order-list .track_order_details_main {
    text-align: left !important;
  }
  .buyer-return-order-list .order_section .container-fluid {
    padding: 0px;
  }
  .buyer-return-order-list .nav-pills .nav-link {
    font-size: 9px;
  }
  .buyer-return-order-list .track_order_section_left p span {
    margin: 0px 6px 0px 0px;
  }
  .buyer-return-order-list .track_order_section_right a .heading-sm {
    font-size: 10px;
    color: #475156;
  }
  .buyer-return-order-list .cancel_order_heading_left h2 {
    font-size: 20px;
  }
  .buyer-return-order-list .cancel_order_heading_left {
    grid-template-columns: 40% 60%;
  }
  .buyer-return-order-list .track_order_details .track_order_details_main p {
    font-size: 10px;
    line-height: 16px;
  }
}
@media (max-width: 575px) {
  .buyer-return-order-list .track_order_details {
    grid-template-columns: 1fr 1fr;
  }
  .buyer-return-order-list .track_order_details_main.text-center {
    text-align: left !important;
  }
  .buyer-return-order-list .track_order_details_main.text-end {
    text-align: left !important;
  }
  .buyer-return-order-list .track_order_details_main p {
    font-size: 10px;
    line-height: 16px;
  }
  .buyer-return-order-list .track_order_details_main h6 {
    font-size: 10px;
    line-height: 16px;
    margin-bottom: 0px;
  }
  .buyer-return-order-list .track_order_section_left a {
    font-size: 9px;
    display: inline;
  }
  .buyer-return-order-list .track_order_section_right a {
    font-size: 10px;
    display: inline-block;
    text-align: left !important;
  }
  .buyer-return-order-list .track_order_section_left a {
    font-size: 12px;
    padding-left: 0px;
    text-align: left;
  }
  .buyer-return-order-list .track_order_details .track_order_details_main .main_buttons .button-white {
    margin-bottom: 10px;
  }
}
@media (max-width: 320px) {
  .buyer-completed-order .track_order_details_main .cancel_order {
    font-size: 6px;
  }
  .buyer-completed-order .track_order_section_left a {
    font-size: 8px;
    display: inline;
  }
  .buyer-completed-order .track_order_section_right a {
    font-size: 8px;
    display: inline;
  }
}/*# sourceMappingURL=BuyerReturnOrderListContent.css.map */