.buyer-header {
  /* Header */
  /* .main_navigation ul li a:nth-child(1){
      padding: 0px 10px 0px 0px;
  } */
  /* Sidebar */
  /* New Sub category */
  /* New Sub category */
  /* Sub Category  */
}
.buyer-header .main_header {
  position: sticky;
  top: 0;
  z-index: 99;
}
.buyer-header .header {
  width: 100%;
  position: relative;
  background-color: var(--var-background-color);
  padding: 5px 0px;
}
.buyer-header .header .logo_left {
  padding: 0px 0px 0px 15px;
}
.buyer-header .logo-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.buyer-header .logo-section-left a {
  text-decoration: none;
  color: var(--var-white-color);
  font-size: 13px;
  text-align: center;
  margin: 0 auto;
  display: inline-block;
}
.buyer-header .delivery-section-inner {
  display: flex;
  align-items: center;
}
.buyer-header .delivery-section-inner i {
  color: var(--var-white-color);
  padding: 0px 15px 0px 15px;
}
.buyer-header .delivery-section-inner p {
  color: var(--var-white-color);
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 0px;
}
.buyer-header .delivery-section-inner p span {
  font-size: 15px;
  font-weight: 500;
  display: block;
}
.buyer-header .search_bar {
  width: 100%;
  position: relative;
}
.buyer-header .search_bar input {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border: 0;
  padding-left: 110px;
}
.buyer-header .search_bar .search-btn i {
  position: absolute;
  top: 0;
  right: 0;
  width: 45px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: var(--var-light-blue-color);
  color: var(--var-white-color);
  border-radius: 0px 4px 4px 0px;
  border: 1px #fff solid;
}
.buyer-header .search_bar .top_select {
  position: absolute;
  top: 0;
  left: 0;
  width: 160px;
  height: 40px;
  line-height: 40px;
  text-align: left;
  background: var(--var-gray-color);
  color: var(--var-primary-color);
  border: 0;
  border-radius: 4px 0px 0px 4px;
  z-index: 1;
}
.buyer-header .search_bar .top_select select {
  word-wrap: normal;
  width: 100%;
  height: 41px;
  border: 0px;
  background: #f3f3f3;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.buyer-header .search_bar .top_select select:focus-visible {
  outline: none;
}
.buyer-header .css-13cymwt-control {
  padding-left: 165px;
  height: 42px;
}
.buyer-header .css-19bb58m {
  margin: 0px;
  padding-bottom: 0px;
  padding-top: 0px;
}
.buyer-header .css-hlgwow {
  padding: 0px 8px;
}
.buyer-header .css-t3ipsp-control {
  padding-left: 165px;
  box-shadow: none;
  border-color: transparent;
}
.buyer-header .css-t3ipsp-control:hover {
  border-color: transparent;
}
.buyer-header .css-qbdosj-Input {
  margin: 0px;
  padding-bottom: 0px;
  padding-top: 0px;
}
.buyer-header .css-1fdsijx-ValueContainer {
  padding: 0px 8px;
}
.buyer-header .css-b62m3t-container {
  position: relative;
  box-sizing: border-box;
  z-index: 1;
}
.buyer-header .css-b62m3t-container:focus-visible {
  outline: none;
}
.buyer-header .css-166bipr-Input {
  margin: 0px !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}
.buyer-header .search_bar .top_select .dropdown-toggle {
  font-size: 14px;
  padding: 0px 4px 0px 4px;
}
.buyer-header .see_all {
  color: #5F6C72;
  font-size: 18px !important;
  padding: 0px 0px 0px 8px;
  line-height: 0px;
}
.buyer-header .see_all_btn .see_all {
  transform: rotate(180deg);
}
.buyer-header .search_bar .top_select .dropdown-menu {
  border-radius: 0px;
  margin-top: 4px !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1607843137);
}
.buyer-header .search_bar .top_select .dropdown-menu li {
  padding: 4px 0px 0px 7px;
  line-height: 23px;
  font-size: 14px;
}
.buyer-header .header-right-side {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.buyer-header .inner_country img {
  max-width: 24px;
  margin-right: 2px;
}
.buyer-header .inner_country .dropdown-toggle::after {
  display: none;
}
.buyer-header .inner_country .btn {
  color: var(--var-white-color);
}
.buyer-header .inner_country a {
  color: var(--var-primary-color);
  font-size: 14px;
  font-weight: 500;
}
.buyer-header .account_section {
  display: flex;
  align-items: center;
  padding-right: 22px;
  position: relative;
}
.buyer-header .account_section a {
  text-decoration: none;
  display: flex;
  align-items: center;
}
.buyer-header .account_section p {
  color: var(--var-white-color);
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0px;
  padding-left: 22px;
}
.buyer-header .account_section p span {
  font-size: 14px;
  font-weight: 500;
  display: block;
}
.buyer-header .account_section i {
  color: var(--var-white-color);
}
.buyer-header .return_section {
  display: flex;
  align-items: center;
  padding-right: 30px;
  position: relative;
}
.buyer-header .return_section p {
  color: var(--var-white-color);
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0px;
  padding-left: 22px;
}
.buyer-header .return_section a {
  text-decoration: none;
  color: var(--var-white-color);
}
.buyer-header .return_section a img {
  width: 22px;
  padding-right: 4px;
  position: absolute;
  left: 0px;
}
.buyer-header .return_section i {
  color: var(--var-white-color);
}
.buyer-header .return_section p span {
  font-size: 14px;
  font-weight: 500;
  display: block;
}
.buyer-header .cart_section p {
  margin-bottom: 0px;
  color: #fff;
  position: relative;
}
.buyer-header .cart_section a {
  text-decoration: none;
}
.buyer-header .cart_section p .cart_number {
  position: absolute;
  left: 8px;
  color: #fff;
  top: -5px;
  font-size: 14px;
}
.buyer-header .secondary_header {
  width: 100%;
  position: relative;
  background-color: var(--var-light-background-color);
  padding: 5px 0px;
}
.buyer-header .main_navigation ul {
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0px;
}
.buyer-header .main_navigation ul li #menu-toggle {
  position: relative;
  padding-left: 22px;
}
.buyer-header .fa-bars {
  display: none;
}
.buyer-header .main_navigation ul li #menu-toggle:before {
  content: "";
  background: url(/public/assets/images/bar.png) no-repeat;
  width: 15px;
  position: absolute;
  left: 0;
  top: 4px;
  height: 15px;
}
.buyer-header .main_navigation ul li {
  color: var(--var-white-color);
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  margin-inline-end: 20px;
}
.buyer-header .main_navigation ul li:nth-child(2) {
  color: var(--var-white-color);
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  margin-inline-start: 57px;
}
.buyer-header .slide-out-menu ul li:first-child {
  color: var(--var-white-color);
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  margin-inline-start: 0px !important;
}
.buyer-header .slide-out-menu ul li:nth-child(2) {
  color: var(--var-white-color);
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  margin-inline-start: 0px !important;
}
.buyer-header .main_navigation ul li:first-child {
  color: var(--var-white-color);
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  margin-inline-start: 20px;
}
.buyer-header .main_navigation .dropdown-menu {
  border: 1px var(--var-light-blue-color) solid;
  width: 250px;
}
.buyer-header .main_navigation .dropdown-menu ul li {
  color: var(--var-white-color);
  display: block;
  font-size: 14px;
  font-weight: 600;
}
.buyer-header .main_navigation .dropdown-menu ul li a i {
  font-size: 16px;
  padding-right: 5px;
}
.buyer-header .main_navigation .dropdown-menu p {
  color: #5F6C72;
  font-weight: 700;
  padding-left: 10px;
  margin-bottom: 10px;
}
.buyer-header .main_navigation .dropdown-toggle {
  padding: 0;
  font-size: 15px;
}
.buyer-header .dropdown-toggle.btn {
  border: none !important;
  padding: 1px;
}
.buyer-header .active {
  background: var(--var-light-blue-color);
  color: var(--var-white-color) !important;
}
.buyer-header .main_navigation .dropdown-menu ul li a {
  color: #5F6C72;
  text-decoration: none;
  padding: 0px 16px 0px 16px;
}
.buyer-header .main_navigation ul li a {
  color: var(--var-white-color);
  text-decoration: none;
  padding: 0px 10px 0px 10px;
}
.buyer-header .header_category {
  padding: 0px !important;
  display: inline-block;
}
.buyer-header .main_navigation ul li a i {
  font-size: 10px;
}
.buyer-header .main_navigation .dropdown-menu ul li a:nth-child(1) {
  padding: 8px 10px 8px 10px;
}
.buyer-header .main_navigation .dropdown-toggle::after {
  display: none;
}
.buyer-header .main_navigation .btn {
  color: var(--var-white-color);
}
.buyer-header .secondary_header_right {
  display: flex;
  justify-content: flex-end;
}
.buyer-header .seller_btn {
  padding-right: 45px;
}
.buyer-header .seller_btn a {
  background-color: var(--var-light-blue-color);
  text-decoration: none;
  color: var(--var-white-color);
  font-size: 12px;
  font-weight: 1000;
  padding: 5px 30px;
  display: inline-block;
  border-radius: 10px;
}
.buyer-header .wish_list a {
  font-size: 12px;
  color: var(--var-white-color);
  text-decoration: none;
}
.buyer-header .wish_list a i {
  color: #E30808;
  font-size: 14px;
}
.buyer-header .header_in {
  display: none;
}
.buyer-header .main_navigation ul li .sub-menu.mega-menu-column-4 {
  max-width: 1100px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 15px;
}
.buyer-header .main_navigation ul li .sub-menu.mega-menu {
  left: 50%;
  transform: translateX(-50%);
}
.buyer-header .main_navigation .sub-menu {
  position: absolute;
  z-index: 500;
  background-color: #ffffff;
  box-shadow: -2px 2px 70px -25px rgba(0, 0, 0, 0.3);
  padding: 20px 30px;
  transition: all 0.5s ease;
  margin-top: 25px;
  opacity: 0;
  visibility: hidden;
}
.buyer-header .main_navigation > ul > li.menu-item-has-children:hover .sub-menu {
  margin-top: 9px;
  visibility: visible;
  opacity: 1;
}
.buyer-header .logo-section-left img {
  width: 108px;
}
.buyer-header .main_navigation > ul > li.menu-item-has-children:hover a i {
  transform: rotate(180deg);
  transition-duration: 0.4s;
}
.buyer-header .main_navigation > ul > li .sub-menu.mega-menu-column-4 > .list-item {
  flex: 0 0 25%;
  padding: 0 15px;
}
.buyer-header .main_navigation > ul > li .sub-menu.mega-menu-column-4 > .list-item .title {
  font-size: 16px;
  color: var(--var-primary-color);
  font-weight: 600;
  line-height: 1;
  padding: 10px 0;
  margin-bottom: 0px;
}
.buyer-header .main_navigation > ul > li .sub-menu.mega-menu > .list-item > ul > li {
  line-height: 1;
  display: block;
}
.buyer-header .main_navigation > ul > li .sub-menu.mega-menu > .list-item > ul > li > a {
  padding: 10px 0;
  display: inline-block;
  font-size: 15px;
  color: #555555;
  transition: color 0.3s ease;
}
.buyer-header header:before {
  content: "";
  display: block;
  z-index: -1;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  background: rgba(0, 0, 0, 0.4);
  transition: opacity 0.2s, visibility 0ms linear 0.2s, z-index 0ms linear 0.2s;
}
.buyer-header header.active:before {
  transition: opacity 0.2s, visibility 0ms;
  z-index: 5;
  opacity: 1;
  visibility: visible;
}
.buyer-header header.active nav.slide-out-menu {
  transform: translateZ(0);
  opacity: 1;
  visibility: visible;
  transition: opacity 0.2s, visibility 0ms, transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}
.buyer-header header nav.navbar {
  padding: 0px;
}
.buyer-header .menu-panels p {
  color: #5F6C72;
  font-weight: 700;
  padding-left: 10px;
  margin-bottom: 10px;
  font-size: 20px;
  margin-top: 10px;
}
.buyer-header header nav.slide-out-menu {
  z-index: 9000;
  background: #fff;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  max-width: 290px;
  opacity: 0;
  visibility: hidden;
  overflow-y: hidden;
  overflow-x: hidden;
  transition: opacity 0.2s, visibility 0ms linear 0.2s, transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  transform: translate3d(-290px, 0, 0);
}
.buyer-header .slide-out-menu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}
.buyer-header .slide-out-menu::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}
.buyer-header .slide-out-menu::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ccc;
}
.buyer-header header nav.slide-out-menu div.menu-panels {
  position: relative;
  height: 100%;
}
.buyer-header header nav.slide-out-menu div.menu-panels button.menu-link {
  position: relative;
  text-align: left;
  width: 100%;
  display: block;
  padding: 14px 20px;
  background: transparent;
  margin: 0;
  border: none;
  color: #5F6C72;
}
.buyer-header header nav.slide-out-menu div.menu-panels button.menu-link svg {
  position: absolute;
  top: 50%;
  margin-top: -7px;
  height: 14px;
  fill: #5F6C72;
}
.buyer-header header nav.slide-out-menu div.menu-panels button.menu-link svg.arrow-right {
  right: 10px;
}
.buyer-header header nav.slide-out-menu div.menu-panels button.menu-link svg.arrow-left {
  left: 10px;
}
.buyer-header header nav.slide-out-menu div.menu-panels button.menu-link.menu-header {
  text-align: center;
}
.buyer-header header nav.slide-out-menu div.menu-panels ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.buyer-header header nav.slide-out-menu div.menu-panels ul li {
  font-size: 14px;
  border-bottom: 1px solid rgba(229, 229, 229, 0.1882352941);
  width: 100%;
}
.buyer-header header nav.slide-out-menu div.menu-panels ul li a {
  width: 100%;
  display: block;
  padding: 14px 20px;
  color: #5F6C72;
  text-decoration: none;
}
.buyer-header header nav.slide-out-menu div.menu-panels ul li a i {
  font-size: 16px;
  padding-right: 5px;
}
.buyer-header header nav.slide-out-menu div.menu-panel {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  overflow: hidden;
  background: #fff;
  z-index: 0;
  transition: transform 0.4s cubic-bezier(0.23, 1, 0.32, 1), visibility 0s linear 0.4s;
  visibility: hidden;
  transform: translateX(-290px);
}
.buyer-header header nav.slide-out-menu div.menu-panel.is-active {
  visibility: visible;
  transition: transform 0.4s cubic-bezier(0.23, 1, 0.32, 1), visibility 0s;
  transform: translateX(0);
  z-index: 1;
}
.buyer-header .mobile_view {
  display: none;
}
.buyer-header .login_profile button {
  background: transparent;
  border: 0px;
  color: #fff;
  padding-left: 22px;
  display: flex;
  align-items: center;
}
.buyer-header .account_section img {
  width: 22px;
  padding-right: 4px;
  position: absolute;
  left: 0px;
}
.buyer-header .dropdown .dropdown-menu {
  display: none;
}
.buyer-header .dropdown:hover > .dropdown-menu,
.buyer-header .dropend:hover > .dropdown-menu {
  display: block;
  margin-top: 2px;
  margin-left: 0px;
}
.buyer-header .dropdown-item.active,
.buyer-header .dropdown-item:active {
  color: #000;
  background-color: #ffffff;
}
.buyer-header .logout_popup .modal-content {
  box-shadow: 0px 0px 11.7px 10px rgba(0, 0, 0, 0.2509803922);
  border-radius: 20px;
  padding-bottom: 20px;
}
.buyer-header .modal.fade.logout_popup .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
  border-radius: 20px;
}
.buyer-header .logout_popup .modal-content img {
  width: 60%;
}
.buyer-header .logout_popup .modal-content h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 22px;
  color: var(--var-dark-color);
}
.buyer-header .logout_popup .modal-content p {
  font-size: 20px;
  font-weight: 700;
  line-height: 30.12px;
  color: var(--var-dark-color);
  margin-bottom: 0px;
}
.buyer-header .logout_popup .modal-footer .logout_btn {
  background: rgba(8, 170, 227, 0.0392156863);
  border: 0.5px solid #183823;
  color: var(--var-heading-color);
  text-decoration: none;
  border-radius: 10px;
  padding: 8px 30px;
  transition: 0.4s;
  height: 40px;
  line-height: 20px;
  display: inline-block;
}
.buyer-header .logout_popup .modal-footer {
  border: 0px;
  display: block;
  text-align: center;
}
.buyer-header .logout_popup .modal-footer .logout_btn:hover {
  border: 0.5px solid #183823;
  background: #183823;
  color: var(--var-white-color);
  transition: 0.4s;
}

/* Sub Categroy */
@media (max-width: 1300px) {
  .buyer-header .logo-section-left a {
    font-size: 10px;
  }
}
@media (max-width: 1284px) {
  .buyer-header .account_section {
    padding-right: 16px;
  }
  .buyer-header .account_section p {
    font-size: 9px;
  }
  .buyer-header .return_section {
    padding-right: 16px;
  }
  .buyer-header .return_section p {
    font-size: 9px;
  }
}
@media (max-width: 1054px) {
  .buyer-header .delivery-section-inner p span {
    font-size: 13px;
  }
  .buyer-header .logo-section-left a {
    font-size: 9px;
  }
}
@media (max-width: 991px) {
  .buyer-header .mobile_view {
    display: block;
  }
  .buyer-header .header_in {
    display: block;
    z-index: 99;
    position: relative;
  }
  .buyer-header .main_navigation ul li #menu-toggle {
    padding-left: 0px;
  }
  .buyer-header .header_in #menu-toggle:before {
    content: "";
    background: url(/public/assets/images/bar.png) no-repeat;
    width: 15px;
    position: absolute;
    left: 7px;
    top: 9px;
    height: 15px;
  }
  .buyer-header .fa-bars {
    display: inline-block;
  }
  .buyer-header .header .logo_left {
    padding: 0px 0px 0px 4px;
  }
  .buyer-header .header_in button {
    background: transparent;
    border: none;
    width: 30px;
    height: 30px;
    cursor: pointer;
    outline: 0;
    padding: 5px;
  }
  .buyer-header .header_in .toggle_1 span {
    width: 100%;
    height: 3px;
    background: #f5f4f4;
    display: block;
    position: relative;
  }
  .buyer-header .header_in .toggle_1 span:before,
  .buyer-header .toggle_1 span:after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background: #f5f4f4;
    transition: all 0.3s ease-out;
    z-index: 999;
  }
  .buyer-header .header_in .toggle_1 span:before {
    top: -8px;
  }
  .buyer-header .toggle_1 span:after {
    top: 8px;
  }
  .buyer-header .toggle_1 span.toggle_1 {
    background: transparent;
  }
  .buyer-header .toggle_1 span.toggle_1:before {
    top: 0;
    transform: rotate(-45deg);
    background: #fff;
  }
  .buyer-header .toggle_1 span.toggle_1:after {
    top: 0;
    transform: rotate(45deg);
    background: #fff;
  }
  .buyer-header .sidebar {
    background: #fff;
    width: 290px;
    position: fixed;
    top: 54px;
    left: -290px;
    height: 100%;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease-out;
    padding: 15px 0px 0px 10px;
    z-index: 9;
    display: none;
  }
  .buyer-header header nav.slide-out-menu {
    top: 0px;
  }
  .buyer-header .sidebar ul {
    list-style: none;
  }
  .buyer-header .sidebar ul li {
    display: block;
  }
  .buyer-header .sidebar ul li a {
    padding: 8px 15px;
    font-size: 16px;
    color: #222;
    font-family: arial;
    text-decoration: none;
    display: block;
    position: relative;
    z-index: 1;
    transition: all 0.3s ease-out;
    font-weight: 500;
  }
  .buyer-header .sidebar ul li a:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 1px;
    background: #4CAF50;
    z-index: -1;
    transition: all 0.3s ease-out;
  }
  .buyer-header .sidebar ul li a:hover:before {
    width: 100%;
  }
  .buyer-header .sidebar ul li a:hover {
    color: #4CAF50;
  }
  .buyer-header .sidebarshow {
    left: 0;
  }
  .buyer-header .main_navigation .btn {
    color: var(--var-primary-color);
    padding: 0px;
  }
  .buyer-header .main_navigation ul li a:nth-child(1) {
    padding: 10px 10px 10px 0px;
  }
  .buyer-header .logo-section-left {
    display: flex;
    align-items: center;
  }
  .buyer-header .delivery-section-inner p span {
    font-size: 12px;
    font-weight: 400;
    display: inline;
  }
  .buyer-header .header .row .order_4:nth-of-type(2) {
    order: 4;
  }
  .buyer-header .header .row .order_4:nth-of-type(3) {
    order: 3;
  }
  .buyer-header .header .row .order_4:nth-of-type(4) {
    order: 2;
  }
  .buyer-header .logo-section-left img {
    width: 70px;
  }
  .buyer-header .account_section p {
    font-size: 11px;
  }
  .buyer-header .account_section p span {
    font-size: 7px;
  }
  .buyer-header .cart_section p {
    font-size: 11px;
  }
  .buyer-header .cart_section p img {
    width: 18px;
  }
  .buyer-header .return_section p {
    font-size: 11px;
  }
  .buyer-header .return_section p span {
    font-size: 7px;
  }
  .buyer-header .logo-section-left a {
    font-size: 9px;
  }
  .buyer-header .delivery-section-inner i {
    padding: 0px 15px 0px 0px;
  }
  .buyer-header .return_section i {
    font-size: 10px;
  }
  .buyer-header .account_section i {
    font-size: 10px;
  }
  .buyer-header .inner_country i {
    font-size: 10px;
  }
  .buyer-header .delivery-section-inner {
    margin: 8px 0px 8px 0px;
  }
  .buyer-header .seller_btn a {
    padding: 5px 35px;
    margin-right: 20px;
  }
  .buyer-header .search_bar {
    margin-top: 5px;
  }
  .buyer-header .header-right-side {
    display: inline-flex;
    float: right;
  }
  .buyer-header .country_section {
    float: left;
    padding-right: 20px;
  }
  .buyer-header .account_section {
    float: left;
    padding-right: 20px;
  }
  .buyer-header .cart_section {
    float: left;
    padding-right: 0px;
  }
  .buyer-header .secondary_header_right {
    justify-content: flex-end;
  }
  .buyer-header .cart_section p .cart_number {
    font-size: 8px;
    top: -2px;
  }
  .buyer-header .menu-item-has-children {
    display: none !important;
  }
}
@media (max-width: 640px) {
  .buyer-header .secondary_header_right {
    justify-content: space-between;
  }
  .buyer-header .seller_btn a {
    padding: 5px 25px;
    margin-right: 20px;
  }
}
.secondary_header.sticky {
  position: fixed;
  top: 0;
  z-index: 9999999; /* Ensure the header stays above other content */
  transform: translateY(0); /* Transform value when sticky */
  background-color: #000000; /* Background color when sticky */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Box shadow when sticky */
}/*# sourceMappingURL=BuyerHeader.css.map */