.buyer-product-listing {

    h1 {
        font-size: 36px;
    }

    .btn_primary {
        text-decoration: none;
        background: var(--var-light-blue-color);
        color: var(--var-white-color);
        text-transform: uppercase;
        padding: 10px 16px;
    }

    .btn_primary:hover {
        background: var(--var-light-background-color);
        color: var(--var-white-color);
    }

    .shop-page-product .button {
        background: none;
        border: none;
    }

    input,
    select {
        outline: transparent;
    }

    .breadcrumb_section {
        position: relative;
        background-color: #EDEDED;
        box-shadow: 0px 4px 4px 0px #00000040;
        padding: 14px 0px;
    }

    .breadcrumb_section_content h4 {
        font-size: 14px;
        color: var(--var-primary-color);

    }

    /* Product Listing */
    .product_listing {
        position: relative;
        padding: 45px 0px;
    }

    .product_listing_left {
        box-shadow: 0px 0px 30px 0px #00000012;
        padding: 10px;
        height: 100%;
    }

    .filetr_heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .filetr_heading a {
        text-decoration: none;
        font-weight: 400;
        font-size: 18px;
    }

    .product_listing_left h4 {
        font-size: 36px;
        font-weight: 700;
        line-height: 45.18px;
        text-align: left;
        color: #020202;
    }

    .search_bar_product_listing {
        position: relative;
    }

    .input_search {
        width: 50%;
        position: relative;
        float: left;
        margin-bottom: 20px;
    }

    .search_bar_product_listing input {
        width: 100%;
        height: 40px;
        border-radius: 4px;
        border: 1px #ddd solid;
        padding-left: 10px;
    }

    .search_bar_product_listing input:outline {
        border: 0px;
    }

    .search_bar_product_listing .search-btn i {
        position: absolute;
        top: 0;
        right: 0;
        width: 45px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        color: var(--var-light-blue-color);
        border-radius: 0px 4px 4px 0px;
    }

    .input_search .filter {
        float: right;
    }

    .input_search label {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;

    }

    .input_search .filter select {
        height: 44px;
        padding: 12px 16px 12px 16px;
        border-radius: 2px 0px 0px 0px;
        border: 1px #E4E7E9 solid;
        margin-left: 4px;
    }

    .shop-page-product {
        position: relative;
        background: var(--var-white-color);
        padding: 10px;
        border: 1px #E4E7E9 solid;
        border-radius: 3px;
        margin-bottom: 20px;
    }

    .shop-page-product a {
        text-decoration: none;
    }

    .shop-page-product img {
        width: 100%;
    }

    .shop-page-product ul {
        list-style: none;
        padding-left: 0px;
        margin-bottom: 5px;
    }

    .shop-page-product ul li {
        display: inline-block;
        color: #FA8232;
        font-size: 12px;
        padding: 0px 4px 0px 0px;
    }

    .shop-page-product ul li:nth-last-child(1) {
        color: #77878F;
    }

    .shop-page-product h4 {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: var(--var-primary-color);
    }

    .shop-page-product p {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #929FA5;
    }

    .shop-page-product p span {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #2DA5F3;
    }

    .hot_product {
        background: #EFD33D;
        position: absolute;
        top: 6px;
        left: 6px;
        font-size: 12px;
        font-weight: 600;
        color: var(--var-primary-color);
        padding: 5px 10px 5px 10px;
    }

    .best_product {
        background: #2DB224;
        position: absolute;
        top: 6px;
        left: 6px;
        font-size: 12px;
        font-weight: 600;
        color: var(--var-white-color);
        padding: 5px 10px 5px 10px;
    }

    .filter_badge .badge-secondary {
        background: #F0F0F0;
        color: #272727;
        font-size: 12px;
        font-weight: 400;
        margin: 0px 4px 5px 0px;
        padding: 10px 14px 10px 14px;
    }

    .product_list_search .input_search_1 {
        position: relative;
    }

    .product_list_search .input_search_1 input {
        border: 1px #898C8C solid;
        border-radius: 5px;
        height: 35px;
        padding-left: 30px;
        font-size: 14px;
        font-weight: 400;
    }

    .product_list_search .input_search_1 i {
        position: absolute;
        left: 5px;
        top: 10px;
        color: #A3A3A3;
        font-size: 15px;
    }

    .search_popup_inner p {
        font-size: 16px;
        font-weight: 700;
        color: var(--var-primary-color);
        margin-bottom: 8px;
    }

    .search_popup_inner .form-check {
        margin-bottom: 5px;
    }

    .search_popup_inner .form-check-input {
        border: 1px var(--var-primary-text-color) solid;
    }

    .search_popup_inner .form-check-input:checked {
        background-color: #848484;
        border-color: #848484;
    }

    .search_popup_inner label {
        font-size: 14px;
        font-weight: 400;
        color: var(--var-primary-color);
    }

    .product_list_search .modal-footer .btn-secondary {
        background: transparent;
        color: var(--var-light-blue-color);
        border: 0px;
        font-size: 14px;
        font-weight: 400;
    }

    .product_list_search .modal-footer .btn-primary {
        background: var(--var-gray-color);
        color: var(--var-primary-color);
        font-size: 14px;
        font-weight: 400;
        border: 1px #ddd solid;
        padding: 5px 20px;
    }

    .product_list_search .btn-close {
        opacity: 1;
    }

    .brand_section {
        border-bottom: 1px #ddd solid;
        padding-bottom: 10px;
        padding-top: 5px;
    }

    .brand_section_left label {
        font-size: 15px;
        font-weight: 400;
    }

    .brand_section_left label span {
        color: #848484;
    }

    .brand_section_left .form-check-input:checked {
        background-color: #848484;
        border-color: #848484;
    }

    .brand_section_left .form-check {
        margin-bottom: 10px;
    }

    .brand_heading {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 20px;
        align-items: center;
    }

    .brand_heading input {
        border: 1px #ddd solid;
        padding-left: 30px;
        width: 100%;
        cursor: pointer;
        border-radius: 4px;
        height: 30px;
    }

    .input_search_1 {
        position: relative;
    }

    .brand_heading i {
        position: absolute;
        left: 10px;
        top: 9px;
        color: #898C8C;
        font-size: 12px;
    }

    .brand_heading h4 {
        font-size: 24px;
        font-weight: 700;
        color: #272727;
    }

    .discount_range h4 {
        font-size: 24px;
        font-weight: 700;
        color: #191C1F;
    }

    select {
        font-family: "Mulish", sans-serif !important;
    }

    .brand_section_left a {
        text-decoration: none;
    }

    /* Price Range */
    .range-slider {
        position: relative;
    }

    .range-slider .enter_price input {
        width: 48%;
        margin: 0px 3px 0px 0px;
    }

    .price_range_checkbox .form-check {
        margin-bottom: 10px;
    }

    .price_range_checkbox label {
        font-size: 15px;
        font-weight: 400;
        color: #475156;
    }

    .price_range_checkbox .form-check-input:checked {
        background-color: transparent;
        border-color: #0d6efd;
    }

    .price-field span {
        margin-right: 10px;
        margin-top: 6px;
        font-size: 17px;
    }

    .price-field input {
        font-size: 15px;
        border-radius: 2px;
        text-align: center;
        border: 1px #ddd solid;
        width: 96%;
        height: 40px;
        margin: 20px 15px 20px 0px;
    }

    .price-input {
        width: 100%;
        font-size: 19px;
        color: #555;
        display: flex;
        justify-content: space-evenly;
    }

    /* Remove Arrows/Spinners */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .slider-container {
        width: 100%;
    }

    .slider-container {
        height: 6px;
        position: relative;
        background: #e4e4e4;
        border-radius: 5px;
    }

    .slider-container .price-slider {
        height: 100%;
        left: 25%;
        right: 15%;
        position: absolute;
        border-radius: 5px;
        background: var(--var-light-blue-color);
    }

    .range-input {
        position: relative;
    }

    .range-input input {
        position: absolute;
        width: 100%;
        height: 5px;
        background: none;
        top: -5px;
        pointer-events: none;
        cursor: pointer;
        -webkit-appearance: none;
    }

    /* Styles for the range thumb in WebKit browsers */
    .discount_range input[type="range"]::-webkit-slider-thumb {
        height: 18px;
        width: 18px;
        border-radius: 70%;
        border: 1px var(--var-light-blue-color)solid;
        pointer-events: auto;
        -webkit-appearance: none;
        background: var(--var-white-color);
    }

    /* pagination */
    .pagination {
        width: fit-content;
        height: 66px;
        margin: 0 auto;
    }

    .pagination ul {
        margin: 0 auto;
        text-align: center;
        padding: 0px 5px 0px 5px;
    }

    .pagination ul li {
        display: inline-block;
        list-style-type: none;
        margin-left: 5px;
        background: rgba(0, 0, 0, 0.03);
        border-radius: 50%;
        height: 35px;
        width: 35px;
        line-height: 35px;
        -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
        font-weight: 400;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        background-position: 0 -35px;
        -webkit-transition: all .5s ease-in-out;
        -o-transition: all .5s ease-in-out;
        transition: all .5s ease-in-out;

    }

    .pagination ul li:hover,
    .pagination button:hover {
        -webkit-transform: scale(0.96);
        -ms-transform: scale(0.96);
        transform: scale(0.96);
    }

    .pagination button {
        line-height: 32px;
        width: 35px;
        height: 35px;
        border: 0;
        border: 1px var(--var-light-blue-color) solid;
        border-radius: 50px;
        font-weight: 600;
        background-position: 0 -45px;
        -webkit-transition: all .5s ease-in-out;
        -o-transition: all .5s ease-in-out;
        transition: all .5s ease-in-out;
        background: transparent;
    }

    .pagination button i {
        color: var(--var-light-blue-color);
    }

    .pagination ul .active {
        background: var(--var-light-blue-color);
        background-repeat: no-repeat;
        color: #fff;
        background-position: 0 0;
        font-weight: 600;
    }

    .pagination button.active {
        background-color: rgba(0, 0, 0, 0.07);

    }

    /* pagination */

    @media (max-width:1300px) and (min-width:1190px) {
        .shop-page-product h4 {
            font-size: 12px;
        }
    }


    @media (max-width:992px) {
        .shop-page-product h4 {
            font-size: 12px;
        }
    }

    @media (max-width:767px) {
        .product_listing_left {
            padding: 20px;
            margin-bottom: 25px;
        }

        .shop-page-product h4 {
            font-size: 8px;
        }

        .product_listing_right {
            margin-top: 25px;
        }
    }

    @media (max-width:576px) {
        .input_search {
            width: 100%;
        }

        .shop-page-product h4 {
            font-size: 12px;
        }

        .input_search .filter {
            float: left;
        }

        .search_popup_inner label {
            font-size: 10px;
        }

    }
}