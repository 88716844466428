.otp-verification-email {
  /* Forgot Password */
  /* Otp Section */
  /* remove the up/down spinner in number input
     source - https://css-tricks.com/snippets/css/turn-off-number-input-spinners/
  */
}
.otp-verification-email * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.otp-verification-email body {
  font-family: "Mulish", sans-serif;
}
.otp-verification-email .login.form-section {
  height: 100%;
  width: 100%;
}
.otp-verification-email .welcome-login-main {
  align-items: center;
}
.otp-verification-email .fields-section {
  text-align: center;
  background-color: transparent;
  padding-block: 50px;
  padding-left: 15%;
  padding-right: 15%;
}
.otp-verification-email .image-sec img {
  height: 100%;
  width: 100%;
}
.otp-verification-email .opt-number-sec {
  margin-left: 12%;
  margin-right: 12%;
  background: rgba(8, 170, 227, 0.0784313725);
  border: 1px solid #08AAE3;
  box-shadow: 0px 2px 5.9px 2px rgba(0, 0, 0, 0.2509803922);
  padding: 0px 30px 0px 30px;
}
.otp-verification-email .opt-number-sec h3 {
  padding-top: 15%;
}
.otp-verification-email .otp .fields-section {
  text-align: center;
  background-color: transparent;
  margin-top: 5%;
  padding-block: 50px;
  padding-left: 15%;
  padding-right: 15%;
}
.otp-verification-email .resend-sec {
  padding-bottom: 25px;
}
.otp-verification-email .resend-sec a {
  text-decoration: none;
  color: #08AAE3;
}
.otp-verification-email .enter-opt {
  padding-top: 10px;
  padding-bottom: 20px;
}
.otp-verification-email .enter-opt .opt-num {
  padding: 10px 20px;
  border-radius: 20px;
  border: 1px solid #8E8E8E;
  margin: 10px;
  font-weight: 700;
}
.otp-verification-email .verify-btn {
  padding-bottom: 10px;
}
.otp-verification-email .otp_section_inner input {
  border-radius: 50px;
  border-radius: 20px;
  border: 1px solid #8E8E8E;
  width: 52px;
  height: 42px;
  background: transparent;
  text-align: center;
  margin: 0px 3px 0px 3px;
}
.otp-verification-email .forgot_password {
  position: relative;
}
.otp-verification-email .forgot_password .row {
  height: 100vh;
}
.otp-verification-email .forgot_password_img img {
  padding: 60px;
}
.otp-verification-email .forgot_password_img h2 {
  font-size: 30px;
  font-weight: 700;
  line-height: 46.99px;
  margin-bottom: 20px;
}
.otp-verification-email .forgot_password_img label {
  width: 100%;
  font-size: 16px;
  font-weight: 600;
}
.otp-verification-email .forgot_password_img input {
  width: 80%;
  border: 1px solid #D0D5DD;
  padding: 8px;
  border-radius: 8px;
  margin: 10px 0px;
}
.otp-verification-email input {
  outline: none;
}
.otp-verification-email .forgot_password_img button {
  background: #183823;
  display: inline-block;
  color: #fff;
  padding: 6px 21px;
  width: 65%;
  box-shadow: 0px 4px 8px 0px rgba(211, 38, 38, 0.2509803922);
  border-radius: 25px;
  text-decoration: none;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  margin-top: 10px;
  border: 0px;
}
.otp-verification-email .otp_section {
  height: 100vh;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.otp-verification-email .otp_section_inner {
  background: rgba(8, 170, 227, 0.0784313725);
  border: 1px solid #08AAE3;
  box-shadow: 0px 2px 5.9px 2px rgba(0, 0, 0, 0.2509803922);
  text-align: center;
  padding: 100px 30px 100px 30px;
}
.otp-verification-email .otp_section input[type=number]::-webkit-inner-spin-button,
.otp-verification-email .otp_section input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.otp-verification-email .otp_section_inner {
  background: rgba(8, 170, 227, 0.0784313725);
  border: 1px solid #08AAE3;
  box-shadow: 0px 2px 5.9px 2px rgba(0, 0, 0, 0.2509803922);
}
.otp-verification-email .otp_section_inner p {
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
}
.otp-verification-email .otp_section_inner input {
  border-radius: 50px;
  border-radius: 20px;
  border: 1px solid #8E8E8E;
  width: 52px;
  height: 42px;
  background: transparent;
  text-align: center;
}
.otp-verification-email .otp_section_inner h5 {
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  color: #333;
  margin-top: 20px;
  margin-bottom: 15px;
}
.otp-verification-email .otp_section_inner h2 {
  font-size: 30px;
  font-weight: 700;
  line-height: 37.65px;
  color: #000;
  margin-bottom: 20px;
}
.otp-verification-email .verify-btn button {
  padding: 8px 16px;
  border: none;
  background: #183823;
  color: #fff;
  font-weight: 600;
  border-radius: 10px;
  width: 100%;
}
.otp-verification-email .forgot_popup h1 {
  font-size: 22px !important;
  font-weight: 700;
  color: #000;
}
.otp-verification-email .resend_otp_heading {
  font-weight: 700;
  font-size: 19px;
}

@media (max-width: 1100px) {
  .otp-verification-email .forgot_password_img h2 {
    font-size: 20px;
  }
  .otp-verification-email .forgot_password_img a {
    font-size: 13px;
  }
  .otp-verification-email .forgot_password_img img {
    padding: 0px;
  }
}
@media (max-width: 767px) {
  .otp .forgot_password_img button {
    font-size: 13px;
    margin: 0 auto;
    text-align: center;
    display: inline-block;
    width: 66%;
  }
  .otp .forgot_password_img h2 {
    font-size: 22px;
    font-weight: 700;
    line-height: 33.99px;
    margin-bottom: 5px;
  }
  .otp .forgot_password_img img {
    padding: 5px;
  }
  .otp .welcome-login-main .px-5 {
    padding-right: 0rem !important;
    padding-left: 0rem !important;
  }
  .otp .welcome-login-main {
    padding-bottom: 45px;
  }
  .otp .otp_section_inner {
    margin: 20px;
  }
}
@media (max-width: 576px) {
  .otp .forgot_password_img img {
    padding: 15px;
  }
  .otp .forgot_password_img img {
    padding: 15px;
    width: 100%;
  }
  .otp .forgot_pass {
    text-align: center;
  }
  .otp .otp_section_inner input {
    width: 46px;
    height: 36px;
  }
  .otp .forgot_password_img h2 {
    text-align: center;
  }
  .otp .forgot_password_img label {
    text-align: center;
  }
}/*# sourceMappingURL=OtpVerificationEmail.css.map */