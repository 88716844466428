.buyer-product-details {
  /* Slider wrapper */
  /* Slick Slider Item */
  /* Ensure padding is consistent */
  /* Custom Slider */
  /* Slick Slider Navigation */
  /* Ensure Slick Track and Slides are displayed correctly */
  /* Prevent user selection on the slider */
  /* Optional: adjust padding/margins as needed */
  /* Optional: to always show navigation buttons */
  /* Related Products */
  /* Frequently Bought */
  /* Features */
  /* Customer reviews */
}
.buyer-product-details body {
  font-family: "Mulish", sans-serif;
}
.buyer-product-details :root {
  --var-background-color: #141920;
  --var-light-background-color: #252F3D;
  --var-gray-color: #F3F3F3;
  --var-white-color: #fff;
  --var-light-blue-color: #08AAE3;
  --var-primary-color: #18191A;
  --var-primary-text-color: #475156;
  --var-heading-color: #272727;
  --var-green-color: #0A8200;
}
.buyer-product-details h1 {
  font-size: 36px;
}
.buyer-product-details .btn_primary {
  text-decoration: none;
  background: var(--var-light-blue-color);
  color: var(--var-white-color);
  text-transform: uppercase;
  padding: 10px 16px;
}
.buyer-product-details .btn_primary:hover {
  background: var(--var-light-background-color);
  color: var(--var-white-color);
}
.buyer-product-details input,
.buyer-product-details select {
  outline: transparent;
}
.buyer-product-details .breadcrumb_section {
  position: relative;
  background-color: #EDEDED;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
  padding: 10px 0px;
}
.buyer-product-details .breadcrumb_section_content h4 {
  font-size: 14px;
  color: var(--var-primary-color);
}
.buyer-product-details .breadcrumb_section_content ul {
  list-style-type: none;
  padding-left: 0px;
  margin-bottom: 0px;
}
.buyer-product-details .breadcrumb_section_content ul li {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
}
.buyer-product-details .breadcrumb_section_content ul li a {
  text-decoration: none;
  color: #5F6C72;
}
.buyer-product-details .breadcrumb_section_content ul li:nth-child(1) a {
  text-decoration: none;
  color: #5F6C72 !important;
}
.buyer-product-details .breadcrumb_section_content ul li:nth-last-child(1) a {
  text-decoration: none;
  color: var(--var-light-blue-color);
}
.buyer-product-details .product_details {
  position: relative;
  padding: 45px 0px;
}
.buyer-product-details .product_details_left.product-left {
  position: relative;
  overflow: hidden;
}
.buyer-product-details .swiper-container.product-slider img {
  width: 100%;
}
.buyer-product-details .swiper-container.product-thumbs {
  position: relative;
}
.buyer-product-details .swiper-container.product-thumbs img {
  border: 1px #ddd solid;
}
.buyer-product-details .swiper-container .swiper-button-next:after,
.buyer-product-details .swiper-button-prev:after {
  display: none;
}
.buyer-product-details .product-slider .swiper-slide {
  border: 1px #ddd solid;
  border-radius: 4px;
}
.buyer-product-details .product-slider .swiper-slide img {
  border-radius: 4px;
}
.buyer-product-details .button_section .swiper-button-next i,
.buyer-product-details .button_section .swiper-button-prev i {
  background-color: var(--var-light-blue-color);
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: var(--var-white-color);
  text-align: center;
  border-radius: 50%;
}
.buyer-product-details .button_section .swiper-button-prev i {
  margin-left: -20px;
}
.buyer-product-details .button_section .swiper-button-next i,
.buyer-product-details .button_section .swiper-button-prev i {
  width: 35px;
  height: 35px;
  line-height: 35px;
}
.buyer-product-details .button_section .swiper-button-next i,
.buyer-product-details .button_section .swiper-button-prev i {
  margin-right: 5px;
}
.buyer-product-details .button_section {
  display: flex;
  justify-content: space-between;
  direction: rtl;
  margin-top: -9%;
  position: absolute;
  width: 100%;
  margin-bottom: 15px;
  left: 0;
}
.buyer-product-details .swiper-button-next:after,
.buyer-product-details .swiper-container-rtl .swiper-button-prev:after {
  content: "next";
  display: none;
}
.buyer-product-details .product-thumbs .swiper-slide {
  padding: 0px 10px 0px 10px;
}
.buyer-product-details .slider_product_details {
  position: relative;
}
.buyer-product-details .slider_product_details .swiper-button-next,
.buyer-product-details .slider_product_details .swiper-button-prev {
  position: relative;
  justify-content: space-between;
}
.buyer-product-details .swiper-button-next,
.buyer-product-details .swiper-button-prev {
  width: auto !important;
}
.buyer-product-details .product-thumbs .swiper-slide-active img {
  border: 1px var(--var-light-blue-color) solid !important;
}
.buyer-product-details .swiper-button-prev,
.buyer-product-details .swiper-container-rtl .swiper-button-next {
  left: 32px !important;
}
.buyer-product-details .swiper-button-next,
.buyer-product-details .swiper-container-rtl .swiper-button-prev {
  right: 20px !important;
}
.buyer-product-details .thuimb_iiner_img {
  margin: 4px;
}
.buyer-product-details .slider {
  width: 100%;
  overflow: hidden;
  /* Ensure items are inline-flex for horizontal display */
}
.buyer-product-details .slider__item {
  display: inline-flex;
  margin-right: 20px !important;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 0px 0px 0px 30px;
}
.buyer-product-details .product_main_slider img {
  width: 640px !important;
  height: 480px !important;
  -o-object-fit: cover;
     object-fit: cover;
}
.buyer-product-details .custom-slider {
  width: 100%;
  margin: auto;
}
.buyer-product-details .slick-prev,
.buyer-product-details .slick-next {
  position: absolute;
  line-height: 0;
  top: 50%;
  width: 30px;
  height: 30px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  border-radius: 50px;
  background: #043e46;
  display: none !important;
  /* Remove this if you want to display arrows */
}
.buyer-product-details .slick-next {
  right: -30px;
}
.buyer-product-details .slick-prev {
  left: -30px;
}
.buyer-product-details .slick-next:before {
  content: ">";
  font-size: 1.2em;
  font-weight: 1000;
  padding-left: 12px;
  color: white;
}
.buyer-product-details .slick-prev:before {
  content: "<";
  font-size: 1.2em;
  font-weight: 1000;
  padding-left: 9px;
  color: white;
}
.buyer-product-details .slick-slider {
  margin: 0 -15px;
}
.buyer-product-details .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.buyer-product-details .slick-slide {
  display: inline-block;
  float: none !important;
  /* Ensures no float styling affects it */
  height: auto !important;
  /* Ensures auto height */
}
.buyer-product-details .product-details_left.product-left {
  position: relative;
  overflow: hidden;
}
.buyer-product-details .slick-slider {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
}
.buyer-product-details .slider__item:focus-visible {
  outline: none !important;
}
.buyer-product-details .slick-slider .slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.buyer-product-details .slider-sec {
  margin-top: 10px;
}
.buyer-product-details .slider__item {
  margin-right: 0px !important;
  padding: 0px 0px 0px 0px;
}
.buyer-product-details .slide.slider__item.mx-5 {
  margin: 0px !important;
  padding-left: 0px !important;
}
.buyer-product-details .slick-prev,
.buyer-product-details .slick-next {
  display: block !important;
}
.buyer-product-details .slick-next:before {
  color: #000;
  content: "\f054";
  font-family: FontAwesome;
}
.buyer-product-details .slick-prev:before {
  color: #000;
  content: "\f053";
  font-family: FontAwesome;
}
.buyer-product-details .slick-slide {
  scale: 0.8;
}
.buyer-product-details .slick-current {
  scale: 1;
}
.buyer-product-details .slick-track .slick-slide.slick-active.slick-current {
  border: 1px var(--var-light-blue-color) solid !important;
  border-radius: 4px;
  padding: 5px;
}
.buyer-product-details .slick-track .slick-slide.slick-active.slick-current img {
  width: 90px;
  height: 70px;
  -o-object-fit: cover;
     object-fit: cover;
}
.buyer-product-details .slider-nav .slick-current .card {
  border: 0px;
}
.buyer-product-details .img-sec {
  text-align: center;
}
.buyer-product-details .img-sec img {
  width: 80px;
  height: 65px;
  -o-object-fit: contain !important;
     object-fit: contain !important;
}
.buyer-product-details .slider-nav .card {
  border: 1px #ddd solid;
}
.buyer-product-details .product_details_content ul {
  list-style: none;
  padding-left: 0px;
  margin-bottom: 5px;
}
.buyer-product-details .product_details_content ul li {
  display: inline-block;
  color: #FA8232;
  font-size: 12px;
}
.buyer-product-details .product_details_content ul li:nth-last-child(1) {
  color: var(--var-primary-color);
  font-size: 14px;
  font-weight: 700;
}
.buyer-product-details .product_details_content ul li:nth-last-child(1) span {
  color: #5F6C72;
  font-size: 14px;
  font-weight: 400;
}
.buyer-product-details .product_details_content h3 {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  color: var(--var-primary-color);
}
.buyer-product-details .sku_section table {
  width: 100%;
}
.buyer-product-details .sku_section table td {
  color: #5F6C72;
  font-size: 14px;
  font-weight: 400;
}
.buyer-product-details .sku_section table td strong {
  color: #191C1F;
  font-weight: 700;
}
.buyer-product-details .green_stock {
  color: #2DB224 !important;
}
.buyer-product-details .red_stock {
  color: red !important;
}
.buyer-product-details .product_details_price {
  padding: 20px 0px 20px 0px;
  border-bottom: 1px #E4E7E9 solid;
}
.buyer-product-details .product_details_price h2 {
  font-size: 24px;
  font-weight: 700;
  color: var(--var-light-blue-color);
}
.buyer-product-details .product_details_price del {
  font-size: 18px;
  font-weight: 400;
  color: #77878F;
}
.buyer-product-details .product_details_price span {
  background: #EFD33D;
  font-size: 14px;
  font-weight: 600;
  color: var(--var-primary-color);
  padding: 5px 10px 5px 10px;
}
.buyer-product-details .product-details_color {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}
.buyer-product-details .details_color_left p {
  font-size: 14px;
  font-weight: 400;
  color: var(--var-primary-color);
}
.buyer-product-details .details_color_left ul {
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0px;
}
.buyer-product-details .details_color_left ul li {
  display: inline-block;
  padding: 0px 5px 0px 0px;
}
.buyer-product-details .details_color_left ul li span.active {
  width: 35px;
  height: 35px;
  background-color: #E0E1E1;
  border-radius: 50%;
  display: block;
  line-height: 35px;
  border: 4px #fff solid;
  padding: 6px;
  outline: 2px var(--var-light-blue-color) solid;
}
.buyer-product-details .details_color_left ul li span {
  width: 35px;
  height: 35px;
  background-color: #E0E1E1;
  border-radius: 50%;
  display: block;
  line-height: 35px;
  padding: 6px;
  outline: none;
}
.buyer-product-details .details_color_right p {
  font-size: 14px;
  font-weight: 400;
  color: var(--var-primary-color);
}
.buyer-product-details .details_color_right select {
  height: 44px;
  padding: 12px 16px 12px 16px;
  border-radius: 2px 0px 0px 0px;
  border: 1px #E4E7E9 solid;
  width: 100%;
}
.buyer-product-details .quantity_number {
  display: flex;
  border-radius: 0px;
  justify-content: space-between;
  border: 1px #ddd solid;
  min-height: 40px;
  border-radius: 4px;
}
.buyer-product-details .quantity_number .btn {
  cursor: pointer;
  padding: 4px 6px 4px 6px;
}
.buyer-product-details .quantity_number .quantity {
  border: none;
  text-align: center;
  font-size: 16px;
  color: #43484D;
  font-weight: 300;
  background: transparent;
  width: 25px;
}
.buyer-product-details .quantity_number .btn {
  cursor: pointer;
  padding: 4px 6px 4px 6px;
  font-size: 22px;
}
.buyer-product-details .quantity_section {
  display: grid;
  grid-template-columns: 27% 50% 20%;
  align-items: center;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  margin-top: 25px;
  justify-content: space-between;
}
.buyer-product-details .add_to_cart a {
  background-color: #183823;
  color: var(--var-white-color);
  text-align: center;
  text-decoration: none;
  width: 100%;
  display: block;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 12px 0px;
  min-height: 40px;
  border-radius: 4px;
  cursor: pointer;
}
.buyer-product-details .buy_now_btn a {
  color: var(--var-primary-text-color);
  text-align: center;
  text-decoration: none;
  width: 100%;
  display: block;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 12px 0px;
  min-height: 40px;
  border: 1px #ddd solid;
  cursor: pointer;
  border-radius: 4px;
}
.buyer-product-details .add_to_cart a:hover {
  color: #fff;
}
.buyer-product-details .payment_option p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.buyer-product-details .payment_option {
  border: 1px solid #E4E7E9;
  margin-top: 35px;
  padding: 20px;
}
.buyer-product-details .description_section {
  position: relative;
  padding: 45px 0px;
  border-top: 1px #ddd solid;
  border-bottom: 1px #ddd solid;
}
.buyer-product-details .description_content h2 {
  font-size: 32px;
  font-weight: 700;
  line-height: 24px;
}
.buyer-product-details .description_content p {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}
.buyer-product-details .vender_list h4 {
  color: var(--var-primary-color);
  font-size: 18px;
  font-weight: 800;
  margin-top: 15px;
}
.buyer-product-details .description_list ul {
  padding-left: 0px;
  list-style: none;
}
.buyer-product-details .description_list ul li {
  position: relative;
  padding-left: 15px;
  margin-bottom: 10px;
}
.buyer-product-details .description_list ul li::before {
  content: "";
  width: 6px;
  height: 6px;
  background: #7a7a7a;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 10px;
}
.buyer-product-details .related_products {
  position: relative;
  padding: 45px 0px;
}
.buyer-product-details .related_products_inner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;
  width: 100%;
  align-content: space-between;
  margin-bottom: 20px;
  align-content: center;
  margin-bottom: 20px;
  flex-direction: column;
}
.buyer-product-details .related_products_heading h2 {
  font-size: 32px;
  font-weight: 700;
  line-height: 24px;
  color: var(--var-primary-color);
}
.buyer-product-details .related_products_inner p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}
.buyer-product-details .related_products_inner img {
  width: 272px;
  height: 208px;
  -o-object-fit: contain;
     object-fit: contain;
  max-width: 100%;
}
.buyer-product-details .related_products .slick-track .slick-slide.slick-active.slick-current img {
  width: 272px;
  height: 208px;
  -o-object-fit: contain;
     object-fit: contain;
  max-width: 100%;
  border: 0px;
}
.buyer-product-details .related_products .slider .slick-list {
  padding: 0 0px 0 15px !important;
  margin: 0px 30px 0px 0px !important;
}
.buyer-product-details .related_products .slick-track .slick-slide.slick-active.slick-current {
  border: 0px !important;
  border-radius: 4px;
  padding: 5px;
}
.buyer-product-details .related_products .slick-current {
  scale: 0.8;
}
.buyer-product-details .related_products_add_to_cart {
  display: grid;
  grid-template-columns: 80% 20%;
  justify-content: center;
  align-items: center;
  justify-items: stretch;
  grid-column-gap: 10px;
  width: 100%;
}
.buyer-product-details .product_details_wish_list {
  text-align: center;
  border: 1px #FFE7D6 solid;
  height: 43px;
  line-height: 46px;
  border-radius: 4px;
}
.buyer-product-details .size_chart p a img {
  padding-right: 5px;
  width: 34px;
}
.buyer-product-details .size_chart p a {
  color: #333;
  text-decoration: none;
}
.buyer-product-details .product_details_wish_list i {
  font-size: 24px;
}
.buyer-product-details .frequently_bought {
  position: relative;
  padding: 45px 0px;
  border-top: 1px #ddd solid;
}
.buyer-product-details .frequently_bought_main_section {
  align-items: center;
  justify-items: center;
}
.buyer-product-details .plus_icon i {
  color: var(--var-primary-color);
  font-size: 30px;
}
.buyer-product-details .frequently_bought_inner .add_product {
  width: 100%;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  background: #183823;
  color: var(--var-white-color);
  font-size: 16px;
  font-weight: 700;
  line-height: 26.65px;
  padding: 8px;
  border-radius: 10px;
  margin: 0 auto;
}
.buyer-product-details .add_product_text {
  padding-left: 45px !important;
}
.buyer-product-details .frequently_bought_inner p {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: var(--var-primary-color);
  margin-bottom: 0px;
}
.buyer-product-details .frequently_bought_inner .see_more_btn {
  font-size: 16px;
  color: #183823;
  text-decoration: none;
}
.buyer-product-details .features_section {
  position: relative;
  width: 100%;
  padding: 45px 0px;
  border-top: 1px #ddd solid;
  border-bottom: 1px #ddd solid;
}
.buyer-product-details .features_section_inner h2 {
  font-size: 32px;
  font-weight: 700;
  line-height: 24px;
  color: var(--var-primary-color);
}
.buyer-product-details .features_section_inner ul {
  list-style: none;
  padding-left: 0px;
  margin-top: 15px;
}
.buyer-product-details .features_section_inner ul li {
  position: relative;
  padding-left: 30px;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 7px;
}
.buyer-product-details .features_section_inner ul li i {
  position: absolute;
  top: 4px;
  left: 0px;
  color: var(--var-light-blue-color);
}
.buyer-product-details .features_section_table h2 {
  font-size: 32px;
  font-weight: 700;
  line-height: 40.16px;
  color: #191C1F;
}
.buyer-product-details .features_section_table table {
  width: 100%;
  background-color: #E8E8E8;
}
.buyer-product-details .features_section_table table th {
  font-size: 25px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: var(--var-primary-color);
  padding: 12px;
}
.buyer-product-details .features_section_table table tbody tr {
  border: 0.5px solid var(--var-primary-color);
}
.buyer-product-details .features_section_table table tbody tr td:nth-child(1) {
  background-color: var(--var-gray-color);
  background-color: #D9D9D9;
  width: 30%;
}
.buyer-product-details .features_section_table table td strong {
  color: var(--var-primary-color);
}
.buyer-product-details .features_section_table table td b {
  color: var(--var-primary-color);
  font-weight: 700;
  font-size: 24px;
}
.buyer-product-details .table_padding td {
  padding-bottom: 20px !important;
}
.buyer-product-details .features_section_table table td {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  color: var(--var-primary-color);
  padding: 5px 5px 5px 15px;
}
.buyer-product-details .customer_reviews {
  position: relative;
  padding: 45px 0px;
}
.buyer-product-details .customer_reviews_left h2 {
  font-size: 32px;
  font-weight: 700;
  line-height: 32px;
  color: var(--var-primary-color);
}
.buyer-product-details .customer_reviews_left ul {
  list-style: none;
  padding-left: 0px;
  margin-bottom: 5px;
}
.buyer-product-details .customer_reviews_left ul li {
  display: inline-block;
  color: #FA8232;
  font-size: 28px;
}
.buyer-product-details .customer_reviews_left ul li:nth-last-child(1) {
  color: var(--var-primary-color);
  font-size: 20px;
  font-weight: 700;
}
.buyer-product-details .progress_bar table {
  width: 100%;
}
.buyer-product-details .progress_bar table td {
  padding-bottom: 10px;
}
.buyer-product-details .progress_bar table td:nth-child(1) {
  width: 20%;
}
.buyer-product-details .progress_bar table td:nth-child(3) {
  width: 60%;
}
.buyer-product-details .progress_bar table td:nth-child(3) {
  width: 20%;
}
.buyer-product-details .reviews_btn a {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
  text-decoration: none;
  padding: 10px 12px;
  display: block;
  text-align: center;
  background: rgba(243, 242, 242, 0.5019607843);
  color: var(--var-primary-color);
  border-radius: 4px;
  width: 75%;
}
.buyer-product-details .customer_says h2 {
  font-size: 32px;
  font-weight: 700;
  line-height: 24px;
  color: var(--var-primary-color);
}
.buyer-product-details .customer_reviews_main {
  display: flex;
  justify-content: space-between;
  align-content: flex-start;
  margin-top: 20px;
}
.buyer-product-details .customer_reviews_user h3 {
  font-size: 22px;
  font-weight: 700;
  color: #133240;
  margin-top: 10px;
}
.buyer-product-details .customer_reviews_user p {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #133240;
  margin-bottom: 5px;
}
.buyer-product-details .customer_reviews_user_right ul {
  list-style: none;
  padding-left: 0px;
  margin-bottom: 5px;
}
.buyer-product-details .customer_reviews_user_right ul li {
  display: inline-block;
  color: #FA8232;
  font-size: 18px;
}
.buyer-product-details .view-more {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
  background: rgba(243, 242, 242, 0.5019607843);
  color: #000;
  text-decoration: none;
  padding: 10px 24px;
  display: inline-block;
  border-radius: 4px;
  margin-top: 8px;
}
.buyer-product-details .view-more i {
  color: #A7A7A7;
}
.buyer-product-details .shop-page-product {
  position: relative;
  background: var(--var-white-color);
  padding: 10px;
  border: 1px #E4E7E9 solid;
  border-radius: 3px;
  margin-bottom: 20px;
}
.buyer-product-details .shop-page-product a {
  text-decoration: none;
}
.buyer-product-details .shop-page-product img {
  width: 288px;
  height: 235px;
  -o-object-fit: contain;
     object-fit: contain;
  max-width: 100%;
}
.buyer-product-details .hot_product {
  background: #EFD33D;
  position: absolute;
  top: 6px;
  left: 6px;
  font-size: 12px;
  font-weight: 600;
  color: var(--var-primary-color);
  padding: 5px 10px 5px 10px;
}
.buyer-product-details .shop-page-product ul {
  list-style: none;
  padding-left: 0px;
  margin-bottom: 5px;
}
.buyer-product-details .shop-page-product ul li {
  display: inline-block;
  color: #FA8232;
  font-size: 12px;
}
.buyer-product-details .shop-page-product ul li:nth-last-child(1) {
  color: #77878F;
}
.buyer-product-details .shop-page-product h4 {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--var-primary-color);
}
.buyer-product-details .shop-page-product p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #929FA5;
}
.buyer-product-details .shop-page-product p span {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #183823;
}

@media (max-width: 1320px) {
  .buyer-product-details .details_color_right select {
    font-size: 8px;
  }
}
@media (max-width: 1120px) {
  .buyer-product-details .frequently_bought_inner .add_product {
    font-size: 15px;
    padding: 7px;
  }
  .buyer-product-details .frequently_bought_main_section {
    grid-template-columns: 20% 5% 20% 5% 20% 21%;
  }
}
@media (max-width: 1024px) {
  .buyer-product-details .related_products_inner img {
    -o-object-fit: contain;
       object-fit: contain;
  }
  .buyer-product-details .shop-page-product img {
    -o-object-fit: contain;
       object-fit: contain;
    width: 100%;
    height: 155px;
  }
}
@media (max-width: 992px) {
  .buyer-product-details .buy_now_btn a {
    font-size: 12px;
  }
  .buyer-product-details .add_to_cart a {
    font-size: 14px;
  }
  .buyer-product-details .frequently_bought_inner .add_product {
    font-size: 10px;
    line-height: 20px;
  }
  .buyer-product-details .add_product_text {
    padding-left: 7px !important;
  }
  .buyer-product-details .quantity_section {
    grid-template-columns: 27% 48% 20%;
  }
  .buyer-product-details .button_section {
    margin-top: -10%;
  }
  .buyer-product-details .details_color_right select {
    font-size: 10px;
  }
  .buyer-product-details .shop-page-product h4 {
    font-size: 12px;
  }
  .buyer-product-details .img-sec img {
    width: 100%;
    height: 40px;
    -o-object-fit: contain !important;
       object-fit: contain !important;
  }
  .buyer-product-details .slick-track .slick-slide.slick-active.slick-current img {
    width: 100%;
    height: 40px;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media (max-width: 767px) {
  .buyer-product-details .reviews_btn {
    margin-bottom: 25px;
  }
  .buyer-product-details .footer-sec .container.py-4 {
    padding: 0px;
    margin: 0px;
  }
  .buyer-product-details .button_section {
    margin-top: -6%;
  }
  .buyer-product-details .details_color_right select {
    font-size: 14px;
  }
  .buyer-product-details .reviews_btn a {
    width: 100%;
  }
}
@media (max-width: 640px) {
  .buyer-product-details .frequently_bought_main_section {
    grid-template-columns: 59% 1fr;
  }
  .buyer-product-details .add_product_text {
    padding-left: 0px !important;
  }
  .buyer-product-details .product-thumbs .swiper-slide {
    padding: 0px 0px 0px 0px;
  }
  .buyer-product-details .customer_reviews_left h2 {
    font-size: 22px;
  }
  .buyer-product-details .customer_says h2 {
    font-size: 24px;
  }
  .buyer-product-details .product_details_price span {
    font-size: 10px;
  }
  .buyer-product-details .product-details_color {
    grid-template-columns: 1fr;
  }
  .buyer-product-details .button_section {
    margin-top: -7%;
  }
  .buyer-product-details .description_content h2 {
    font-size: 24px;
  }
  .buyer-product-details .progress_bar table td {
    font-size: 12px;
  }
  .buyer-product-details .product_main_slider img {
    width: 100% !important;
    height: 300px !important;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media (max-width: 576px) {
  .buyer-product-details .quantity_section {
    grid-template-columns: 26% 47% 20%;
    justify-content: space-between;
  }
  .buyer-product-details .related_products_heading h2 {
    line-height: 30px;
    font-size: 22px;
  }
  .buyer-product-details .buy_now_btn a {
    font-size: 10px;
    line-height: 20px;
    height: 44px;
  }
  .buyer-product-details .frequently_bought_inner .add_product {
    font-size: 10px;
    padding: 0px;
  }
  .buyer-product-details .product_details_price h2 {
    font-size: 18px;
  }
  .buyer-product-details .button_section {
    margin-top: -30px;
  }
  .buyer-product-details .button_section .swiper-button-next i,
  .buyer-product-details .button_section .swiper-button-prev i {
    width: 26px;
    height: 26px;
    line-height: 26px;
  }
  .buyer-product-details .button_section {
    margin-top: -8%;
  }
  .buyer-product-details .plus_icon {
    text-align: center;
  }
  .buyer-product-details .plus_icon i {
    font-size: 15px;
  }
}
@media (max-width: 480px) {
  .buyer-product-details .button_section {
    margin-top: -10%;
  }
}
@media (max-width: 430px) {
  .buyer-product-details .button_section {
    margin-top: -12%;
  }
}/*# sourceMappingURL=BuyerProductDetailsContent.css.map */