:root {
  --var-background-color: #141920;
  --var-light-background-color: #252F3D;
  --var-gray-color: #F3F3F3;
  --var-white-color: #fff;
  --var-light-blue-color: #08AAE3;
  --var-primary-color: #18191A;
  --var-primary-text-color: #475156;
  --var-heading-color: #272727;
  --var-green-color: #0A8200;
  --var-dark-color: #000000;
}

.buyer-leave-seller-feedback {
  /* Your Orders */
  /* Your Orders */
  /* Seller Feedback */
  /* Cancel Order */
}
.buyer-leave-seller-feedback .order_section {
  position: relative;
  padding: 45px 0px;
}
.buyer-leave-seller-feedback .order_section_inner {
  position: relative;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  padding: 20px 10px 20px 10px;
}
.buyer-leave-seller-feedback .track_order_section_1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px #ddd solid;
  padding-bottom: 20px;
}
.buyer-leave-seller-feedback .track_order_section_left a {
  border: 1px solid #08AAE3;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.59px;
  background: rgba(8, 170, 227, 0.0705882353);
  color: var(--var-heading-color);
  text-decoration: none;
  padding: 6px;
  border-radius: 14px;
  display: inline-block;
}
.buyer-leave-seller-feedback .track_order_section_left a span {
  color: #848484;
}
.buyer-leave-seller-feedback .track_order_section_right a {
  font-size: 18px;
  font-weight: 600;
  line-height: 25.1px;
  background: var(--var-light-blue-color);
  color: var(--var-white-color);
  border-radius: 14px;
  padding: 6px 12px;
  text-decoration: none;
  display: inline-block;
}
.buyer-leave-seller-feedback .track_order_section_right a i {
  padding-right: 6px;
}
.buyer-leave-seller-feedback .track_order_details {
  display: grid;
  grid-template-columns: 7% 24% 15% 15% 15% 20%;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: space-between;
  padding: 15px 10px 15px 10px;
  align-items: center;
}
.buyer-leave-seller-feedback .track_order_details_main p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--var-primary-color);
  margin-bottom: 0px;
}
.buyer-leave-seller-feedback .track_order_details_main h6 {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: var(--var-primary-color);
}
.buyer-leave-seller-feedback .track_order_details_main h6 a {
  text-decoration: none;
  color: #08aae3;
}
.buyer-leave-seller-feedback .track_order_details_main .btn_primary {
  border-radius: 19px;
  font-size: 14px;
  text-transform: none;
  padding: 6px 12px;
}
.buyer-leave-seller-feedback .track_order_details_main .cancel_order {
  border: 1px solid #08AAE3;
  font-size: 14px;
  font-weight: 500;
  line-height: 22.59px;
  background: rgba(8, 170, 227, 0.0705882353);
  color: var(--var-heading-color);
  text-decoration: none;
  padding: 6px 12px;
  border-radius: 19px;
  transition-duration: 0.4s;
}
.buyer-leave-seller-feedback .track_order_details_main .cancel_order:hover {
  background: var(--var-light-blue-color);
  color: var(--var-white-color);
  transition-duration: 0.4s;
}
.buyer-leave-seller-feedback .seller_feedback.cancel_order_section {
  padding: 15px 50px 15px 70px;
}
.buyer-leave-seller-feedback .seller_feedback_main {
  position: relative;
  padding: 0px 0px 45px 0px;
}
.buyer-leave-seller-feedback .seller_feedback_inner h2 {
  font-size: 30px;
  font-weight: 700;
  line-height: 37.65px;
  color: #000;
}
.buyer-leave-seller-feedback .seller_feedback_inner p {
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  color: var(--var-primary-color);
}
.buyer-leave-seller-feedback .seller_feedback_inner textarea {
  padding: 10px;
  width: 100%;
  height: 170px;
  resize: none;
  background-color: #F0F0F0;
  border-radius: 5px;
  border: 1px solid grey;
}
.buyer-leave-seller-feedback .seller_feedback_inner ul {
  list-style: none;
  padding-left: 0px;
  margin-bottom: 5px;
  margin-top: 15px;
}
.buyer-leave-seller-feedback .seller_feedback_inner ul li {
  display: inline-block;
  color: #F8A401;
  font-size: 22px;
}
.buyer-leave-seller-feedback .seller_feedback_inner ul li:nth-child(1) {
  color: var(--var-primary-color);
  font-size: 20px;
  font-weight: 700;
}
.buyer-leave-seller-feedback .seller_feedback_inner ul li:nth-last-child(1) {
  color: var(--var-primary-color);
}
.buyer-leave-seller-feedback .seller_feedback_inner h4 {
  font-size: 24px;
  font-weight: 700;
  line-height: 30.12px;
  color: var(--var-primary-color);
  margin-top: 10px;
}
.buyer-leave-seller-feedback .seller_feedback_inner .form-check {
  margin-bottom: 10px;
}
.buyer-leave-seller-feedback .seller_feedback_inner input {
  font-size: 20px;
  font-weight: 400;
  line-height: 25.1px;
  color: var(--var-primary-color);
}
.buyer-leave-seller-feedback .seller_feedback_inner a {
  background: linear-gradient(92.23deg, #08AAE3 0.56%, #199A8E 102.68%);
  display: inline-block;
  color: #fff;
  text-decoration: none;
  padding: 10px 70px;
  font-size: 24px;
  font-weight: 700;
  border-radius: 10px;
  margin: 25px 0px;
}
.buyer-leave-seller-feedback .review_popup h4 {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  color: #000;
}
.buyer-leave-seller-feedback .review_popup img {
  width: 45%;
}
.buyer-leave-seller-feedback .cancel_order_heading {
  display: grid;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  grid-template-columns: 70% 30%;
}
.buyer-leave-seller-feedback .cancel_order_heading_left {
  position: relative;
}
.buyer-leave-seller-feedback .cancel_order_heading_left h2 {
  font-size: 30px;
  font-weight: 700;
  line-height: 24px;
  color: var(--var-dark-color);
}
.buyer-leave-seller-feedback .cancel_order_search {
  position: relative;
}
.buyer-leave-seller-feedback .cancel_order_search input {
  border: 1px #ddd solid;
  padding-left: 30px;
  width: 100%;
  cursor: pointer;
  border-radius: 4px;
  height: 37px;
}
.buyer-leave-seller-feedback .cancel_order_search i {
  position: absolute;
  left: 10px;
  top: 13px;
  color: #898C8C;
  font-size: 12px;
}
.buyer-leave-seller-feedback .cancel_order_section {
  display: grid;
  grid-template-columns: 7% 25% 20% 20% 20%;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: space-between;
  padding: 15px 10px 15px 10px;
  align-items: center;
}
.buyer-leave-seller-feedback .status_color {
  color: var(--var-light-blue-color) !important;
}

@media (max-width: 767px) {
  .buyer-leave-seller-feedback {
    /* Your Order */
    /* Seller Feedback */
  }
  .buyer-leave-seller-feedback .track_order_details_main p {
    font-size: 10px;
    line-height: 14px;
  }
  .buyer-leave-seller-feedback .track_order_details_main h6 {
    font-size: 10px;
  }
  .buyer-leave-seller-feedback .track_order_details_main .cancel_order {
    font-size: 7px;
  }
  .buyer-leave-seller-feedback .track_order_details_main .btn_primary {
    font-size: 7px;
  }
  .buyer-leave-seller-feedback .seller_feedback.cancel_order_section {
    padding: 15px 10px 15px 10px;
  }
}
@media (max-width: 575px) {
  .buyer-leave-seller-feedback .cancel_order_section {
    grid-template-columns: 1fr 1fr;
  }
}/*# sourceMappingURL=BuyerLeaveSellerFeedbackContent.css.map */