:root {
    --var-background-color: #183823;
    --var-light-background-color: #000000;
    --var-gray-color: #F3F3F3;
    --var-white-color: #fff;
    --var-light-blue-color: #183823;
    --var-primary-color: #18191A;
    --var-primary-text-color: #475156;
    --var-heading-color: #272727;
    --var-green-color: #183823;
    --var-dark-color: #000000;
}

.buyer-contact-us {

    .profile_section {
        position: relative;
        padding: 45px 0px;
    }

    .profile_heading h2 {
        font-size: 30px;
        font-weight: 700;
        line-height: 24px;
        color: #000;
    }

    /* Contact Us */
    .contact_us_section {
        position: relative;
        box-shadow: 0px 0px 60px 30px #00000008;
        border-radius: 10px;
        padding: 5px;
        border: 1px #d9d9d994 solid;
        margin: 0px 30px 0px 30px;
    }

    .contact_us_section_left {
        border: 1px solid #08AAE380;
        background: #08AAE31A;
        padding: 25px;
        border-radius: 10px;
        position: relative;
        overflow: hidden;
    }

    .contact_us_section_left h4 {
        font-size: 28px;
        font-weight: 600;
        line-height: 35.14px;
        color: var(--var-primary-color);
        margin-bottom: 20px;
    }

    .contact_us_section_left p {
        font-size: 18px;
        font-weight: 400;
        line-height: 22.59px;
        margin-bottom: 35px;
    }

    .contact_us_section_left ul {
        list-style: none;
        padding-left: 0px;
        padding-bottom: 0px;
    }

    .contact_us_section_left ul li {
        font-size: 16px;
        font-weight: 400;
        line-height: 20.08px;
        color: var(--var-heading-color);
        position: relative;
        padding-left: 25px;
        margin-bottom: 35px;
    }

    .contact_us_section_left ul li i {
        color: #08AAE3;
        position: absolute;
        left: 0;
        top: 5px;
    }

    .contact_us_section_left .circle {
        background: url(/public/assets/images/circle.png);
        position: absolute;
        bottom: 0px;
        width: 150px;
        height: 150px;
        right: 0;
        background-position: 0% 0%;
        background-repeat: no-repeat;
    }

    .social_media_icon {
        position: absolute;
        bottom: 20px;
        z-index: 9;
    }

    .social_media_icon ul {
        list-style: none;
        margin-bottom: 0px;
    }

    .social_media_icon ul li {
        display: inline-block;
        margin: 0px;
        color: #444444;
    }

    .social_media_icon ul li a {
        color: #444444;
    }

    .social_media_icon ul li:nth-child(1) {
        padding-left: 0px;
    }

    .social_media_icon ul li i {
        position: relative;
        color: #444444;
    }

    .radio_buttom_section {
        display: flex;
        justify-items: start;
    }

    .radio_buttom_section .form-check:nth-child(1) {
        padding-left: 20px;
    }

    .radio_buttom_section .form-check {
        padding-left: 40px;
    }

    .contact_us_section_right_field label {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        color: var(--var-dark-color);
        width: 100%;
    }

    .contact_us_section_right_field input {
        border-top: 0px;
        border-left: 0px;
        border-right: 0px;
        border-bottom: 1px #ddd solid;
        width: 100%;
        
    }

    .contact_us_section_right_field input::placeholder {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: var(--var-dark-color);
    }

    .contact_us_section_right_field .message {
        margin: 30px 0px 20px 0px;
    }

    .contact_us_section_right_field textarea {
        border-top: 0px;
        border-left: 0px;
        border-right: 0px;
        border-bottom: 1px #ddd solid;
        width: 100%;
        height: 30px;
    }

    .contact_us_section_right_field textarea::placeholder {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #8D8D8D;
    }
    input:focus-visible{
        outline: transparent;
    }
    textarea:focus-visible{
        outline: transparent;
    }
    .contact_us_section_right {
        padding: 25px 20px 25px 15px;
    }

    .contact_us_section_right h2 {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        color: var(--var-dark-color);
    }

    // .contact_us_section_right .form-check-input:checked {
    //     background-color: #222;
    //     border-color: #222;
    //     background-image: none;

    // }
    .contact_us_section_right .form-check-input:focus {
        box-shadow: none;
    }

    .contact_us_section_right_field button {
        background: #183823;
        box-shadow: 0px 0px 14px 0px #0000001F;
        padding: 15px 48px 15px 48px;
        border-radius: 5px;
        color: var(--var-white-color);
        border: 0px;
        font-size: 16px;
        font-weight: 500;
        line-height: 20.08px;
        margin: 20px 0px 60px 0px;
    }

}
@media (max-width:992px){
    .buyer-contact-us {
    .contact_us_section_left h4 {
        font-size: 15px;
    }
    .contact_us_section_left p {
        font-size: 14px;
    }
    .contact_us_section_left ul li {
        font-size: 14px;
    }
}
}
@media (max-width:767px){
    .buyer-contact-us {
    .radio_buttom_section .form-check {
        padding-left: 30px;
    }
    .radio_buttom_section .form-check-label {
        font-size: 13px;
    }
}
}
@media (max-width:640px){
    .buyer-contact-us {
        .contact_us_section {
            margin: 0px 10px 0px 10px;
        }
    .radio_buttom_section {
        display: block;
        float: left;
    }
    .radio_buttom_section .form-check {
        width: 50%;
        float: left;
        display: flex;
        align-items: center;
    }
    .radio_buttom_section .form-check:nth-child(1) {
        padding-left: 30px;
    }
    .radio_buttom_section .form-check-label {
        font-size: 10px;
        padding-left: 4px;
        padding-top: 5px;
    }
    .contact_us_section_right_field .text-end{
        text-align: center !important;
    }
    .contact_us_section_right_field button{
        text-align: center !important;
    }
}
}