.BuyerLandingPage {
  /* Slider  */
  /* Product Category */
  /* Trending Now */
  /* Slider wrapper */
  /* Slick Slider Item */
  /* Ensure padding is consistent */
  /* Custom Slider */
  /* Slick Slider Navigation */
  /* Ensure Slick Track and Slides are displayed correctly */
  /* Prevent user selection on the slider */
  /* Optional: adjust padding/margins as needed */
  /* Optional: to always show navigation buttons */
  /* Sell Product */
  /* New Product */
  /* Shop Categories */
}
.BuyerLandingPage body {
  font-family: "Mulish", sans-serif;
}
.BuyerLandingPage :root {
  --var-background-color: #141920;
  --var-light-background-color: #252F3D;
  --var-gray-color: #F3F3F3;
  --var-white-color: #fff;
  --var-light-blue-color: #08AAE3;
  --var-primary-color: #18191A;
  --var-primary-text-color: #475156;
  --var-heading-color: #272727;
  --var-green-color: #0A8200;
  --var-dark-color: #000000;
}
.BuyerLandingPage h1 {
  font-size: 32px;
}
.BuyerLandingPage .btn_primary {
  text-decoration: none;
  background: var(--var-light-blue-color);
  color: var(--var-white-color);
  text-transform: uppercase;
  padding: 10px 16px;
}
.BuyerLandingPage .btn_primary:hover {
  background: var(--var-light-background-color);
  color: var(--var-white-color);
}
.BuyerLandingPage input,
.BuyerLandingPage select {
  outline: none;
}
.BuyerLandingPage .carousel-indicators {
  display: none;
}
.BuyerLandingPage .home_slider .carousel-control-next,
.BuyerLandingPage .carousel-control-prev {
  top: 17%;
  width: 6%;
  background-color: #f9d9c9;
  margin: 12px;
}
.BuyerLandingPage .home_slider .carousel-control-next-icon,
.BuyerLandingPage .carousel-control-prev-icon {
  filter: brightness(0);
}
.BuyerLandingPage .home_slider img {
  width: 100%;
  height: 400px;
  -o-object-fit: cover;
     object-fit: cover;
}
.BuyerLandingPage .product_category {
  position: relative;
  margin-top: -100px;
  z-index: 9;
}
.BuyerLandingPage .product_section {
  background: #F2F4F5;
  border: 0px;
  padding: 10px;
}
.BuyerLandingPage .product_category_main {
  display: grid;
  align-items: center;
  padding: 30px 0px 25px 0px;
  justify-content: space-between;
  grid-template-columns: 60% 40%;
}
.BuyerLandingPage .product_category_main_right {
  position: relative;
}
.BuyerLandingPage .product_category_main_right img {
  max-width: 100%;
  width: 375px;
  height: 350px;
  -o-object-fit: contain;
     object-fit: contain;
}
.BuyerLandingPage .product_section .active {
  background: transparent;
  color: transparent !important;
}
.BuyerLandingPage .product_category_main_left h6 {
  font-size: 14px;
  font-weight: 600;
  color: var(--var-light-blue-color);
  position: relative;
  padding-left: 30px;
}
.BuyerLandingPage .product_category_main_left h6:before {
  content: "";
  width: 24px;
  height: 2px;
  background: var(--var-light-blue-color);
  position: absolute;
  left: 0px;
  top: 6px;
}
.BuyerLandingPage .product_category_main_left h2 {
  font-size: 32px;
  font-weight: 600;
  color: var(--var-primary-color);
}
.BuyerLandingPage .product_category_main_left p {
  font-size: 18px;
  font-weight: 600;
  color: var(--var-primary-text-color);
}
.BuyerLandingPage .product_section .carousel-indicators {
  display: block;
  margin-left: 0px;
  margin-bottom: 0px;
}
.BuyerLandingPage .product_section .carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 10px;
  height: 10px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #abb0b3;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.8;
  transition: opacity 0.6s ease;
  border-radius: 50%;
}
.BuyerLandingPage .product_section .carousel-indicators .active {
  opacity: 1;
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 10px;
  height: 10px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: var(--var-primary-text-color);
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.8;
  transition: opacity 0.6s ease;
  border-radius: 50%;
}
.BuyerLandingPage .offer_circle {
  position: absolute;
  top: 0px;
  z-index: 9;
  right: 0;
  width: 100px;
  height: 100px;
  background: var(--var-light-blue-color);
  border-radius: 50%;
  color: var(--var-white-color);
  text-align: center;
  line-height: 100px;
  font-size: 20px;
  font-weight: 600;
}
.BuyerLandingPage .product_section_black {
  background: #191C1F;
  border: none;
}
.BuyerLandingPage .product_section_black_inner {
  display: flex;
  align-items: center;
  padding: 20px 0px 0px 20px;
  overflow: hidden;
  justify-content: space-around;
}
.BuyerLandingPage .gray_section_main {
  padding: 20px 0px 20px 20px;
}
.BuyerLandingPage .product_section_black_inner_left h6 {
  font-size: 14px;
  font-weight: 600;
  color: #EBC80C;
  text-transform: uppercase;
}
.BuyerLandingPage .product_section_black_inner_left h2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  color: var(--var-white-color);
}
.BuyerLandingPage .product_section_black_inner_right img {
  border-radius: 0px 0px 4px 0px;
  width: 190px;
  height: 162px;
  -o-object-fit: cover;
     object-fit: cover;
}
.BuyerLandingPage .gray_section img {
  width: 170px;
  height: 170px;
  -o-object-fit: cover;
     object-fit: cover;
}
.BuyerLandingPage .product_section_black_inner_right {
  padding-top: 20px;
  position: relative;
}
.BuyerLandingPage .new_product_inner_main img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.BuyerLandingPage .product_offer {
  text-align: center;
  display: inline-block;
  background: #EBC80C;
  padding: 5px 12px;
  position: absolute;
  right: 35px;
  top: 3px;
}
.BuyerLandingPage .product_section_gray_inner_left h2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  color: #191C1F;
}
.BuyerLandingPage .heart {
  margin-top: 10px;
  font-size: 30px;
  margin-right: 10px;
  color: black;
}
.BuyerLandingPage .trending_now {
  position: relative;
  padding: 25px 0px;
}
.BuyerLandingPage .headeing h1 {
  color: var(--var-heading-color);
  font-weight: 700;
}
.BuyerLandingPage .trending_product {
  position: relative;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  width: 94%;
}
.BuyerLandingPage .trending_product .image img {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  width: 100%;
  height: 255px;
  -o-object-fit: fill;
     object-fit: fill;
  max-width: 100%;
  text-align: center;
  margin: 0 auto;
}
.BuyerLandingPage .trending_product h5 {
  font-size: 22px;
  font-weight: 500;
  color: var(--var-heading-color);
  margin: 0;
  white-space: nowrap;
  /* Prevents the text from wrapping to the next line */
  overflow: hidden;
  /* Hides any overflowing text */
  text-overflow: ellipsis;
  /* Adds ellipses (...) at the end of the text if it overflows */
}
.BuyerLandingPage .trending_product h6 {
  font-size: 18px;
  font-weight: 400;
  color: var(--var-heading-color);
  line-height: 30px;
  margin: 0px;
}
.BuyerLandingPage .trending_product h6 span {
  color: #848484;
}
.BuyerLandingPage .trending_product h6 span i {
  color: #ffd700;
}
.BuyerLandingPage .trending_product h2 {
  font-size: 20px;
  font-weight: 500;
  color: var(--var-heading-color);
  line-height: 30px;
  white-space: normal;
}
.BuyerLandingPage .trending_product h2 span {
  color: #848484;
  font-size: 18px;
  font-weight: 400;
}
.BuyerLandingPage .trending_product h2 strong {
  color: var(--var-green-color);
  font-size: 18px;
  font-weight: 700;
}
.BuyerLandingPage .trending_product .product-desc {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.BuyerLandingPage .trending_product p {
  color: var(--var-heading-color);
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0px;
}
.BuyerLandingPage .slider {
  width: 100%;
  overflow: hidden;
  /* Ensure items are inline-flex for horizontal display */
}
.BuyerLandingPage .slider__item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 0px 5px 0px 0px;
  padding-top: 20px;
}
.BuyerLandingPage .custom-slider {
  width: 100%;
  margin: auto;
}
.BuyerLandingPage .slick-prev,
.BuyerLandingPage .slick-next {
  position: absolute;
  line-height: 0;
  top: 50%;
  width: 30px;
  height: 30px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  border-radius: 50px;
  background: #043e46;
  display: none !important;
  /* Remove this if you want to display arrows */
}
.BuyerLandingPage .slick-next {
  right: -30px;
}
.BuyerLandingPage .slick-prev {
  left: -30px;
}
.BuyerLandingPage .slick-next:before {
  content: ">";
  font-size: 1.2em;
  font-weight: 1000;
  padding-left: 12px;
  color: white;
}
.BuyerLandingPage .slick-prev:before {
  content: "<";
  font-size: 1.2em;
  font-weight: 1000;
  padding-left: 9px;
  color: white;
}
.BuyerLandingPage .slick-slider {
  margin: 0 -15px;
}
.BuyerLandingPage .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.BuyerLandingPage .slick-slide {
  display: inline-block;
  float: none !important;
  /* Ensures no float styling affects it */
  height: auto !important;
  /* Ensures auto height */
}
.BuyerLandingPage .slick-slider {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.BuyerLandingPage .slider .slick-list {
  padding: 0 10% 0 15px !important;
}
.BuyerLandingPage .slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.BuyerLandingPage .slick-prev,
.BuyerLandingPage .slick-next {
  display: block !important;
}
.BuyerLandingPage .sell_product {
  position: relative;
  width: 100%;
  padding: 0px 0px 0px 0px;
}
.BuyerLandingPage .sell_product_inner {
  background-color: rgba(248, 248, 248, 0.937254902);
  border: 1px #C7C7C7 solid;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
}
.BuyerLandingPage .sell_product_inner h3 {
  font-size: 18px;
  font-weight: 700;
  color: #484848;
}
.BuyerLandingPage .sell_product_inner h2 {
  font-size: 18px;
  font-weight: 700;
  color: var(--var-primary-color);
}
.BuyerLandingPage .sell_product .sell_product_inner_main {
  display: grid;
  grid-template-columns: auto auto !important;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  height: -moz-max-content;
  height: max-content;
}
.BuyerLandingPage .see_more_btn {
  color: #0d6efd;
  font-size: 14px;
  font-weight: 500;
}
.BuyerLandingPage .sell_product_inner_main_section p {
  font-size: 13px;
  color: var(--var-primary-color);
  font-weight: 400;
  margin-bottom: 0px;
  margin-top: 5px;
}
.BuyerLandingPage .sell_product_inner_main_section img {
  width: 100% !important;
  height: 157px;
  -o-object-fit: fill;
     object-fit: fill;
}
.BuyerLandingPage .new_product {
  position: relative;
  padding: 25px 0px;
}
.BuyerLandingPage .new_product_inner {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  align-items: center;
  height: auto !important;
}
.BuyerLandingPage .new_product_main_right img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.BuyerLandingPage .new_product_inner h6 {
  font-size: 14px;
  font-weight: 600;
  background-color: var(--var-light-blue-color);
  color: var(--var-white-color);
  display: inline-block;
  padding: 10px;
}
.BuyerLandingPage .new_product_inner h2 {
  font-size: 32px;
  font-weight: 600;
  color: var(--var-primary-color);
  line-height: 40px;
}
.BuyerLandingPage .new_product_inner p {
  font-size: 16px;
  font-weight: 400;
  color: var(--var-primary-text-color);
}
.BuyerLandingPage .btn_orange {
  background-color: #183823;
}
.BuyerLandingPage .new_product_right.new_product_inner h6 {
  background-color: #EFD33D;
}
.BuyerLandingPage .new_product_right.new_product_inner h2 {
  color: var(--var-white-color);
}
.BuyerLandingPage .new_product_right.new_product_inner p {
  color: #ADB7BC;
}
.BuyerLandingPage .card-body.new_product_inner.new_product_right {
  padding: 26px 26px 0px 26px;
}
.BuyerLandingPage .new_product_img .offer_circle {
  top: 15px;
  right: 40px;
  font-size: 14px;
}
.BuyerLandingPage .new_product_img {
  position: relative;
  overflow: hidden;
}
.BuyerLandingPage .category_section img {
  width: 100%;
  border-radius: 10px;
}
.BuyerLandingPage .shop_categories {
  position: relative;
  padding: 0px 0px 45px 0px;
}
.BuyerLandingPage .category_section {
  position: relative;
}
.BuyerLandingPage .category_section_content {
  position: absolute;
  top: 20px;
  left: 20px;
}
.BuyerLandingPage .category_section_content h2 {
  font-size: 24px;
  font-weight: 700;
  color: var(--var-white-color);
  line-height: 32px;
  text-shadow: 1px 1px 7px black;
}
.BuyerLandingPage .category_section_content a {
  font-size: 18px;
  font-weight: 600;
  color: var(--var-white-color);
  text-decoration: none;
  text-shadow: 1px 1px 7px black;
}
.BuyerLandingPage .category_section_content a i {
  font-size: 14px;
  font-weight: 600;
}
.BuyerLandingPage .category_section_1 img {
  width: 100%;
  height: 480px;
  -o-object-fit: fill;
     object-fit: fill;
}

.category_section_2 img {
  width: 435px;
  height: 235px;
  -o-object-fit: cover;
     object-fit: cover;
}

.blog_section {
  position: relative;
  padding: 45px 0px;
  background: #EAEAEA;
}

.blog_headeing h2 {
  font-size: 32px;
  font-weight: 800;
  line-height: 40px;
  color: #191C1F;
}

.blog_inner {
  position: relative;
  padding: 25px;
  background: var(--var-white-color);
}

.blog_inner img {
  width: 100%;
  border-radius: 10px;
}

.blog_inner ul {
  list-style: none;
  padding-left: 0px;
  margin-bottom: 5px;
  margin-top: 10px;
}

.blog_inner ul li {
  font-size: 14px;
  color: var(--var-primary-text-color);
  font-weight: 400;
  display: inline-block;
  padding: 0px 10px 0px 0px;
}

.blog_inner ul li i {
  color: #FA8232;
  font-size: 18px;
  padding-right: 5px;
}

.blog_inner h5 {
  color: #191C1F;
  font-size: 18px;
  font-weight: 700;
}

.blog_inner p {
  color: #77878F;
  font-weight: 400;
  font-size: 16px;
}

.btn_white {
  color: #FA8232;
  background-color: var(--var-white-color);
  border: 1px #FA8232 solid;
  border-radius: 0px;
  font-weight: 600;
}

/* Bestsellers */
.bestsellers {
  position: relative;
  padding: 0px 0px 45px;
}

.bestsellers_product {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.bestsellers_product img {
  text-align: center;
  margin: 0 auto;
}

.bestsellers_heading {
  margin-bottom: 20px;
}

.bestsellers_heading h2 {
  font-size: 34px;
  font-weight: 700;
  line-height: 43.57px;
  color: var(--var-primary-color);
}

.bestsellers_heading h2 span a {
  font-size: 18px;
  font-weight: 400;
  line-height: 18.15px;
  color: #0d6efd;
  padding-left: 25px;
  text-decoration: underline;
}

/* Bestsellers */
/* Your Account */
.your_account {
  position: relative;
  padding: 20px 0px 45px;
}

.your_account_left {
  box-shadow: 8px 7px 9.3px 0px rgba(0, 0, 0, 0.0784313725);
  border: 1px solid #08AAE3;
  border-radius: 4px;
}

.your_account_left ul {
  list-style: none;
  padding-left: 0px;
}

.your_account_left ul li {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.your_account_left ul li a {
  text-decoration: none;
  color: #69757B;
  display: block;
  padding: 12px;
  transition-duration: 0.4s;
}

.your_account_left ul li a:hover {
  background: #08AAE3;
  color: var(--var-white-color);
  transition-duration: 0.4s;
}

.your_account_left ul li:nth-last-child(1) a {
  color: #E30808;
}

.your_account_left ul li:nth-last-child(1):hover a {
  background: #E30808;
  color: var(--var-white-color);
}

.your_account_left ul li a i {
  padding-right: 8px;
}

.your_account_inner {
  padding: 45px 15px 45px 15px;
  border: 1px var(--var-light-blue-color) solid;
  background: #F8F8F8;
  height: 100%;
  cursor: pointer;
  transition-duration: 0.5s;
}

.your_account_inner a {
  text-decoration: none;
}

.your_account_right.h-100 {
  padding: 25px;
}

.your_account_inner i {
  font-size: 56px;
  margin-bottom: 20px;
  color: var(--var-light-blue-color);
  transition-duration: 0.5s;
}

.your_account_inner h4 {
  font-size: 25px;
  font-weight: 700;
  line-height: 20px;
  color: var(--var-dark-color);
  transition-duration: 0.5s;
}

.your_account_inner p {
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 0px;
  color: #636363;
  transition-duration: 0.5s;
}

.your_account_inner:hover {
  background: var(--var-light-blue-color);
  transition-duration: 0.5s;
}

.your_account_inner:hover i {
  color: var(--var-white-color);
  transition-duration: 0.5s;
}

.your_account_inner:hover h4 {
  color: var(--var-white-color);
  transition-duration: 0.5s;
}

.your_account_inner:hover p {
  color: var(--var-white-color);
  transition-duration: 0.5s;
}

/* Your Account */
/* Your Orders */
.order_section {
  position: relative;
  padding: 45px 0px;
}

.order_section_inner {
  position: relative;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  padding: 20px 10px 20px 10px;
}

.track_order_section_1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px #ddd solid;
  padding-bottom: 20px;
}

.track_order_section_left a {
  border: 1px solid #08AAE3;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.59px;
  background: rgba(8, 170, 227, 0.0705882353);
  color: var(--var-heading-color);
  text-decoration: none;
  padding: 6px;
  border-radius: 14px;
  display: inline-block;
}

.track_order_section_left a span {
  color: #848484;
}

.track_order_section_right a {
  font-size: 18px;
  font-weight: 600;
  line-height: 25.1px;
  background: var(--var-light-blue-color);
  color: var(--var-white-color);
  border-radius: 14px;
  padding: 6px 12px;
  text-decoration: none;
  display: inline-block;
}

.track_order_section_right a i {
  padding-right: 6px;
}

.track_order_details {
  display: grid;
  grid-template-columns: 7% 24% 15% 15% 15% 20%;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: space-between;
  padding: 15px 10px 15px 10px;
  align-items: center;
}

.track_order_details_main p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--var-primary-color);
  margin-bottom: 0px;
}

.track_order_details_main h6 {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: var(--var-primary-color);
}

.track_order_details_main h6 a {
  text-decoration: none;
  color: #08aae3;
}

.track_order_details_main .btn_primary {
  border-radius: 19px;
  font-size: 14px;
  text-transform: none;
  padding: 6px 12px;
}

.track_order_details_main .cancel_order {
  border: 1px solid #08AAE3;
  font-size: 14px;
  font-weight: 500;
  line-height: 22.59px;
  background: rgba(8, 170, 227, 0.0705882353);
  color: var(--var-heading-color);
  text-decoration: none;
  padding: 6px 12px;
  border-radius: 19px;
}

/* Your Orders */
/* Order Details */
.quantity_number .btn.active {
  outline: transparent;
  border-color: transparent;
}

.quantity_number {
  border-radius: 4px !important;
}

.order_details .fa-plus {
  color: #a3a3a3;
  width: 30px;
  height: 30px;
  border: 1px #a3a3a3 solid;
  border-radius: 50%;
  line-height: 30px;
  text-align: center;
  font-size: 22px;
}

.order_details .fa-trash {
  color: #a3a3a3;
}

.order_details_content h5 {
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  color: var(--var-primary-text-color);
  text-transform: uppercase;
  border-top: 1px #ddd solid;
  padding: 10px 0px 10px 0px;
}

/* Order Details */
/* Cancel Order */
.cancel_order_heading {
  display: grid;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  grid-template-columns: 70% 30%;
}

.cancel_order_heading_left {
  position: relative;
}

.cancel_order_heading_left h2 {
  font-size: 30px;
  font-weight: 700;
  line-height: 24px;
  color: var(--var-dark-color);
}

.cancel_order_search {
  position: relative;
}

.cancel_order_search input {
  border: 1px #ddd solid;
  padding-left: 30px;
  width: 100%;
  cursor: pointer;
  border-radius: 4px;
}

.cancel_order_search i {
  position: absolute;
  left: 10px;
  top: 9px;
  color: #898C8C;
  font-size: 12px;
}

.cancel_order_section {
  display: grid;
  grid-template-columns: 7% 25% 20% 20% 20%;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: space-between;
  padding: 15px 10px 15px 10px;
  align-items: center;
}

.status_color {
  color: var(--var-light-blue-color) !important;
}

/* Cancel Order */
/* Return Order */
.return_order_form .return_order_form_inner {
  display: block;
  margin-bottom: 25px;
  float: left;
  width: 100%;
}

.return_order_form .return_order_form_inner span {
  width: 20%;
  float: left;
  font-size: 22px;
  font-weight: 600;
  line-height: 24px;
}

.return_order_form select {
  height: 44px;
  padding: 12px 16px 12px 16px;
  border-radius: 4px;
  border: 1px var(--var-light-blue-color) solid;
  width: 80%;
}

.return_order_form textarea {
  padding: 12px 16px 12px 16px;
  border-radius: 4px;
  border: 1px var(--var-light-blue-color) solid;
  width: 80%;
}

textarea {
  outline: none;
}

.return_order_form p span {
  width: 20%;
  float: left;
  font-size: 22px;
  font-weight: 600;
  line-height: 24px;
}

.return_order_form .custom-file {
  border-radius: 4px;
  border: 1px var(--var-light-blue-color) solid;
  width: 55%;
  display: block;
  float: left;
}

.return_order_form_inner label {
  font-size: 14px;
  width: 100%;
  text-align: center;
  padding: 30px 12px;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.37px;
}

.return_order_form_inner label i {
  font-size: 45px;
  display: block;
  color: var(--var-light-blue-color);
  margin-bottom: 10px;
}

.custom-file-input {
  cursor: pointer;
  display: none;
}

.return_order_form_inner a {
  background: linear-gradient(92.23deg, #08AAE3 0.56%, #199A8E 102.68%);
  display: inline-block;
  color: #fff;
  text-decoration: none;
  padding: 10px 70px;
  font-size: 24px;
  font-weight: 700;
  border-radius: 10px;
}

/* Return Order */
/* Buy It Again */
.buy_again_box {
  position: relative;
  background: #F2F0F0;
  padding: 15px;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  flex-direction: column;
}

.buy_again_box img {
  width: 100%;
}

.buy_again_box h4 {
  font-size: 18px;
  font-weight: 700;
  line-height: 21.78px;
  color: var(--var-dark-color);
}

.buy_again_box p {
  font-size: 14px;
  font-weight: 400;
  line-height: 15.06px;
  color: var(--var-dark-color);
  margin-top: 10px;
  margin-bottom: 5px;
}

.buy_again_box h6 a {
  font-size: 14px;
  font-weight: 400;
  line-height: 14.1px;
  color: var(--var-light-blue-color);
  text-decoration: none;
}

.buy_again_box ul {
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0px;
}

.buy_again_box ul li {
  display: inline-block;
  color: #FA8232;
  font-size: 15px;
  font-weight: 400;
}

.buy_again_box ul li:nth-child(1) {
  color: var(--var-dark-color);
}

.buy_again_box ul li:nth-last-child(1) {
  color: #085B8A;
}

/* Buy It Again */
/* Track Order */
.track_order_section {
  position: relative;
  padding: 45px 0px;
}

.track_order_heading h2 {
  font-size: 30px;
  font-weight: 700;
  line-height: 24px;
}

.track_order_box {
  display: flex;
  align-items: center;
  padding: 30px 10px 30px 10px;
  background: rgba(8, 170, 227, 0.1019607843);
  border: 1px solid #08AAE3;
  border-radius: 4px;
  justify-items: center;
  justify-content: space-between;
  margin-top: 35px;
}

.track_order_box_left h5 {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
}

.track_order_box_left p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0px;
  color: var(--var-primary-text-color);
}

.track_order_box_left p span i {
  font-size: 6px;
}

.track_order_box_left h2 {
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
  color: var(--var-light-blue-color);
  margin-bottom: 0px;
}

.track_order_box_inner p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--var-primary-text-color);
}

.track_order_box_inner p strong {
  color: var(--var-primary-color);
}

.hh-grayBox {
  background-color: #F8F8F8;
  margin-bottom: 20px;
  padding: 35px;
  margin-top: 20px;
}

.pt45 {
  padding-top: 45px;
}

.order-tracking {
  text-align: center;
  width: 24.33%;
  position: relative;
  display: block;
}

.order-tracking .is-complete1 {
  display: block;
  position: relative;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  border: 2px solid var(--var-light-blue-color);
  background-color: var(--var-light-blue-color) !important;
  margin: 0 auto;
  transition: background 0.25s linear;
  -webkit-transition: background 0.25s linear;
  z-index: 2;
}

.order-tracking .is-complete {
  display: block;
  position: relative;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  border: 2px solid var(--var-light-blue-color);
  background-color: var(--var-white-color);
  margin: 0 auto;
  transition: background 0.25s linear;
  -webkit-transition: background 0.25s linear;
  z-index: 2;
}

.order-tracking .is-complete:after {
  display: block;
  position: absolute;
  content: "";
  height: 14px;
  width: 7px;
  top: -2px;
  bottom: 0;
  left: 5px;
  margin: auto 0;
  border: 0px solid #AFAFAF;
  border-width: 0px 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
}

.order-tracking.completed .is-complete {
  border-color: #08AAE3;
  border-width: 0px;
  background-color: #08AAE3;
}

.order-tracking.completed .is-complete:after {
  border-color: #fff;
  border-width: 0px 3px 3px 0;
  width: 7px;
  left: 11px;
  opacity: 1;
}

.order-tracking p i {
  font-size: 30px;
  margin-bottom: 10px;
}

.order-tracking p {
  color: #A4A4A4;
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: 0;
  line-height: 20px;
  font-weight: 800;
}

.order-tracking p span {
  font-size: 14px;
}

.order-tracking.completed p {
  color: #000;
}

.order-tracking.completed p i {
  color: var(--var-light-blue-color);
}

.order-tracking::before {
  content: "";
  display: block;
  height: 7px;
  width: 100%;
  background-color: #848484;
  top: 12px;
  position: absolute;
  left: -51%;
  z-index: 0;
}

.order-tracking:first-child:before {
  display: none;
}

.order-tracking.completed:before {
  background-color: #848484;
}

/* Track Order */
/* Sub Category */
.sub_product_listing {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
}

.sub_category_heading h2 {
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  color: #363636;
}

.sub_category_heading p {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #5F6C72;
}

/* Sub Category */
/* Seller Feedback */
.seller_feedback.cancel_order_section {
  padding: 15px 50px 15px 70px;
}

.seller_feedback_main {
  position: relative;
  padding: 0px 0px 45px 0px;
}

.seller_feedback_inner h2 {
  font-size: 30px;
  font-weight: 700;
  line-height: 37.65px;
  color: #000;
}

.seller_feedback_inner p {
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  color: var(--var-primary-color);
}

.seller_feedback_inner textarea {
  padding: 10px;
  width: 100%;
  height: 170px;
  resize: none;
  background-color: #F0F0F0;
  border-radius: 5px;
  border: 1px solid grey;
}

.seller_feedback_inner ul {
  list-style: none;
  padding-left: 0px;
  margin-bottom: 5px;
  margin-top: 15px;
}

.seller_feedback_inner ul li {
  display: inline-block;
  color: #F8A401;
  font-size: 22px;
}

.seller_feedback_inner ul li:nth-child(1) {
  color: var(--var-primary-color);
  font-size: 20px;
  font-weight: 700;
}

.seller_feedback_inner ul li:nth-last-child(1) {
  color: var(--var-primary-color);
}

.seller_feedback_inner h4 {
  font-size: 24px;
  font-weight: 700;
  line-height: 30.12px;
  color: var(--var-primary-color);
  margin-top: 10px;
}

.seller_feedback_inner .form-check {
  margin-bottom: 10px;
}

.seller_feedback_inner input {
  font-size: 20px;
  font-weight: 400;
  line-height: 25.1px;
  color: var(--var-primary-color);
}

.seller_feedback_inner a {
  background: linear-gradient(92.23deg, #08AAE3 0.56%, #199A8E 102.68%);
  display: inline-block;
  color: #fff;
  text-decoration: none;
  padding: 10px 70px;
  font-size: 24px;
  font-weight: 700;
  border-radius: 10px;
  margin: 25px 0px;
}

.review_popup h4 {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  color: #000;
}

.review_popup img {
  width: 45%;
}

/* Profile Section */
.profile_section {
  position: relative;
  padding: 45px 0px;
}

.profile_heading h2 {
  font-size: 30px;
  font-weight: 700;
  line-height: 24px;
  color: #000;
}

.profile_man_box {
  position: relative;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  padding: 20px 100px 20px 100px;
}

.profile_inner_box_section {
  padding: 30px 10px 30px 10px;
  background: rgba(8, 170, 227, 0.0392156863);
  border: 1px solid #08AAE3;
  border-radius: 12px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: stretch;
  justify-content: space-between;
  align-items: center;
}

.profile_inner_box h6 {
  position: relative;
  font-size: 24px;
  font-weight: 700;
  line-height: 32.16px;
  color: var(--var-dark-color);
  padding-left: 65px;
}

.profile_inner_box h6 span {
  font-size: 20px;
  font-weight: 700;
  line-height: 30.12px;
  color: var(--var-dark-color);
}

.profile_inner_box h6 i {
  position: absolute;
  left: 0;
  top: 14px;
  font-size: 38px;
  color: #A8ACB1;
}

.active_profile_icon {
  color: var(--var-light-blue-color) !important;
}

.profile_inner_box a {
  border-radius: 19px;
  font-size: 14px;
  text-transform: none;
  padding: 6px 50px;
  float: right;
  text-decoration: none;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
  color: #848484;
  background: rgba(8, 170, 227, 0.0392156863);
  border: 1px solid rgba(8, 170, 227, 0.5019607843);
  transition: 0.4s;
}

.profile_inner_box a:hover {
  background-color: #08AAE3;
  color: var(--var-white-color);
  transition: 0.4s;
}

.profile_change_name p {
  font-size: 20px;
  font-weight: 300;
  line-height: 26px;
  color: var(--var-dark-color);
}

.profile_change_name label {
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  width: 100%;
}

.profile_change_name input {
  border: 1px solid #848484;
  width: 50%;
  border-radius: 2px;
  padding: 8px;
  margin: 10px 0px 20px 0px;
}

.profile_change_name a {
  border: 1px solid rgba(8, 170, 227, 0.5019607843);
  padding: 12px 24px 12px 24px;
  border-radius: 5px;
  text-decoration: none;
  display: inline-block;
  margin-top: 25px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
}

.iti__flag {
  display: none;
}

.change-primary-number {
  margin: 15px 0px 20px 0px;
}

.change-primary-number input {
  width: 100%;
  border: 1px solid #848484;
  border-radius: 2px;
  padding: 8px;
  margin: 10px 0px 20px 70px !important;
  padding-left: 5px !important;
}

.change-primary-number .iti--separate-dial-code .iti__selected-flag {
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid #848484;
}

.field-icon {
  float: right;
  margin-left: -24px;
  margin-top: -48px;
  position: relative;
  z-index: 2;
  padding-right: 5px;
  cursor: pointer;
}

.change_password_profile {
  position: relative;
}

.change_password_profile input {
  border: 1px solid #848484;
  width: 100%;
  border-radius: 2px;
  padding: 8px;
  margin: 10px 0px 30px 0px;
  color: #77878F;
  font-size: 14px;
}

.change_password_profile label {
  font-size: 22px;
  font-weight: 700;
  line-height: 20px;
  width: 100%;
}

.change_password_profile a {
  border: 1px solid rgba(8, 170, 227, 0.5019607843);
  padding: 12px 24px 12px 24px;
  border-radius: 5px;
  text-decoration: none;
  display: inline-block;
  margin-top: 15px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
}

.your_address {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 90px;
  grid-row-gap: 75px;
}

.your_address_box {
  box-shadow: 0px 0px 10.8px 3px rgba(0, 0, 0, 0.2509803922);
  text-align: center;
  padding: 35px;
  height: 100%;
}

.your_address_box i {
  width: 80px;
  height: 80px;
  line-height: 80px;
  border-radius: 50%;
  background: var(--var-light-blue-color);
  color: var(--var-white-color);
  font-size: 36px;
  margin-top: 20px;
}

.your_address_box h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.5px;
  color: var(--var-light-blue-color);
  margin-top: 20px;
}

.your_address_box_1 {
  box-shadow: 0px 0px 10.8px 3px rgba(0, 0, 0, 0.2509803922);
  padding: 35px;
  height: 100%;
}

.your_address_box_1 h4 {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.5px;
  color: var(--var-heading-color);
  position: relative;
  padding: 0px 0px 8px 60px;
}

.your_address_box_1 h4 img {
  position: absolute;
  left: 0px;
  border: 1px dotted var(--var-light-blue-color);
  width: 50px;
  height: 50px;
  padding: 5px;
  border-radius: 50%;
  top: -5px;
}

.your_address_box_1 p {
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  color: #9098B1;
}

.your_address_box_1 h6 {
  font-size: 18px;
  font-weight: 500;
  line-height: 32.4px;
  letter-spacing: 0.5px;
  color: #848484;
}

.address_edit {
  display: flex;
}

.address_edit_left a {
  background: linear-gradient(180deg, #08AAE3 0%, #199A8E 100%);
  color: var(--var-white-color);
  text-decoration: none;
  padding: 5px 16px;
  border-radius: 4px;
}

.address_edit_right a {
  color: #a3a3a3;
  padding-left: 15px;
}

.add_new_address {
  border: 1px solid rgba(8, 170, 227, 0.5019607843);
  background: rgba(8, 170, 227, 0.0392156863);
  position: relative;
  padding: 30px 20px 30px 20px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.add-new-address_btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.add_new_address_btn_left a {
  background: linear-gradient(180deg, #08AAE3 0%, #199A8E 100%);
  color: var(--var-white-color);
  text-decoration: none;
  padding: 16px 24px 16px 24px;
  border-radius: 4px;
  margin-right: 25px;
  display: inline-block;
}

.add_new_address_btn_right a {
  color: var(--var-heading-color);
  text-decoration: none;
  padding: 16px 24px 16px 24px;
  border-radius: 4px;
  background: rgba(8, 170, 227, 0.0784313725);
  border: 1px solid rgba(8, 170, 227, 0.5019607843);
  display: inline-block;
}

.add_new_address_right_field label {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: #191C1F;
  width: 100%;
  margin-bottom: 10px;
}

.add_new_address_right_field .form-control {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #929FA5;
  border: 1px solid #979797;
  border-radius: 2px;
  height: 44px;
  width: 100%;
  margin-bottom: 25px;
  padding-left: 10px;
}

.add_new_address_right_field select {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #929FA5;
  border: 1px solid #979797;
  border-radius: 2px;
  height: 44px;
  width: 100%;
  margin-bottom: 25px;
  padding-left: 10px;
}

/* Contact Us */
.contact_us_section {
  position: relative;
  box-shadow: 0px 0px 60px 30px rgba(0, 0, 0, 0.031372549);
  border-radius: 10px;
  padding: 5px;
  border: 1px rgba(217, 217, 217, 0.5803921569) solid;
  margin: 0px 30px 0px 30px;
}

.contact_us_section_left {
  border: 1px solid rgba(8, 170, 227, 0.5019607843);
  background: rgba(8, 170, 227, 0.1019607843);
  padding: 25px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.contact_us_section_left h4 {
  font-size: 28px;
  font-weight: 600;
  line-height: 35.14px;
  color: var(--var-primary-color);
  margin-bottom: 20px;
}

.contact_us_section_left p {
  font-size: 18px;
  font-weight: 400;
  line-height: 22.59px;
  margin-bottom: 35px;
}

.contact_us_section_left ul {
  list-style: none;
  padding-left: 0px;
  padding-bottom: 0px;
}

.contact_us_section_left ul li {
  font-size: 16px;
  font-weight: 400;
  line-height: 20.08px;
  color: var(--var-heading-color);
  position: relative;
  padding-left: 25px;
  margin-bottom: 35px;
}

.contact_us_section_left ul li i {
  color: #08AAE3;
  position: absolute;
  left: 0;
  top: 5px;
}

.contact_us_section_left .circle {
  background: url(../../../../public/assets/images/circle.png);
  position: absolute;
  bottom: 0px;
  width: 150px;
  height: 150px;
  right: 0;
  background-position: 0% 0%;
  background-repeat: no-repeat;
}

.social_media_icon {
  position: absolute;
  bottom: 20px;
  z-index: 9;
}

.social_media_icon ul {
  list-style: none;
  margin-bottom: 0px;
}

.social_media_icon ul li {
  display: inline-block;
  margin: 0px;
  color: #444444;
}

.social_media_icon ul li a {
  color: #444444;
}

.social_media_icon ul li:nth-child(1) {
  padding-left: 0px;
}

.social_media_icon ul li i {
  position: relative;
  color: #444444;
}

.radio_buttom_section {
  display: flex;
  justify-items: start;
}

.radio_buttom_section .form-check:nth-child(1) {
  padding-left: 20px;
}

.radio_buttom_section .form-check {
  padding-left: 40px;
}

.contact_us_section_right_field label {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: var(--var-dark-color);
  width: 100%;
}

.contact_us_section_right_field input {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 1px #ddd solid;
  width: 100%;
  margin-bottom: 25px;
}

.contact_us_section_right_field input::-moz-placeholder {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--var-dark-color);
}

.contact_us_section_right_field input::placeholder {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--var-dark-color);
}

.contact_us_section_right_field .message {
  margin: 30px 0px 20px 0px;
}

.contact_us_section_right_field textarea {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 1px #ddd solid;
  width: 100%;
  margin-bottom: 25px;
  height: 30px;
}

.contact_us_section_right_field textarea::-moz-placeholder {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #8D8D8D;
}

.contact_us_section_right_field textarea::placeholder {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #8D8D8D;
}

.contact_us_section_right {
  padding: 25px 20px 25px 15px;
}

.contact_us_section_right h2 {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: var(--var-dark-color);
}

.contact_us_section_right .form-check-input:checked {
  background-color: #222;
  border-color: #222;
  background-image: none;
}

.contact_us_section_right_field button {
  background: #08AAE3;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.1215686275);
  padding: 15px 48px 15px 48px;
  border-radius: 5px;
  color: var(--var-white-color);
  border: 0px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20.08px;
  margin: 20px 0px 60px 0px;
}

/* Faq */
.faq_section {
  padding-right: 50px;
  margin-bottom: 25px;
}

.faq_section .accordion-button {
  font-size: 20px;
  font-weight: 600;
  line-height: 29px;
}

.faq_section .accordion-item {
  border-left: 0;
  border-right: 0;
  margin-bottom: 15px;
  border-bottom: 0px;
}

.faq_section #headingOne .accordion-button.collapsed {
  border-top: 0px;
}

.faq_section .accordion-header .accordion-button.collapsed {
  border: 1px #ddd solid;
  border-radius: 4px;
}

.faq_section .accordion-collapse.collapse.show {
  box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.1215686275);
}

.faq_section .accordion-button:not(.collapsed) {
  background: #08AAE3;
  color: var(--var-white-color);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.faq_section .accordion-button:not(.collapsed)::after {
  background-image: url(../../../../public/assets/images/faq-1.png);
  transform: var(--bs-accordion-btn-icon-transform);
}

.faq_section .accordion-button::after {
  background-image: url(../../../../public/assets/images/faq-2.png);
}

.faq_section .accordion-button:focus {
  z-index: 3;
  border-color: transparent;
  outline: 0;
  box-shadow: none;
}

.faq_section .accordion-body p {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #475156;
}

.faq_section .accordion-body ul {
  padding-left: 15px;
}

.faq_section .accordion-body ul li {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--var-primary-text-color);
  margin-bottom: 12px;
}

.faq_right {
  border: 1px solid rgba(8, 170, 227, 0.5019607843);
  background: rgba(8, 170, 227, 0.0784313725);
  border-radius: 4px;
  padding: 20px;
}

.faq_right h2 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

.faq_right p {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #475156;
}

.faq_right input {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #929FA5;
  border: 1px solid #08AAE3;
  border-radius: 2px;
  height: 44px;
  width: 100%;
  margin-bottom: 25px;
  padding-left: 10px;
}

.faq_right textarea {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #929FA5;
  border: 1px solid #08AAE3;
  border-radius: 2px;
  height: 84px;
  width: 100%;
  margin-bottom: 25px;
  padding-left: 10px;
}

.faq_right button {
  background: #08AAE3;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.1215686275);
  padding: 15px 48px 15px 48px;
  border-radius: 5px;
  color: var(--var-white-color);
  border: 0px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20.08px;
  text-transform: uppercase;
}

/* Privacy Policy */
.privacy_policy_section {
  position: relative;
  padding: 45px 0px;
  background: url(../../../../public/assets/images/privacy_bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
}

.page_titile h2 {
  font-size: 50px;
  font-weight: 700;
  line-height: 43.85px;
  color: var(--var-white-color);
  text-align: center;
}

.page_titile p {
  font-size: 24px;
  font-weight: 400;
  line-height: 45.18px;
  text-align: center;
  color: var(--var-white-color);
}

.page_titile_img img {
  width: 70%;
}

.policy_content {
  position: relative;
  padding: 45px 0px;
  background: url(../../../../public/assets/images/policy_bg.png);
  background-repeat: no-repeat;
  background-position: center center;
}

.policy_content_inner h2 {
  font-size: 30px;
  font-weight: 800;
  line-height: 37.65px;
  color: var(--var-dark-color);
  margin-bottom: 20px;
}

.policy_content_inner ul {
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0px;
}

.policy_content_inner ul li {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
  background: #D9D9D9;
  font-size: 20px;
  font-weight: 400;
  line-height: 22px;
  color: var(--var-dark-color);
  margin-bottom: 25px;
  border-radius: 6px;
  position: relative;
  padding: 8px 0px 8px 35px;
}

.policy_content_inner ul li a {
  display: block;
  padding: 10px 12px;
  color: #000;
  text-decoration: none;
}

.policy_content_inner ul li a img {
  position: absolute;
  left: 10px;
  top: 17px;
}

.policy_content_inner ul li:hover {
  background: var(--var-light-blue-color);
}

.policy_content_inner ul li:hover a {
  color: var(--var-white-color);
}

.policy_content_inner ul li .policy_img2 {
  display: none;
}

.policy_content_inner ul li:hover .policy_img1 {
  display: none;
}

.policy_content_inner ul li:hover .policy_img2 {
  display: block;
}

.policy_content_inner p {
  font-size: 18px;
  font-weight: 400;
  line-height: 29px;
  color: var(--var-dark-color);
}

@media (max-width: 1299px) {
  .new_product_inner h2 {
    font-size: 20px;
    line-height: 26px;
  }
  .new_product_inner p {
    font-size: 10px;
  }
  .btn_primary {
    padding: 7px 10px;
    font-size: 9px;
  }
  .new_product_inner h6 {
    font-size: 10px;
  }
  .new_product_img {
    position: absolute;
    bottom: 0px;
    height: 80%;
    right: -10px;
    width: 58%;
  }
  .trending_product p {
    font-size: 12px;
  }
  .trending_product h5 {
    font-size: 22px;
  }
  .policy_content_inner ul li {
    font-size: 16px;
  }
  .trending_product img {
    -o-object-fit: contain;
       object-fit: contain;
  }
}
@media (max-width: 1082px) and (min-width: 992px) {
  .your_address_box h2 {
    font-size: 20px;
  }
  .your_address_box_1 h4 {
    font-size: 15px;
  }
  .contact_us_section_left h4 {
    font-size: 22px;
  }
  .contact_us_section_left p {
    font-size: 16px;
    line-height: 20.59px;
  }
  .radio_buttom_section .form-check-label {
    font-size: 12px;
  }
}
@media (max-width: 1100px) {
  .product_section_black_inner_left h2 {
    font-size: 20px;
    line-height: 25px;
  }
  .new_product_inner_main img {
    -o-object-fit: contain;
       object-fit: contain;
  }
}
@media (max-width: 992px) {
  .new_product_inner h2 {
    font-size: 15px;
    line-height: 20px;
  }
  .category_section_content h2 {
    font-size: 15px;
    line-height: 26px;
  }
  .blog_inner ul li {
    font-size: 8px;
  }
  .blog_inner h5 {
    font-size: 12px;
  }
  .trending_product h5 {
    font-size: 18px;
  }
  /* Your Order */
  .track_order_details {
    grid-template-columns: 7% 23% 14% 14% 14% 21%;
  }
  .track_order_details_main .cancel_order {
    font-size: 10px;
  }
  .track_order_details_main .btn_primary {
    font-size: 10px;
  }
  /* Your Order */
  /* Return Order */
  .return_order_form .return_order_form_inner span {
    width: 22%;
    font-size: 16px;
  }
  .return_order_form select {
    width: 65%;
  }
  .return_order_form textarea {
    width: 65%;
  }
  .return_order_form .custom-file {
    width: 65%;
  }
  /* By It Again */
  .buy_again_box h4 {
    font-size: 15px;
  }
  .buy_again_box ul li {
    font-size: 12px;
  }
  /* Profile */
  .profile_man_box {
    padding: 20px 25px 20px 25px;
  }
  .profile_inner_box h6 {
    font-size: 14px;
    line-height: 16px;
    padding-left: 32px;
  }
  .profile_inner_box h6 span {
    font-size: 12px;
  }
  .profile_inner_box h6 i {
    top: 12px;
    font-size: 24px;
  }
  .profile_inner_box a {
    font-size: 12px;
    padding: 6px 28px;
  }
  .profile_inner_box a.active {
    font-size: 12px;
    padding: 6px 28px;
  }
  /* Your Address */
  .your_address {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 23px;
    grid-row-gap: 40px;
  }
  /* Contact Address */
  .radio_buttom_section .form-check-label {
    font-size: 15px;
  }
  .contact_us_section_left h4 {
    font-size: 15px;
  }
  .contact_us_section_left p {
    font-size: 14px;
  }
  .radio_buttom_section .form-check {
    padding-left: 30px;
  }
  .contact_us_section_left ul li {
    font-size: 14px;
  }
  .contact_us_section_left .circle {
    width: 125px;
    height: 125px;
  }
  .faq_right h2 {
    font-size: 15px;
  }
  .faq_right p {
    font-size: 14px;
  }
  .faq_right button {
    padding: 15px 25px 15px 25px;
  }
  .policy_content_inner ul li a {
    padding: 10px 7px;
  }
  .trending_now {
    position: relative;
    padding: 45px 0px 10px;
  }
  .trending_product h2 strong {
    color: var(--var-green-color);
    font-size: 14px;
    font-weight: 700;
  }
  .bestsellers_heading h2 {
    font-size: 22px;
  }
  .category_section_content a {
    font-size: 15px;
  }
  .offer_circle {
    width: 70px;
    height: 70px;
    line-height: 70px;
    font-size: 12px;
  }
  .product_category_main_left h2 {
    font-size: 26px;
  }
  .product_category_main_right img {
    width: 355px;
    height: 255px;
  }
}
@media (max-width: 767px) {
  .home_slider img {
    height: 255px;
  }
  .category_section_2 img {
    width: 100%;
  }
  .product_category {
    margin-top: -45px;
  }
  .product_category_main_left h6 {
    font-size: 8px;
  }
  .product_category_main_left p {
    font-size: 12px;
  }
  .product_category_main_left h2 {
    font-size: 20px;
  }
  .btn_primary {
    padding: 8px 9px;
    font-size: 9px;
  }
  .offer_circle {
    width: 60px;
    height: 60px;
    line-height: 60px;
    font-size: 14px;
  }
  .product_section_black_inner_left h2 {
    font-size: 16px;
    line-height: 22px;
  }
  .trending_product h5 {
    font-size: 16px;
  }
  .trending_product h2 {
    font-size: 17px;
  }
  .trending_product p {
    font-size: 9px;
  }
  .new_product {
    padding: 15px 0px;
  }
  .new_product_inner h2 {
    font-size: 16px;
    line-height: 20px;
  }
  /* Your Order */
  .track_order_details {
    grid-template-columns: 7% 16% 15% 15% 15% 23%;
  }
  .track_order_details_main p {
    font-size: 10px;
    line-height: 14px;
  }
  .track_order_details_main h6 {
    font-size: 10px;
  }
  .track_order_details_main .cancel_order {
    font-size: 7px;
  }
  .track_order_details_main .btn_primary {
    font-size: 7px;
  }
  /* Your Order */
  /* Seller Feedback */
  .seller_feedback.cancel_order_section {
    padding: 15px 10px 15px 10px;
  }
  /* Profile */
  .profile_change_name input {
    width: 70%;
  }
  .change-primary-number input {
    width: 80%;
  }
  .radio_buttom_section .form-check-label {
    font-size: 13px;
  }
  .faq_section {
    padding-right: 0px;
  }
  .policy_content_inner ul li {
    font-size: 10px;
    padding: 8px 0px 8px 22px;
  }
  .policy_content_inner ul li a img {
    left: 5px;
    width: 20px;
    top: 10px;
  }
  .policy_content_inner ul li a {
    padding: 2px 7px;
  }
  .page_titile h2 {
    font-size: 40px;
  }
  .page_titile p {
    font-size: 20px;
  }
  .your_account_right.h-100 {
    padding: 0px 0px 0px 0px;
  }
  .sub_product_listing {
    grid-template-columns: 1fr 1fr;
  }
  .slider__item {
    padding: 0px 15px 0px 0px;
  }
}
@media (max-width: 640px) {
  .secondary_header_right {
    justify-content: space-between;
  }
  .slider .slick-list {
    padding: 0 10% 0 0% !important;
  }
  .slider__item {
    margin-right: 20px;
    margin-left: 20px;
    padding: 0px 20px 0px 20px;
  }
  .headeing h1 {
    font-size: 28px;
    text-align: center;
  }
  .your_address {
    grid-template-columns: 1fr;
    grid-column-gap: 23px;
    grid-row-gap: 40px;
  }
  .profile_change_name a {
    margin-top: 15px;
  }
  .change_password_profile input {
    margin: 10px 0px 20px 0px;
  }
  .contact_us_section {
    margin: 0px 10px 0px 10px;
  }
  .radio_buttom_section {
    display: block;
    float: left;
  }
  .radio_buttom_section .form-check {
    width: 50%;
    float: left;
    display: flex;
    align-items: center;
  }
  .radio_buttom_section .form-check:nth-child(1) {
    padding-left: 30px;
  }
  .contact_us_section_right_field button {
    margin: 10px 0px 10px 0px;
  }
  .radio_buttom_section .form-check-label {
    font-size: 10px;
    padding-left: 4px;
  }
  .faq_section .accordion-button {
    font-size: 15px;
  }
  .page_titile h2 {
    font-size: 11px;
    line-height: initial;
  }
  .page_titile p {
    font-size: 10px;
    line-height: 12px;
  }
  .page_titile_img img {
    width: 100%;
  }
  .cancel_order_heading {
    grid-template-columns: 45% 55%;
  }
}
@media (max-width: 575px) {
  .breadcrumb_section_content ul li {
    font-size: 10px !important;
  }
  .product_section_black {
    margin-top: 20px;
  }
  .product_category {
    margin-top: -32px;
  }
  .sell_product_inner {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: stretch;
    text-align: center;
  }
  .shop_categories {
    padding: 45px 0px 45px 0px;
  }
  .bestsellers_heading h2 {
    font-size: 20px;
    line-height: 25px;
  }
  .track_order_section_left a {
    font-size: 9px;
    display: inline;
  }
  .track_order_section_right a {
    font-size: 10px;
    display: inline;
  }
  /* Your Account */
  .your_account_right.h-100 {
    padding: 0px;
    margin-top: 25px;
  }
  .track_order_details {
    grid-template-columns: 1fr 1fr;
  }
  .track_order_details_main.text-center {
    text-align: left !important;
  }
  .track_order_details_main.text-end {
    text-align: left !important;
  }
  .track_order_details_main p {
    font-size: 10px;
    line-height: 16px;
  }
  .track_order_details_main h6 {
    font-size: 10px;
    line-height: 16px;
    margin-bottom: 0px;
  }
  /* Order Details */
  .order_details .fa-plus {
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
  }
  /* Cancel Order */
  .cancel_order_section {
    grid-template-columns: 1fr 1fr;
  }
  .cancel_order_heading_left h2 {
    font-size: 14px;
    margin-bottom: 0px;
  }
  /* Return Order */
  .return_order_form .return_order_form_inner span {
    width: 100%;
    float: left;
    font-size: 16px;
    margin-bottom: 10px;
  }
  .return_order_form select {
    width: 100%;
  }
  .return_order_form textarea {
    width: 100%;
  }
  .return_order_form .custom-file {
    width: 100%;
  }
  /* By It Again */
  .cancel_order_heading.buy_it_again {
    grid-template-columns: auto;
  }
  /* Track Order */
  .track_order_box {
    justify-items: center;
    flex-direction: column;
    justify-content: inherit;
    align-items: baseline;
  }
  .order-tracking p i {
    font-size: 18px;
    margin-bottom: 0px;
  }
  .order-tracking p span {
    font-size: 8px;
    line-height: 12px;
  }
  .order-tracking p {
    line-height: 12px;
  }
  /* Track Order */
  /* Sub Category */
  /* Profile */
  .profile_inner_box h6 i {
    top: 8px;
    font-size: 24px;
  }
  .profile_heading h2 {
    font-size: 22px;
  }
  .category_section_1 img {
    width: 100%;
  }
  .see_more_btn {
    text-align: left;
    display: block;
  }
  .home_slider img {
    height: 180px;
  }
  .home_slider .carousel-control-next,
  .home_slider .carousel-control-prev {
    top: 50%;
  }
}
@media (max-width: 320px) {
  .track_order_details_main .cancel_order {
    font-size: 6px;
  }
  .track_order_section_left a {
    font-size: 8px;
    display: inline;
  }
  .track_order_section_right a {
    font-size: 8px;
    display: inline;
  }
}
/* Add this CSS for the zoom effect on the whole card */
.card.trending_product {
  transition: transform 0.3s ease;
  /* Smooth transition for the zoom effect */
}

.card.trending_product:hover {
  transform: scale(1.02);
  /* Scale the entire card to 105% on hover */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  /* Optional: Adds a shadow effect on hover */
  z-index: 99;
}

.slick-dots {
  display: flex !important;
  /* Align dots horizontally */
  justify-content: center;
  /* Center the dots horizontally */
  list-style: none;
  /* Remove default list styling */
  padding: 0;
  /* Remove padding */
  margin-top: 15px;
  /* Add some space above the dots */
}

.slick-dots li {
  margin: 0 5px;
  /* Add space between dots */
}

.slick-dots li button {
  font-size: 0;
  /* Remove button text */
  width: 10px;
  /* Set the width of dots */
  height: 10px;
  /* Set the height of dots */
  background-color: #ddd;
  /* Default dot color */
  border-radius: 50%;
  /* Make dots circular */
  border: none;
  /* Remove border */
  cursor: pointer;
  /* Show pointer cursor */
}

.slick-dots li.slick-active button {
  background-color: #000;
  /* Active dot color */
}

.sell_product .sell_product_inner .sell_product_inner_main .sell_product_inner_main_section {
  position: relative;
  overflow: hidden;
}
.sell_product .sell_product_inner .sell_product_inner_main .sell_product_inner_main_section img {
  width: 100%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}
.sell_product .sell_product_inner .sell_product_inner_main .sell_product_inner_main_section:hover img {
  transform: scale(1.05);
  box-shadow: 0px -3px 4px 0px rgba(0, 0, 0, 0.2);
}

.pos-abso {
  position: absolute;
}

.left {
  left: 88%;
}

.pos-rel {
  position: relative;
}

.ht {
  min-height: 180px;
  max-height: -moz-max-content;
  max-height: max-content;
}/*# sourceMappingURL=BuyerLandingPage.css.map */