:root {
  --var-background-color: #141920;
  --var-light-background-color: #252F3D;
  --var-gray-color: #F3F3F3;
  --var-white-color: #fff;
  --var-light-blue-color: #08AAE3;
  --var-primary-color: #18191A;
  --var-primary-text-color: #475156;
  --var-heading-color: #272727;
  --var-green-color: #0A8200;
  --var-dark-color: #000000;
}

.buyer-your-address .cancel_order_heading {
  display: grid;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  grid-template-columns: 70% 30%;
}
.buyer-your-address .cancel_order_heading_left {
  position: relative;
}
.buyer-your-address .cancel_order_heading_left h2 {
  font-size: 30px;
  font-weight: 700;
  line-height: 24px;
  color: var(--var-dark-color);
}
.buyer-your-address .cancel_order_search {
  position: relative;
}
.buyer-your-address .cancel_order_search input {
  border: 1px #ddd solid;
  padding-left: 30px;
  width: 100%;
  cursor: pointer;
  border-radius: 4px;
  height: 37px;
}
.buyer-your-address .cancel_order_search i {
  position: absolute;
  left: 10px;
  top: 13px;
  color: #898C8C;
  font-size: 12px;
}
.buyer-your-address .your_address {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 90px;
  grid-row-gap: 75px;
}
.buyer-your-address .your_address_box {
  box-shadow: 0px 0px 10.8px 3px rgba(0, 0, 0, 0.2509803922);
  text-align: center;
  padding: 35px;
  height: 100%;
  border-radius: 8px;
}
.buyer-your-address .your_address_box i {
  width: 80px;
  height: 80px;
  line-height: 80px;
  border-radius: 50%;
  background: var(--var-light-blue-color);
  color: var(--var-white-color);
  font-size: 36px;
  margin-top: 20px;
}
.buyer-your-address .your_address_box h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.5px;
  color: var(--var-light-blue-color);
  margin-top: 20px;
}
.buyer-your-address .your_address_box p {
  margin-bottom: 0px;
}
.buyer-your-address .your_address_box_1 {
  box-shadow: 0px 0px 10.8px 3px rgba(0, 0, 0, 0.2509803922);
  padding: 35px;
  height: 100%;
  border-radius: 8px;
}
.buyer-your-address .your_address_box_1 h4 {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.5px;
  color: var(--var-heading-color);
  position: relative;
  padding: 0px 0px 8px 60px;
}
.buyer-your-address .your_address_box_1 h4 img {
  position: absolute;
  left: 0px;
  border: 1px dotted var(--var-light-blue-color);
  width: 50px;
  height: 50px;
  padding: 5px;
  border-radius: 50%;
  top: -5px;
}
.buyer-your-address .your_address_box_1 p {
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  color: #9098B1;
  line-break: anywhere;
}
.buyer-your-address .your_address_box_1 h6 {
  font-size: 18px;
  font-weight: 500;
  line-height: 32.4px;
  letter-spacing: 0.5px;
  color: #848484;
  line-break: anywhere;
}
.buyer-your-address .address_edit {
  display: flex;
  margin-top: 25px;
}
.buyer-your-address .address_edit_left a {
  background: #183823;
  color: var(--var-white-color);
  text-decoration: none;
  padding: 5px 16px;
  border-radius: 4px;
}
.buyer-your-address .address_edit_right a {
  color: #da4343;
  padding-left: 15px;
}
.buyer-your-address .order_section {
  position: relative;
  padding: 45px 0px;
}
.buyer-your-address .order_section_inner {
  position: relative;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  padding: 20px 10px 20px 10px;
}

@media (max-width: 992px) {
  .buyer-your-address .your_address {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 23px;
    grid-row-gap: 40px;
  }
  .buyer-your-address .cancel_order_search input {
    font-size: 9px;
  }
}
@media (max-width: 640px) {
  .buyer-your-address .cancel_order_heading {
    grid-template-columns: 45% 55%;
  }
  .buyer-your-address .cancel_order_heading_left h2 {
    font-size: 14px;
    margin-bottom: 0px;
  }
  .buyer-your-address .your_address {
    grid-template-columns: 1fr;
    grid-column-gap: 23px;
    grid-row-gap: 40px;
  }
}/*# sourceMappingURL=BuyerYourAddressContent.css.map */