.order-it-again {
  /* Your Orders */
  /* Your Orders */
  /* Buy It Again */
  /* Buy It Again */
  /* Cancel Order */
  /* Cancel Order */
}
.order-it-again .order_section {
  position: relative;
  padding: 45px 0px;
}
.order-it-again .order_section_inner {
  position: relative;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  padding: 20px 10px 20px 10px;
}
.order-it-again .track_order_section_1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px #ddd solid;
  padding-bottom: 20px;
}
.order-it-again .track_order_section_left a {
  border: 1px solid #08AAE3;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.59px;
  background: rgba(8, 170, 227, 0.0705882353);
  color: var(--var-heading-color);
  text-decoration: none;
  padding: 6px;
  border-radius: 14px;
  display: inline-block;
}
.order-it-again .track_order_section_left a span {
  color: #848484;
}
.order-it-again .track_order_section_right a {
  font-size: 18px;
  font-weight: 600;
  line-height: 25.1px;
  background: var(--var-light-blue-color);
  color: var(--var-white-color);
  border-radius: 14px;
  padding: 6px 12px;
  text-decoration: none;
  display: inline-block;
}
.order-it-again .track_order_section_right a i {
  padding-right: 6px;
}
.order-it-again .track_order_details {
  display: grid;
  grid-template-columns: 7% 24% 15% 15% 15% 20%;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: space-between;
  padding: 15px 10px 15px 10px;
  align-items: center;
}
.order-it-again .track_order_details_main p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--var-primary-color);
  margin-bottom: 0px;
}
.order-it-again .track_order_details_main h6 {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: var(--var-primary-color);
}
.order-it-again .track_order_details_main h6 a {
  text-decoration: none;
  color: #08aae3;
}
.order-it-again .track_order_details_main .btn_primary {
  border-radius: 19px;
  font-size: 14px;
  text-transform: none;
  padding: 6px 12px;
}
.order-it-again .track_order_details_main .cancel_order {
  border: 1px solid #08AAE3;
  font-size: 14px;
  font-weight: 500;
  line-height: 22.59px;
  background: rgba(8, 170, 227, 0.0705882353);
  color: var(--var-heading-color);
  text-decoration: none;
  padding: 6px 12px;
  border-radius: 19px;
  transition-duration: 0.4s;
}
.order-it-again .track_order_details_main .cancel_order:hover {
  background: var(--var-light-blue-color);
  color: var(--var-white-color);
  transition-duration: 0.4s;
}
.order-it-again .breadcrumb_section {
  position: relative;
  background-color: #EDEDED;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
  padding: 10px 0px;
}
.order-it-again .breadcrumb_section_content h4 {
  font-size: 14px;
  color: var(--var-primary-color);
}
.order-it-again .breadcrumb_section_content ul {
  list-style-type: none;
  padding-left: 0px;
  margin-bottom: 0px;
}
.order-it-again .breadcrumb_section_content ul li {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  padding: 0px 4px 0px 0px;
}
.order-it-again .breadcrumb_section_content ul li a {
  text-decoration: none;
  color: #5F6C72;
}
.order-it-again .breadcrumb_section_content ul li:nth-child(1) a {
  text-decoration: none;
  color: #5F6C72 !important;
}
.order-it-again .breadcrumb_section_content ul li:nth-last-child(1) a {
  text-decoration: none;
  color: var(--var-light-blue-color);
}
.order-it-again .buy_again_box {
  position: relative;
  background: #F2F0F0;
  padding: 15px;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  flex-direction: column;
  justify-content: space-around;
}
.order-it-again .buy_again_box a {
  text-decoration: none;
}
.order-it-again .buy_again_box img {
  width: 100%;
  height: 215px;
  -o-object-fit: contain;
     object-fit: contain;
}
.order-it-again .buy_again_box h4 {
  font-size: 18px;
  font-weight: 700;
  line-height: 21.78px;
  color: var(--var-dark-color);
}
.order-it-again .buy_again_box p {
  font-size: 14px;
  font-weight: 400;
  line-height: 15.06px;
  color: var(--var-dark-color);
  margin-top: 10px;
  margin-bottom: 5px;
}
.order-it-again .buy_again_box h6 a {
  font-size: 14px;
  font-weight: 400;
  line-height: 14.1px;
  color: var(--var-light-blue-color);
  text-decoration: none;
}
.order-it-again .buy_again_box ul {
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0px;
}
.order-it-again .buy_again_box ul li {
  display: inline-block;
  color: #FA8232;
  font-size: 15px;
  font-weight: 400;
  padding: 0px 4px 0px 0px;
}
.order-it-again .buy_again_box ul li:nth-child(1) {
  color: var(--var-dark-color);
}
.order-it-again .buy_again_box ul li:nth-last-child(1) {
  color: #085B8A;
}
.order-it-again .cancel_order_heading {
  display: grid;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  grid-template-columns: 70% 30%;
}
.order-it-again .cancel_order_heading_left {
  position: relative;
}
.order-it-again .cancel_order_heading_left h2 {
  font-size: 30px;
  font-weight: 700;
  line-height: 24px;
  color: var(--var-dark-color);
}
.order-it-again .cancel_order_search {
  position: relative;
}
.order-it-again .cancel_order_search input {
  border: 1px #ddd solid;
  padding-left: 30px;
  width: 100%;
  cursor: pointer;
  border-radius: 4px;
  height: 37px;
}
.order-it-again .cancel_order_search i {
  position: absolute;
  left: 10px;
  top: 13px;
  color: #898C8C;
  font-size: 12px;
}
.order-it-again .cancel_order_section {
  display: grid;
  grid-template-columns: 7% 25% 20% 20% 20%;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: space-between;
  padding: 15px 10px 15px 10px;
  align-items: center;
}
.order-it-again .status_color {
  color: var(--var-light-blue-color) !important;
}
.order-it-again .see_more_btn {
  color: #183823;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
}
.order-it-again .see_more_btn a {
  text-decoration: none;
}

@media (max-width: 992px) {
  .order-it-again .buy_again_box h4 {
    font-size: 15px;
  }
  .order-it-again .buy_again_box ul li {
    font-size: 12px;
  }
  .order-it-again .cancel_order_search input {
    font-size: 9px;
  }
}
@media (max-width: 640px) {
  .order-it-again .cancel_order_heading_left h2 {
    font-size: 14px;
    margin-bottom: 0px;
  }
  .order-it-again .cancel_order_heading {
    grid-template-columns: 45% 55%;
  }
}
@media (max-width: 575px) {
  .order-it-again .cancel_order_heading_left h2 {
    font-size: 14px;
    margin-bottom: 0px;
  }
  .order-it-again .cancel_order_heading {
    grid-template-columns: 45% 55%;
  }
  .order-it-again .buy_again_box {
    display: block;
  }
}/*# sourceMappingURL=BuyerOrderItAgainContent.css.map */