.track-order {
  /* Your Orders */
  /* Track Order */
  /* Track Order */
  /* Your Orders */
  /* Buy It Again */
  /* Buy It Again */
  /* Cancel Order */
  /* Cancel Order */
}
.track-order .order_section {
  position: relative;
  padding: 45px 0px;
}
.track-order .order_section_inner {
  position: relative;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  padding: 20px 10px 20px 10px;
}
.track-order .track_order_section {
  position: relative;
  padding: 45px 0px;
}
.track-order .track_order_heading h2 {
  font-size: 30px;
  font-weight: 700;
  line-height: 24px;
}
.track-order .track_order_box {
  display: flex;
  align-items: center;
  padding: 30px 10px 30px 10px;
  background: rgba(8, 170, 227, 0.1019607843);
  border: 1px solid #08AAE3;
  border-radius: 4px;
  justify-items: center;
  justify-content: space-between;
  margin-top: 35px;
}
.track-order .track_order_box_left h5 {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
}
.track-order .track_order_box_left p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0px;
  color: var(--var-primary-text-color);
}
.track-order .track_order_box_left p span i {
  font-size: 6px;
}
.track-order .track_order_box_left h2 {
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
  color: var(--var-light-blue-color);
  margin-bottom: 0px;
}
.track-order .track_order_box_inner p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--var-primary-text-color);
}
.track-order .track_order_box_inner p strong {
  color: var(--var-primary-color);
}
.track-order .hh-grayBox {
  background-color: #F8F8F8;
  margin-bottom: 20px;
  padding: 35px;
  margin-top: 20px;
}
.track-order .pt45 {
  padding-top: 45px;
}
.track-order .order-tracking {
  text-align: center;
  width: 16.33%;
  position: relative;
  display: block;
}
.track-order .order-tracking .is-complete1 {
  display: block;
  position: relative;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  border: 2px solid var(--var-light-blue-color);
  background-color: var(--var-light-blue-color) !important;
  margin: 0 auto;
  transition: background 0.25s linear;
  -webkit-transition: background 0.25s linear;
  z-index: 2;
}
.track-order .order-tracking .is-complete {
  display: block;
  position: relative;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  border: 2px solid var(--var-light-blue-color);
  background-color: var(--var-white-color);
  margin: 0 auto;
  transition: background 0.25s linear;
  -webkit-transition: background 0.25s linear;
  z-index: 2;
}
.track-order .order-tracking .is-complete:after {
  display: block;
  position: absolute;
  content: "";
  height: 14px;
  width: 7px;
  top: -2px;
  bottom: 0;
  left: 5px;
  margin: auto 0;
  border: 0px solid #AFAFAF;
  border-width: 0px 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
}
.track-order .order-tracking.completed .is-complete {
  border-color: #08AAE3;
  border-width: 0px;
  background-color: #08AAE3;
}
.track-order .order-tracking.completed .is-complete:after {
  border-color: #fff;
  border-width: 0px 3px 3px 0;
  width: 7px;
  left: 11px;
  opacity: 1;
}
.track-order .order-tracking p i {
  font-size: 30px;
  margin-bottom: 10px;
}
.track-order .order-tracking p {
  color: #A4A4A4;
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: 0;
  line-height: 20px;
  font-weight: 800;
}
.track-order .order-tracking p span {
  font-size: 12px;
  line-height: 17px;
}
.track-order .order-tracking.completed p {
  color: #000;
}
.track-order .order-tracking.completed p i {
  color: var(--var-light-blue-color);
}
.track-order .order-tracking::before {
  content: "";
  display: block;
  height: 7px;
  width: 100%;
  background-color: #848484;
  top: 12px;
  position: absolute;
  left: -51%;
  z-index: 0;
}
.track-order .order-tracking:first-child:before {
  display: none;
}
.track-order .order-tracking.completed:before {
  background-color: #00ade4;
}
.track-order .breadcrumb_section {
  position: relative;
  background-color: #EDEDED;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
  padding: 10px 0px;
}
.track-order .breadcrumb_section_content h4 {
  font-size: 14px;
  color: var(--var-primary-color);
}
.track-order .breadcrumb_section_content ul {
  list-style-type: none;
  padding-left: 0px;
  margin-bottom: 0px;
}
.track-order .breadcrumb_section_content ul li {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  padding: 0px 4px 0px 0px;
}
.track-order .breadcrumb_section_content ul li a {
  text-decoration: none;
  color: #5F6C72;
}
.track-order .breadcrumb_section_content ul li:nth-child(1) a {
  text-decoration: none;
  color: #5F6C72 !important;
}
.track-order .breadcrumb_section_content ul li:nth-last-child(1) a {
  text-decoration: none;
  color: var(--var-light-blue-color);
}
.track-order .buy_again_box {
  position: relative;
  background: #F2F0F0;
  padding: 15px;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  flex-direction: column;
}
.track-order .buy_again_box a {
  text-decoration: none;
}
.track-order .buy_again_box img {
  width: 100%;
}
.track-order .buy_again_box h4 {
  font-size: 18px;
  font-weight: 700;
  line-height: 21.78px;
  color: var(--var-dark-color);
}
.track-order .buy_again_box p {
  font-size: 14px;
  font-weight: 400;
  line-height: 15.06px;
  color: var(--var-dark-color);
  margin-top: 10px;
  margin-bottom: 5px;
}
.track-order .buy_again_box h6 a {
  font-size: 14px;
  font-weight: 400;
  line-height: 14.1px;
  color: var(--var-light-blue-color);
  text-decoration: none;
}
.track-order .buy_again_box ul {
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0px;
}
.track-order .buy_again_box ul li {
  display: inline-block;
  color: #FA8232;
  font-size: 15px;
  font-weight: 400;
}
.track-order .buy_again_box ul li:nth-child(1) {
  color: var(--var-dark-color);
}
.track-order .buy_again_box ul li:nth-last-child(1) {
  color: #085B8A;
}
.track-order .cancel_order_heading {
  display: grid;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  grid-template-columns: 70% 30%;
}
.track-order .cancel_order_heading_left {
  position: relative;
}
.track-order .cancel_order_heading_left h2 {
  font-size: 30px;
  font-weight: 700;
  line-height: 24px;
  color: var(--var-dark-color);
}
.track-order .cancel_order_search {
  position: relative;
}
.track-order .cancel_order_search input {
  border: 1px #ddd solid;
  padding-left: 30px;
  width: 100%;
  cursor: pointer;
  border-radius: 4px;
}
.track-order .cancel_order_search i {
  position: absolute;
  left: 10px;
  top: 9px;
  color: #898C8C;
  font-size: 12px;
}
.track-order .cancel_order_section {
  display: grid;
  grid-template-columns: 7% 25% 20% 20% 20%;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: space-between;
  padding: 15px 10px 15px 10px;
  align-items: center;
}
.track-order .status_color {
  color: var(--var-light-blue-color) !important;
}

@media (max-width: 575px) {
  .track-order .track_order_box {
    justify-items: center;
    flex-direction: column;
    justify-content: inherit;
    align-items: baseline;
  }
  .track-order .order-tracking p span {
    font-size: 8px;
    line-height: 12px;
  }
  .track-order .order-tracking p i {
    font-size: 18px;
    margin-bottom: 0px;
  }
  .track-order .breadcrumb_section_content ul li {
    font-size: 10px !important;
  }
  .track-order .order-tracking .is-complete {
    height: 22px;
    width: 22px;
  }
  .track-order .order-tracking::before {
    height: 6px;
    top: 9px;
  }
  .track-order .order-tracking p {
    font-size: 18px;
    margin-top: 15px;
    line-height: 9px;
  }
}/*# sourceMappingURL=BuyerTrackOrderContent.css.map */