:root {
  --var-background-color: #141920;
  --var-light-background-color: #252F3D;
  --var-gray-color: #F3F3F3;
  --var-white-color: #fff;
  --var-light-blue-color: #183823;
  --var-primary-color: #18191A;
  --var-primary-text-color: #475156;
  --var-heading-color: #272727;
  --var-green-color: #0A8200;
  --var-dark-color: #000000;
}

.buyer-write-review {
  /* footer-sec */
  /* modal */
  /* Slider wrapper */
  /* Slick Slider Item */
  /* Ensure padding is consistent */
  /* Custom Slider */
  /* Slick Slider Navigation */
  /* Ensure Slick Track and Slides are displayed correctly */
  /* Prevent user selection on the slider */
  /* Optional: adjust padding/margins as needed */
  /* Optional: to always show navigation buttons */
  /* Features */
}
.buyer-write-review body {
  font-family: "Mulish", sans-serif;
}
.buyer-write-review .review-heading-sec {
  background-color: #EDEDED;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
}
.buyer-write-review .review-heading-sec h6 {
  font-weight: 700;
  padding: 10px 22px;
}
.buyer-write-review .shop-latest {
  margin-top: 13%;
}
.buyer-write-review .shop-latest h2 {
  font-weight: 900;
  padding-right: 10px;
}
.buyer-write-review .star-sec span {
  color: grey;
  padding: 5px;
}
.buyer-write-review .phone-img {
  position: relative;
}
.buyer-write-review .phone-img img {
  width: 260px;
  height: 300px;
  -o-object-fit: contain;
     object-fit: contain;
}
.buyer-write-review .offer_circle {
  position: absolute;
  top: -10px;
  z-index: 9;
  right: 0;
  width: 65px;
  height: 65px;
  background: #F8A401;
  border-radius: 50%;
  color: var(--var-white-color);
  text-align: center;
  line-height: 65px;
  font-size: 20px;
  font-weight: 600;
}
.buyer-write-review .star-phone-sec {
  margin: 20px;
  background-color: #F9F9F9;
  padding: 20px;
  border-radius: 24px;
}
.buyer-write-review .iphone-star-sec p {
  color: #221F1F;
}
.buyer-write-review .iphone-star-sec h6 {
  font-weight: 700;
}
.buyer-write-review .create-review h3 {
  font-weight: 700;
}
.buyer-write-review .iphone-star-sec h4 {
  font-size: 18px;
  font-weight: 700;
}
.buyer-write-review .slick-slide {
  padding: 0px 30px 0px 0px;
}
.buyer-write-review .review-card {
  border: 1.34px solid #E7EAEC;
  border-radius: 6px;
  height: 100%;
}
.buyer-write-review .review_startr img {
  margin: 0px 0px 0px 4px;
}
.buyer-write-review .icon-star img {
  width: 80px;
  height: 80px;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: top;
     object-position: top;
}
.buyer-write-review .add-headline h3 {
  font-weight: 700;
  font-size: 24px;
}
.buyer-write-review .review_para {
  height: 100px;
  overflow-y: scroll;
}
.buyer-write-review .review_para::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}
.buyer-write-review .review_para::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}
.buyer-write-review .review_para::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ccc;
}
.buyer-write-review .add-headline input {
  padding: 5px 20px 45px 15px;
  border: 1px solid grey;
  background-color: #F0F0F0;
  border-radius: 5px;
  width: 50%;
}
.buyer-write-review .return-review textarea {
  padding: 10px;
  width: 50%;
  height: 170px;
  resize: none;
  background-color: #F0F0F0;
  border-radius: 5px;
  border: 1px solid grey;
}
.buyer-write-review .return-review h3 {
  font-weight: 700;
  font-size: 24px;
}
.buyer-write-review .rating-review h3 {
  font-weight: 700;
  font-size: 20px;
}
.buyer-write-review .rating-img svg {
  width: 30px;
  height: 30px;
}
.buyer-write-review .rating-img img {
  padding-left: 4px;
  width: 25px;
}
.buyer-write-review .submit-btn button {
  margin-bottom: 8%;
  padding: 10px 80px;
  border: none;
  background: #183823;
  color: #fff;
  font-weight: 600;
  border-radius: 10px;
  width: auto;
}
.buyer-write-review .footer-sec {
  background-color: #00071B;
  color: #fff;
}
.buyer-write-review .logo-sec img {
  margin-left: 6%;
  margin-bottom: 2%;
}
.buyer-write-review .search-sec {
  text-align: left;
  position: relative;
}
.buyer-write-review .search-sec input {
  background: transparent;
  border: 1px solid #fff;
  border-radius: 2px;
  padding: 2px 7px;
  color: #fff;
  padding-left: 30px;
}
.buyer-write-review .mail-box-sec {
  position: absolute;
  left: 5px;
}
.buyer-write-review .footer-menu h6 {
  font-size: 18px;
}
.buyer-write-review .footer-menu ul {
  list-style: none;
  padding: 0;
}
.buyer-write-review .footer-menu ul li {
  font-size: 14px;
  padding-top: 8px;
}
.buyer-write-review .media-icons img {
  padding-left: 15px;
}
.buyer-write-review .thank-you h4 {
  font-weight: 700;
}
.buyer-write-review .order-image img {
  height: 200px;
}
.buyer-write-review .thank-you button {
  border: none;
  padding: 8px 16px;
  border-radius: 10px;
  color: #fff;
  background: linear-gradient(92.83deg, #08AAE3 -74.66%, #FF9811 343.14%);
}
.buyer-write-review .modal-dialog {
  box-shadow: 0px 0px 5.5px 5px rgba(0, 0, 0, 0.2509803922);
  overflow: hidden;
}
.buyer-write-review .review_button a {
  background: #183823;
  color: #fff;
  text-decoration: none;
  padding: 7px 30px;
  display: inline-block;
  border-radius: 10px;
  margin-top: 10px;
  font-size: 18px;
}
.buyer-write-review .slider {
  width: 100%;
  overflow: hidden;
  /* Ensure items are inline-flex for horizontal display */
}
.buyer-write-review .slider__item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 0px 30px 0px 0px;
}
.buyer-write-review .custom-slider {
  width: 100%;
  margin: auto;
}
.buyer-write-review .slick-prev,
.buyer-write-review .slick-next {
  position: absolute;
  line-height: 0;
  top: 50%;
  width: 30px;
  height: 30px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  border-radius: 50px;
  background: #043e46;
  display: none !important;
  /* Remove this if you want to display arrows */
}
.buyer-write-review .slick-next {
  right: -30px;
}
.buyer-write-review .slick-prev {
  left: -30px;
}
.buyer-write-review .slick-next:before {
  content: ">";
  font-size: 1.2em;
  font-weight: 1000;
  padding-left: 12px;
  color: white;
}
.buyer-write-review .slick-prev:before {
  content: "<";
  font-size: 1.2em;
  font-weight: 1000;
  padding-left: 9px;
  color: white;
}
.buyer-write-review .slick-slider {
  margin: 0 -15px;
}
.buyer-write-review .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: inherit;
  margin-right: inherit;
}
.buyer-write-review .slick-slide {
  display: inline-block;
  float: none !important;
  /* Ensures no float styling affects it */
  height: auto !important;
  /* Ensures auto height */
}
.buyer-write-review .slick-slider {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.buyer-write-review .slider .slick-list {
  padding: 0 10% 0 15px !important;
}
.buyer-write-review .slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.buyer-write-review .slick-prev,
.buyer-write-review .slick-next {
  display: block !important;
}
.buyer-write-review .features_section {
  position: relative;
  width: 100%;
  padding: 45px 0px;
}
.buyer-write-review .features_section_inner h2 {
  font-size: 32px;
  font-weight: 700;
  line-height: 24px;
  color: var(--var-primary-color);
}
.buyer-write-review .features_section_inner ul {
  list-style: none;
  padding-left: 0px;
  margin-top: 15px;
}
.buyer-write-review .features_section_inner ul li {
  position: relative;
  padding-left: 30px;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 7px;
}
.buyer-write-review .features_section_inner ul li i {
  position: absolute;
  top: 4px;
  left: 0px;
  color: var(--var-light-blue-color);
}
.buyer-write-review .features_section_table h2 {
  font-size: 32px;
  font-weight: 700;
  line-height: 40.16px;
  color: #191C1F;
}
.buyer-write-review .features_section_table table {
  width: 100%;
  background-color: #E8E8E8;
}
.buyer-write-review .features_section_table table th {
  font-size: 25px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: var(--var-primary-color);
  padding: 12px;
}
.buyer-write-review .features_section_table table tbody tr {
  border: 0.5px solid var(--var-primary-color);
}
.buyer-write-review .features_section_table table tbody tr td:nth-child(1) {
  background-color: var(--var-gray-color);
  background-color: #D9D9D9;
  width: 30%;
}
.buyer-write-review .features_section_table table td strong {
  color: var(--var-primary-color);
}
.buyer-write-review .features_section_table table td b {
  color: var(--var-primary-color);
  font-weight: 700;
  font-size: 24px;
}
.buyer-write-review .table_padding td {
  padding-bottom: 20px !important;
}
.buyer-write-review .features_section_table table td {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  color: var(--var-primary-color);
  padding: 5px 5px 5px 15px;
}

@media (max-width: 992px) {
  .buyer-write-review .iphone-star-sec h4 {
    font-size: 14px;
    padding-right: 2px;
  }
  .buyer-write-review .iphone-star-sec span {
    font-size: 12px;
  }
}
@media (max-width: 767px) {
  .buyer-write-review .star-phone-sec {
    margin: 0px;
  }
  .buyer-write-review .add-headline input {
    width: 100%;
  }
  .buyer-write-review .return-review textarea {
    width: 100%;
  }
  .buyer-write-review .review_startr img {
    margin: 0px 0px 0px 4px;
    width: 20px;
  }
  .buyer-write-review .return-review.py-4 {
    padding-top: 0px !important;
  }
  .buyer-write-review .slider .slick-list {
    padding: 0 10% 0 20px !important;
  }
}/*# sourceMappingURL=BuyerWriteReviewContent.css.map */