.createaccount .login .fields-section {
  background-color: transparent;
  padding-left: 15%;
  padding-right: 15%;
}
.createaccount .create-account-sec {
  border: 1px solid #E4E7E9;
  border-radius: 4px;
  box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.1215686275);
  padding: 30px;
}
.createaccount .welcome-back-sec h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 22.59px;
  color: #062A41;
}
.createaccount .welcome-back-sec img {
  width: 130px;
}
.createaccount .welcome-back-sec h1 {
  font-size: 36px;
  font-weight: 800;
  line-height: 60.24px;
  color: #062a41;
  margin-top: 10px;
}
.createaccount .login .welcome-back-sec p {
  color: #062A41;
  font-weight: 700;
}
.createaccount .create-account-sec {
  padding-top: 20px;
}
.createaccount .create-account-sec {
  border: 1px solid #E4E7E9;
  border-radius: 4px;
  box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.1215686275);
  padding: 30px;
}
.createaccount .pass-email-field {
  position: relative;
}
.createaccount .login .form-section .pass-email-field label {
  padding: 10px 0px;
  color: #062A41;
  font-weight: 600;
}
.createaccount .login .form-section .pass-email-field input {
  width: 100%;
  padding: 10px;
  border: 1px solid #D0D5DD;
  border-radius: 8px;
  background: transparent;
}
.createaccount .forget-pass {
  padding-top: 15px;
}
.createaccount .forget-pass .are-you-agree p {
  text-align: left;
  padding-left: 10px;
}
.createaccount .forget-pass .are-you-agree p span {
  color: #08AAE3;
}
.createaccount .forget-pass p a {
  color: #08AAE3;
}
.createaccount .create-account-sec a {
  color: #08AAE3;
}
.createaccount .submit-btn button {
  border: none;
  width: 100%;
  padding: 10px;
  background: #183823;
  color: #fff;
  font-size: 17px;
  font-weight: 600;
  border-radius: 10px;
  margin-top: 25px;
}
.createaccount .or-sec {
  position: relative;
}
.createaccount .or-sec p {
  padding: 4px;
  background-color: #fff;
  color: grey;
  position: absolute;
  top: -18px;
  left: 47%;
  margin: 0;
}
.createaccount .create-account-sec a {
  color: #08AAE3;
}
.createaccount .submit-btn-google button {
  border: 1px solid #D0D5DD !important;
  text-align: left !important;
  width: 100% !important;
  padding: 10px !important;
  color: #000 !important;
  font-size: 17px !important;
  border-radius: 10px !important;
  background: transparent !important;
  margin-top: 25px !important;
  box-shadow: none !important;
}
.createaccount .submit-btn-google span {
  padding-left: 26%;
}
.createaccount .apple_icon i {
  font-size: 20px;
  padding-right: 5px;
}
.createaccount .submit-btn-apple button {
  border: 1px solid #D0D5DD;
  background: transparent;
  text-align: left;
  width: 100%;
  padding: 10px;
  color: #000;
  font-size: 17px;
  border-radius: 10px;
  margin-top: 25px;
}
.createaccount .submit-btn-apple span {
  padding-left: 26%;
}
.createaccount .login-banner-sec {
  height: 100%;
}
.createaccount .login-banner-sec img {
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.createaccount .password_input {
  position: relative;
}
.createaccount .password_input i {
  position: absolute;
  top: 15px;
  right: 10px;
}
.createaccount .create_your_account p {
  font-size: 16px;
  font-weight: 700;
  line-height: 20.08px;
  color: #000;
  margin-top: 20px;
}
.createaccount .create_your_account p a {
  text-decoration: none;
  color: #08AAE3;
}

@media only screen and (min-width: 767px) and (max-width: 1250px) {
  .createaccount .login .fields-section {
    padding-left: 1%;
    padding-right: 1%;
  }
}
@media (max-width: 992px) {
  .createaccount .login .px-5 {
    padding: 15px !important;
  }
  .createaccount .login .fields-section {
    background-color: transparent;
    margin-top: 0;
    padding-block: 50px;
    padding-left: 0%;
    padding-right: 0%;
  }
  .createaccount .welcome-back-sec h1 {
    font-size: 30px;
    line-height: 40px;
    text-align: center;
  }
  .createaccount .welcome-back-sec p {
    text-align: center;
  }
}
@media (max-width: 767px) {
  .createaccount .login .px-5 {
    padding: 15px !important;
  }
  .createaccount .login .fields-section {
    background-color: transparent;
    margin-top: 0;
    padding-block: 50px;
    padding-left: 0%;
    padding-right: 0%;
  }
  .createaccount .welcome-back-sec h1 {
    font-size: 30px;
    line-height: 40px;
  }
  .createaccount .submit-btn-google span {
    padding-left: 5%;
  }
  .createaccount .submit-btn-apple span {
    padding-left: 5%;
  }
  .createaccount .submit-btn-google .d-flex {
    display: flex !important;
    justify-content: center;
  }
  .createaccount .submit-btn-apple .d-flex {
    display: flex !important;
    justify-content: center;
  }
}
@media only screen and (max-width: 670px) {
  .createaccount .login .fields-section {
    padding-left: 1%;
    padding-right: 1%;
  }
}
@media (max-width: 640px) {
  .createaccount .login-banner-sec img {
    display: none;
  }
  .createaccount .welcome-back-sec h1 {
    margin-top: 8px;
  }
}/*# sourceMappingURL=CreateAccount.css.map */