@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body{
	color:black;
    font-family: 'Poppins', sans-serif;
    margin: 0;
    box-sizing: border-box;
    padding: 0;
}
.no-padding {
    padding: 0;
}
h1, h2, h3, h4, h5, h6{
    font-family: 'Poppins', sans-serif;
}
:root {
    --main-color: #FF8901;
    --secondary-color: #FF8901;
    --color-3: #183822;
    --color-4: rgba(24, 56, 35, 1);    
    --color-5: rgba(40, 116, 240, 1);
  }
a{
    text-decoration: none;
}

/* ==============main head end============== */
.padding-b-t-50{
padding: 50px 0;
}
.padding-b-50{
    padding-bottom: 50px; 
}
.padding-t-50{
    padding-top: 50px; 
}
/* ==============main head start============== */
.header_padd_20 {
    padding: 0px 0;
}

.bg-primary-black {
    background: var(--color-3);
}

.but_start_header {
    display: inline-block;
    border-radius: 6px;
    border: 2px solid #ffffff;
    background: var(--color-4);
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    padding: 10px 12px;
    font-size: 15px;
    letter-spacing: 1px;
}

.but_start_header:hover {
    border: 2px solid var(--secondary-color);
    background: var(--secondary-color);
    color:#fff;
}

.logo_main img {
    width: 130px;
}

.header_padd_20 {
    padding: 0px 0;
}

/* ==============main head end============== */
/* ============banner================ */
.banner_images img {
    width: 100%;
    max-height: 450px;
    padding: 0 5vw;
}

.aboutus_content_banner h2 {
    font-size: 40px;
    font-weight: 700;
    color: #141414;
    font-family: 'Poppins', sans-serif;
    padding-bottom: 30px;
}

.aboutus_content_banner p {
    padding-top: 10px;
    padding-bottom: 30px;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    color: #565656;
}

.color_bazar {
    color: var(--main-color);
}

.main_banner_button a {
    display: inline-block;
    border-radius: 10px;
    text-transform: uppercase;
    font-weight: 600;
    padding: 10px;
    font-size: 15px;
    letter-spacing: 1px;
}

.Start-color {
    border: 1px solid var(--color-4);
    background: var(--color-4);
    color: #fff;
    margin-right: 10px;
}

.more_color {
    border: 2px solid #DADADA;
    background: #DADADA;
    color: var(--color-3);
}

.more_color:hover,
.Start-color:hover {
    background: var(--secondary-color);
    color: #fff;
    border: 1px solid var(--secondary-color);
}

.aboutus_content_banner {
    padding: 0 8vw 0 0;
}

/* ============banner================ */
/* =========Why Seller============== */
.why_three-col-wrap {
    text-align: left;
    padding: 10px 12px 15px 10px;
    border-radius: 20px;
    z-index: 1;
    background: #fff;
    border: 1px solid #fff;
    min-height: 220px;
    margin: 10px 0;
}

.why_three-col-wrap:hover {
    border: 1px solid #aad3ef;
    background: #fff;
}

.why_three-col-wrap img {
    width: 170px;
    height: 110px;
}

.book_area_why h4 {
    font-weight: 600;
    color: #141414;
    margin: 0 0 10px;
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
}

.book_area_why p {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    color: #565656;
    text-align: justify;
    margin: 0;
}

.main_heading_sec h3 {
    font-size: 30px;
    font-weight: bold;
    color: #141414;
    font-family: 'Poppins', sans-serif;
    padding-bottom: 20px;
}

/* =========Why Seller============== */
/* ==========How to Sell===================== */

.Step_three-col-wrap img {
    width: 70px;
    padding-bottom: 10px;
}

.book_area_Sell h5 {
    font-size: 15px;
    color: var(--secondary-color);
}

.book_area_Sell h6 {
    font-size: 15px;
    color: var(--color-3);
}

.field-icon {
    color: rgba(6, 42, 65, 1);
    padding-top: 10px;
    padding-top: 7px;
}

/* ==========How to Sell===================== */
/* ===========Seller Success Stories========== */

.seller_succes_bg {
    background: var(--color-4);
    position: relative;
}

.Seller_heading_sec {
    font-size: 30px;
    font-weight: bold;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    padding-bottom: 20px;
}

.team_images_sec img {
    width: 100%;
}

.book_image_icon img {
    width: 20px !important;
}

.book_image_icon {
    padding: 20px 0;
}

.head_slider_team h2 {
    font-weight: 600;
    color: #fff;
    margin: 0 0 10px;
    font-family: 'Poppins', sans-serif;
    text-align: justify;
    font-size: 18px;
}

.img_slider_team {
    padding: 20px 0;
    display: flex;
    align-items: center;
}

.img_section img {
    border-radius: 100%;
    width: 40px !important;
    height: 40px !important;
}

.text_section {
    padding-left: 10px;
}

.text_section h3 {
    font-size: 18px;
    margin-bottom: 0;
    font-weight: bold;
    color: #fff;
}

.text_section p {
    font-size: 16px;
    margin-bottom: 0;
    font-weight: normal;
    color: #fff;
}

.head_slider_team {
    padding: 26px 0;
}

.main_detail_team {
    padding: 0 26vw 0 0;
}

.main_v_slider .slider-counter {
    text-align: right;
    color: #fff;
    font-size: 17px;
    padding-right: 34px;
    position: absolute;
    right: 130px;
}

.slider-main_team .owl-nav {
    position: absolute;
    top: -67px;
    right: 0;
    height: 33px;
    background: transparent;
    font-size: 18px;
    outline: none;
    cursor: pointer;
}

.slider-main_team .owl-nav .owl-prev {
    border-radius: 50%;
    font-size: 27px !important;
    line-height: 30px !important;
    box-shadow: 0 0 5px rgb(0 0 0 / 27%);
    left: -70px;
    position: relative;
    width: 20px;
    height: 20px;
}

.slider-main_team .owl-nav .owl-next {
    border-radius: 50%;
    font-size: 27px !important;
    line-height: 30px !important;
    box-shadow: 0 0 5px rgb(0 0 0 / 27%);
    position: relative;
    width: 20px;
    height: 20px;
}

.main_Seller_new {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 15px 0;

}

/* ===========Seller Success Stories========== */
/* ========footer=============== */
.bg_footer_sec {
    background: var(--color-4);
    padding: 10px 0;
}

.main_footer_logo img {
    width: 120px;
}

.copy_right_main a {
    font-size: 14px;
    color: #fff;
}

.main_listing_footer ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.main_listing_footer ul li {
    padding: 0 10px;
}

.main_listing_footer ul li a {
    color: #fff;
    font-size: 15px;
}

.copy_right_main {
    text-align: right;
}

.main_images_section {
    margin: 14px 0;
    display: flex;
    align-items: baseline;
}

.main_images_section span {
    padding-right: 10px;
}

.Start-color-sellers {
    color: #000;
    font-weight: normal;
    font-size: 15px;
}

//hide slick slider arrows
// .slick-arrow {
//     display: none !important;
// } 
.slick-next {
    right: 125px;
}

.slick-prev {
    right: 210px;
    left: auto;
}
.slick-prev, .slick-next {
    background: transparent;
}

.slick-prev:before {
    padding-left: 0px;
    content: "";
    background-image: url(../../../../public/assets/images/seller_images/left.png);
    display: block;
    width: 35px;
    height: 36px;
}

.slick-next:before {
    padding-left: 0px;
    content: "";
    background-image: url(../../../../public/assets/images/seller_images/right.png);
    display: block;
    width: 35px;
    height: 36px;
}

.slick-prev,
.slick-next {
    top: 14%;
}

@media (max-width:992px) {

    .slick-prev,
    .slick-next {
        top: 7%;
    }
}

@media (max-width:767px) {

    .slick-prev,
    .slick-next {
        top: 8%;
    }
}

@media (max-width:575px) {

    .slick-prev,
    .slick-next {
        top: 15%;
    }

    .main_Seller_new {
        margin-top: 40px;
    }

    .main_footer_logo {
        text-align: center;
        margin-bottom: 10px;
    }

    .copy_right_main {
        text-align: center;
    }
}

@media (max-width:500px) {

    .slick-prev,
    .slick-next {
        top: 16%;
    }

}


/* ==============Faq======================= */
.privacy_policy_section {
    position: relative;
    padding: 45px 0px;
    background: url("../../../../public/assets/images/seller_images/privacy_bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
}

.page_titile h2 {
    font-size: 50px;
    font-weight: 700;
    line-height: 43.85px;
    color: #fff;
    text-align: center;
}

.page_titile h2 {
    font-size: 50px;
    font-weight: 700;
    line-height: 43.85px;
    color: #fff;
    text-align: center;
}

.page_titile p {
    font-size: 24px;
    font-weight: 400;
    line-height: 45.18px;
    text-align: center;
    color: #fff;
}

.page_titile_img img {
    width: 70%;
}

.policy_content {
    position: relative;
    padding: 45px 0px;
    // background: url("../../../../public/assets/images/seller_images/privacy_bg.png");
    background-repeat: no-repeat;
    background-position: center center;
}

.policy_content_inner h2 {
    font-size: 30px;
    font-weight: 800;
    line-height: 37.65px;
    color: var(--var-dark-color);
    margin-bottom: 20px;
}

.policy_content_inner ul {
    list-style: none;
    padding-left: 0px;
    margin-bottom: 0px;
}

.policy_content_inner ul li {
    box-shadow: 0px 4px 4px 0px #00000040;
    background: #D9D9D9;
    font-size: 20px;
    font-weight: 400;
    line-height: 22px;
    color: var(--var-dark-color);
    margin-bottom: 25px;
    border-radius: 6px;
    position: relative;
    padding: 8px 0px 8px 35px;
}

.policy_content_inner ul li a {
    display: block;
    padding: 10px 12px;
    color: #000;
    text-decoration: none;
}

.policy_content_inner ul li a img {
    position: absolute;
    left: 10px;
    top: 17px;
}

.policy_content_inner ul li:hover {
    background: #183822;
}

.policy_content_inner ul li:hover a {
    color: #fff;
}

.policy_content_inner ul li .policy_img2 {
    display: none;
}

.policy_content_inner ul li:hover .policy_img1 {
    display: none;
}

.policy_content_inner ul li:hover .policy_img2 {
    display: block;
}

.policy_content_inner p {
    font-size: 18px;
    font-weight: 400;
    line-height: 29px;
    color: var(--var-dark-color);
}

.breadcrumb_section {
    position: relative;
    background-color: #EDEDED;
    box-shadow: 0px 4px 4px 0px #00000040;
    padding: 10px 0px;
}

.breadcrumb_section_content ul {
    list-style-type: none;
    padding-left: 0px;
    margin-bottom: 0px;
}

.breadcrumb_section_content ul li {
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
}

.breadcrumb_section_content ul li:nth-child(1) a {
    text-decoration: none;
    color: #5F6C72 !important;
}

.breadcrumb_section_content ul li:nth-last-child(1) a {
    text-decoration: none;
    color: #183823;
}

.profile_section {
    position: relative;
    padding: 45px 0px;
}

.profile_heading h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    color: #000;
}

/* Faq */
.faq_section {
    padding-right: 50px;
    margin-bottom: 25px;
}

.faq_section .accordion-button {
    font-size: 16px;
    font-weight: 500;
    line-height: 29px;
}

.faq_section .accordion-item {
    border-left: 0;
    border-right: 0;
    margin-bottom: 15px;
    border-bottom: 0px;
}

.faq_section #headingOne .accordion-button.collapsed {
    border-top: 0px;
}

.faq_section .accordion-header .accordion-button.collapsed {
    border: 1px #ddd solid;
    border-radius: 4px;
}

.faq_section .accordion-collapse.collapse.show {
    box-shadow: 0px 8px 40px 0px #0000001F;
}

.faq_section .accordion-button:not(.collapsed) {
    background: #183823;
    color: #fff;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.faq_section .accordion-button:not(.collapsed)::after {
    background-image: url("../../../../public/assets/images/seller_images/faq-1.png");
    transform: var(--bs-accordion-btn-icon-transform);
}

.faq_section .accordion-button::after {
    background-image: url("../../../../public/assets/images/seller_images/faq-2.png");
}

.faq_section .accordion-button:focus {
    z-index: 3;
    border-color: transparent;
    outline: 0;
    box-shadow: none;
}

.faq_section .accordion-body p {
    font-size: 15px;
    font-weight: 500;
    line-height: 27px;
    color: #475156;
}

.faq_section .accordion-body ul {
    padding-left: 15px;
}

.faq_section .accordion-body ul li {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--var-primary-text-color);
    margin-bottom: 12px;
}

.faq_right {
    border: 1px solid #18382380;
    background: #18382314;
    border-radius: 4px;
    padding: 20px;
}

.faq_right h2 {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;

}

.faq_right p {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #475156;
}

.faq_right input {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #929FA5;
    border: 1px solid #183823;
    border-radius: 2px;
    height: 44px;
    width: 100%;
    margin-bottom: 25px;
    padding-left: 10px;
}

.faq_right textarea {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #929FA5;
    border: 1px solid #183823;
    border-radius: 2px;
    height: 84px;
    width: 100%;
    margin-bottom: 25px;
    padding-left: 10px;
}

.faq_right button {
    background: #183823;
    box-shadow: 0px 0px 14px 0px #0000001F;
    padding: 15px 48px 15px 48px;
    border-radius: 5px;
    color: #fff;
    border: 0px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20.08px;
    text-transform: uppercase;
}