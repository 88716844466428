:root {
    --var-background-color: #141920;
    --var-light-background-color: #252F3D;
    --var-gray-color: #F3F3F3;
    --var-white-color: #fff;
    --var-light-blue-color: #183823;
    --var-primary-color: #18191A;
    --var-primary-text-color: #475156;
    --var-heading-color: #272727;
    --var-green-color: #0A8200;
    --var-dark-color: #000000;
}

.buyer-account {

    /* Your Account */
    .your_account {
        position: relative;
        padding: 60px 0px 35px;
    }

    .your_account_left {
        box-shadow: 8px 7px 9.3px 0px #00000014;
        border: 1px solid #183823;
        border-radius: 4px;
    }

    .your_account_left ul {
        list-style: none;
        padding-left: 0px;
    }

    .your_account_left ul li {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
    }

    .your_account_left ul li a {
        text-decoration: none;
        color: #69757B;
        display: block;
        padding: 12px;
        transition-duration: 0.4s;
    }

    .your_account_left ul li a:hover {
        background: #183823;
        color: var(--var-white-color);
        transition-duration: 0.4s;
    }

    .your_account_left ul li:nth-last-child(1) a {
        color: #E30808;
    }

    .your_account_left ul li:nth-last-child(1):hover a {
        background: #E30808;
        color: var(--var-white-color);
    }

    .your_account_left ul li a i {
        padding-right: 8px;
    }

    .your_account_inner {
        padding: 45px 15px 45px 15px;
        border: 1px var(--var-light-blue-color) solid;
        background: #F8F8F8;
        height: 100%;
        cursor: pointer;
        transition-duration: 0.5s;
    }

    .your_account_inner a {
        text-decoration: none;
    }

   .your_account_right.h-100 {
        padding: 0px 0px 25px 25px;
    }

    .your_account_inner i {
        font-size: 56px;
        margin-bottom: 20px;
        color: var(--var-light-blue-color);
        transition-duration: 0.5s;
    }

    .your_account_inner h4 {
        font-size: 25px;
        font-weight: 700;
        line-height: 20px;
        color: var(--var-dark-color);
        transition-duration: 0.5s;
    }

    .your_account_inner p {
        font-size: 15px;
        font-weight: 600;
        line-height: 20px;
        margin-bottom: 0px;
        color: #636363;
        transition-duration: 0.5s;
    }

    .your_account_inner:hover {
        background: var(--var-light-blue-color);
        transition-duration: 0.5s;
    }

    .your_account_inner:hover i {
        color: var(--var-white-color);
        transition-duration: 0.5s;
    }

    .your_account_inner:hover h4 {
        color: var(--var-white-color);
        transition-duration: 0.5s;
    }

    .your_account_inner:hover p {
        color: var(--var-white-color);
        transition-duration: 0.5s;
    }

    /* Your Account */

    .logout_popup .modal-content {
        box-shadow: 0px 0px 11.7px 10px #00000040;
        border-radius: 20px;
        padding-bottom: 20px;
    }

    .modal.fade.logout_popup .modal-dialog {
        transition: transform .3s ease-out;
        transform: translate(0, -50px);
        border-radius: 20px;
    }

    .logout_popup .modal-content img {
        width: 60%;
    }

    .logout_popup .modal-content h2 {
        font-size: 24px;
        font-weight: 700;
        line-height: 22px;
        color: var(--var-dark-color);
    }

    .logout_popup .modal-content p {
        font-size: 20px;
        font-weight: 700;
        line-height: 30.12px;
        color: var(--var-dark-color);
        margin-bottom: 0px;

    }

    .logout_popup .modal-footer .logout_btn {
        background: #08AAE30A;
        border: 0.5px solid #183823;
        color: var(--var-heading-color);
        text-decoration: none;
        border-radius: 10px;
        padding: 8px 30px;
        transition: 0.4s;
        height: 40px;
        line-height: 20px;
        display: inline-block;
    }

    .logout_popup .modal-footer {
        border: 0px;
        display: block;
        text-align: center;
    }

    .logout_popup .modal-footer .logout_btn:hover {
        border: 0.5px solid #183823;
        background: #183823;
        color: var(--var-white-color);
        transition: 0.4s;
    }


}

@media (max-width:992px) {
    .cart {
        .checkout_table th {
            padding: 12px 10px;
        }
    }
}

@media (max-width:767px) {
    .buyer-account {
        .shopping_cart_heading td {
            font-size: 8px;
            line-height: 10px;
            padding: 6px;
        }

        .quantity_number .btn {
            font-size: 12px;
        }

        .quantity_number .quantity {
            font-size: 12px;
            width: 20px;
        }

        .shopping_cart_heading th {
            padding: 6px;
            font-size: 12px;
        }

        .return_shop_section a {
            font-size: 10px;
            min-height: 46px;
        }

        .shopping_cart_heading table td:nth-child(3) {
            width: 15%;
        }

        .quantity_number {
            min-height: 32px;
        }
        .your_account_right.h-100 {
            padding: 0px 0px 0px 0px;
        }
    }
}

@media (max-width:575px) {
    .buyer-account {
        .your_account_right.h-100 {
            padding: 0px;
            margin-top: 25px;
        }
    }
}