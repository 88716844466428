.buyer-order-details {

    /* Your Orders */
    /* Your Orders */
    .order_section {
        position: relative;
        padding: 45px 0px;
    }

    .order_section_inner {
        position: relative;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 7px 0px;
        padding: 0px 0px 0px 0px;

        .inner_sec_heading {
            margin: 15px;
        }

        .review_section_main {
            display: flex;
            justify-content: space-between;
        }

        .review_section_main .cancel_order {
            border: 1px solid #08AAE3;
            font-size: 14px;
            font-weight: 500;
            line-height: 22.59px;
            background: rgba(8, 170, 227, 0.0705882353);
            color: var(--var-heading-color);
            text-decoration: none;
            padding: 6px 12px;
            border-radius: 19px;
            transition-duration: 0.4s;
        }

        .review_section_main .cancel_order:hover {
            background: var(--var-light-blue-color);
            color: var(--var-white-color);
            transition-duration: 0.4s;
        }
    }

   .track_order_section_1 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #E0E0E0;
        padding: 5px 10px 5px 10px;
    }

    .track_order_section_left a {
        // border: 1px solid #08AAE3;
        font-size: 18px;
        font-weight: 400;
        line-height: 22.59px;
        // background: #08AAE312;
        color: var(--var-heading-color);
        text-decoration: none;
        border-radius: 14px;
        display: inline-block;
        text-align: right;
    }

    .track_order_section_left a span {
        color: #848484;
    }

    .track_order_section_left p {
        text-align: right;
        margin-bottom: 0px;
    }

    .track_order_section_left p span {
        font-size: 14px;
        font-weight: 600;
        color: #272727;
        margin: 0px 6px 0px 6px;
    }

    .track_order_section_right a {
        font-size: 16px;
        font-weight: 600;
        line-height: 25.1px;

        color: black;
        border-radius: 14px;
        padding: 6px 12px;
        text-decoration: none;
        display: inline-block;
        margin-right: 15px;

        .heading-sm {
            font-size: 14px;
            color: #475156;
        }
    }

    .track_order_section_right a i {
        padding-right: 6px;
    }

    .track_order_details {
        display: grid;
        grid-template-columns: 12% 41% 15% 15% 15%;
        grid-column-gap: 10px;
        grid-row-gap: 10px;
        justify-content: space-between;
        padding: 15px 10px 15px 10px;
        align-items: center;

        .track_order_details_main {
            .main_date {
                margin-top: 2%;
                font-size: 14px;
                font-weight: 700;
                line-height: 20px;
                color: #191C1F;
            }

            .main_buttons {

                margin-top: 2%;

                .button-white {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    background: white;
                    color: #000000;
                    border-radius: 6px;
                    padding: 6px 12px;
                    text-decoration: none;
                    display: inline-block;
                    margin-right: 15px;
                    border: 1px solid #BDBDBD;
                    transition: 0.4s;
                }

                .button-white:hover {
                    background-color: #08AAE3;
                    color: #fff;
                    transition: 0.4s;
                }

                .button-color {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    background: var(--var-light-blue-color);
                    color: var(--var-white-color);
                    border-radius: 6px;
                    padding: 6px 12px;
                    text-decoration: none;
                    display: inline-block;
                    border: 1px #BDBDBD solid;
                    margin: 2%;
                    box-shadow: 0px 4px 4px 0px #00000040;

                }
            }

            img {
                width: 154px;
            }

            p {
                font-size: 16px;
                font-weight: 500;
                line-height: 27px;
                color: var(--var-primary-color);
                margin-bottom: 0px;


            }

            h6 {
                font-size: 16px;
                font-weight: 700;
                line-height: 20px;
                color:
                    #08AAE3;
            }
        }
    }





    .track_order_details_main h6 a {
        text-decoration: none;
        color: #08aae3;
    }

    .track_order_details_main .btn_primary {
        border-radius: 19px;
        font-size: 14px;
        text-transform: none;
        padding: 6px 12px;
    }

    .track_order_details_main .cancel_order {
        border: 1px solid #08AAE3;
        font-size: 14px;
        font-weight: 500;
        line-height: 22.59px;
        background: #08AAE312;
        color: var(--var-heading-color);
        text-decoration: none;
        padding: 6px 12px;
        border-radius: 19px;
        transition-duration: 0.4s;
    }

    .track_order_details_main .cancel_order:hover {
        background: var(--var-light-blue-color);
        color: var(--var-white-color);
        transition-duration: 0.4s;
    }

    .track_order_section_right h4 {
        font-size: 18px;
        color: #000000;
        padding: 6px 0px 0px 0px;
        font-weight: 600;
    }

    .track_order_section_right p {
        font-size: 14px;
        color: #475156;
        margin-bottom: 0px;
    }

    .order_details_content h2 {
        font-size: 20px;
        font-weight: 700;
        color: #000000;

    }

    .order_details_content {
        padding: 20px 10px 20px 12px;
    }

    .order_details_content table {
        width: 100%;
    }

    .order_details_content table td {
        font-size: 14px;
        color: #5F6C72;
        font-weight: 400;
        padding: 0px 0px 10px 0px;
    }

    .order_details_content table tr {
        margin-bottom: 5px;
    }

    .order_details_content table th {
        font-size: 16px;
        color: var(--var-dark-color);
        font-weight: 500;
        padding: 10px 0px 10px 0px;
        border-top: 1px #E4E7E9 solid;
    }

    /* Your Orders */
     .cancel_order_heading {
        display: grid;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
        grid-template-columns: 70% 30%;
    }
    .cancel_order_heading_left {
        position: relative;
    }
     .cancel_order_heading_left h2 {
        font-size: 26px;
        font-weight: 700;
        line-height: 24px;
        color: var(--var-dark-color);
    }
     .cancel_order_search {
        position: relative;
    }
     .cancel_order_search input {
        border: 1px #ddd solid;
        padding-left: 30px;
        width: 100%;
        cursor: pointer;
        border-radius: 4px;
        height: 37px;
    }
    .cancel_order_search i {
        position: absolute;
        left: 10px;
        top: 13px;
        color: #898C8C;
        font-size: 12px;
    }


}

@media (max-width:992px) {
    .buyer-order-details {
        .track_order_details {
            grid-template-columns: 18% 34% 10% 10% 13%;
        }

        .track_order_details_main .cancel_order {
            font-size: 10px;
        }

        .track_order_details_main .btn_primary {
            font-size: 10px;
        }

        .track_order_details .track_order_details_main p {
            font-size: 12px;
            line-height: 18px;
        }

        .track_order_details .track_order_details_main .main_buttons .button-white {
            font-size: 10px;
            padding: 6px 10px;
            margin-right: 8px;
        }

        .track_order_section_left a {
            font-size: 15px;
            line-height: 15.59px;
        }

        .buyer-your-orders .track_order_details .track_order_details_main h6 {
            font-size: 10px;
            line-height: 15px;
        }
    }
}

@media (max-width:767px) {
    .buyer-order-details {
        .track_order_details {
            grid-template-columns: 18% 34% 10% 10% 13%;
        }

        .track_order_details_main p {
            font-size: 10px;
            line-height: 14px;
        }

        .track_order_details_main h6 {
            font-size: 10px;
        }

        .track_order_details_main .cancel_order {
            font-size: 7px;
        }

        .track_order_details_main .btn_primary {
            font-size: 7px;
        }

        .track_order_section_right a {
            font-size: 12px;
            line-height: 19.1px;
            padding: 6px 12px;
            margin-right: 1px;
        }

        .track_order_section_left a {
            font-size: 12px;
            line-height: 8.59px;
        }

        .track_order_details .track_order_details_main h6 {
            font-size: 10px;
            line-height: 12px;
        }

        .track_order_details .track_order_details_main .main_date {
            font-size: 10px;
            line-height: 18px;
        }

        .order_section_inner .review_section_main .cancel_order {
            font-size: 9px;
            padding: 6px 10px;
        }
        .cancel_order_heading {
            grid-template-columns: 40% 57%;
        }
    }
}

@media (max-width:640px) {
    .buyer-order-details {
        .track_order_details {
            grid-template-columns: 1fr 1fr;
        }

        .track_order_section_1 {
            display: block;
        }

        .track_order_section_left p {
            text-align: left;
            margin-bottom: 10px;
        }

        .review_section_main {
            display: grid !important;
        }

        .track_order_details_main {
            text-align: left !important;
        }

        .nav-pills .nav-link {
            font-size: 9px;
        }

        .track_order_section_left p span {
            margin: 0px 6px 0px 0px;
        }

        .track_order_section_right a .heading-sm {
            font-size: 10px;
            color: #475156;
        }

        .cancel_order_heading_left h2 {
            font-size: 20px;
        }

        .cancel_order_heading_left {
            grid-template-columns: 40% 60%;
        }

        .track_order_details .track_order_details_main p {
            font-size: 10px;
            line-height: 16px;
        }
    }
}

@media (max-width:575px) {
    .buyer-order-details {
        .track_order_details {
            grid-template-columns: 1fr 1fr;
        }

        .track_order_details_main.text-center {
            text-align: left !important;
        }

        .track_order_details_main.text-end {
            text-align: left !important;
        }

        .track_order_details_main p {
            font-size: 10px;
            line-height: 16px;
        }

        .track_order_details_main h6 {
            font-size: 10px;
            line-height: 16px;
            margin-bottom: 0px;
        }

        .track_order_section_left a {
            font-size: 9px;
            display: inline;
        }

        .track_order_section_right a {
            font-size: 10px;
            display: inline-block;
            text-align: left !important;
        }

        .track_order_section_left a {
            font-size: 12px;
            padding-left: 0px;
        }

        .track_order_details .track_order_details_main .main_buttons .button-white {
            margin-bottom: 10px;
        }

        .order_details_content {
            padding: 20px 10px 5px 12px;
        }
    }
}

@media (max-width:320px) {
    .buyer-order-details {
        .track_order_details_main .cancel_order {
            font-size: 6px;
        }

        .track_order_section_left a {
            font-size: 8px;
            display: inline;
        }

        .track_order_section_right a {
            font-size: 8px;
            display: inline;
        }
    }
}