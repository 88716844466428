.buyer-add-new-address .profile_section {
  position: relative;
  padding: 45px 0px;
}
.buyer-add-new-address .profile_heading h2 {
  font-size: 30px;
  font-weight: 700;
  line-height: 24px;
  color: #000;
}
.buyer-add-new-address .profile_man_box {
  position: relative;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  padding: 20px 100px 20px 100px;
}
.buyer-add-new-address .profile_inner_box_section {
  padding: 30px 10px 30px 10px;
  background: rgba(8, 170, 227, 0.0392156863);
  border: 1px solid #08AAE3;
  border-radius: 12px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: stretch;
  justify-content: space-between;
  align-items: center;
}
.buyer-add-new-address .add_new_address {
  border: 1px solid rgba(24, 56, 35, 0.5019607843);
  background: rgba(24, 56, 35, 0.0392156863);
  position: relative;
  padding: 30px 20px 30px 20px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
.buyer-add-new-address .add-new-address_btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.buyer-add-new-address .add_new_address_btn_left a {
  background: #183823;
  color: var(--var-white-color);
  text-decoration: none;
  padding: 8px 24px 8px 24px;
  border-radius: 4px;
  margin-right: 25px;
  display: inline-block;
}
.buyer-add-new-address .add_new_address_btn_right button {
  color: var(--var-heading-color);
  text-decoration: none;
  padding: 8px 24px 8px 24px;
  border-radius: 4px;
  background: rgba(24, 56, 35, 0.0784313725);
  border: 1px solid rgba(24, 56, 35, 0.5019607843);
  display: inline-block;
}
.buyer-add-new-address .add_new_address_right_field label {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: #191C1F;
  width: 100%;
  margin-bottom: 10px;
}
.buyer-add-new-address .add_new_address_right_field .form-control {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #929FA5;
  border: 1px solid #979797;
  border-radius: 2px;
  height: 44px;
  width: 100%;
  margin-bottom: 2px !important;
  padding-left: 10px;
}
.buyer-add-new-address .add_new_address_right_field select {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #929FA5;
  border: 1px solid #979797;
  border-radius: 2px;
  height: 44px;
  width: 100%;
  margin-bottom: 2px !important;
  padding-left: 10px;
}
.buyer-add-new-address .radio_buttom_section {
  display: flex;
  justify-items: start;
  margin-bottom: 2px !important;
}
.buyer-add-new-address .radio_buttom_section .form-check:nth-child(1) {
  padding-left: 20px;
}
.buyer-add-new-address .radio_buttom_section .form-check {
  padding-left: 40px;
}
.buyer-add-new-address .form-check-input[type=radio] {
  border-radius: 0px;
}
.buyer-add-new-address .order_section {
  position: relative;
  padding: 45px 0px;
}
.buyer-add-new-address .order_section_inner {
  position: relative;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  padding: 20px 10px 20px 10px;
}/*# sourceMappingURL=BuyerAddNewAddressContent.css.map */