.wish-list {
  /* footer-sec */
}
.wish-list .heading-sec .heart-img img {
  margin-top: 8px;
  height: 20px;
}
.wish-list .heading-sec h3 {
  font-weight: 700;
  padding-left: 14px;
}
.wish-list .heading-sec h6 {
  padding: 12px;
}
.wish-list .first-card-sec {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1019607843);
}
.wish-list .first-card-sec .first-image {
  height: 100%;
}
.wish-list .first-card-sec .first-image img {
  width: 100%;
  height: 215px;
  -o-object-fit: contain;
     object-fit: contain;
}
.wish-list .first-card-sec .women-black {
  height: 100%;
}
.wish-list .women-black {
  position: relative;
}
.wish-list .delete-sec img {
  height: 20px;
}
.wish-list .delete-sec {
  position: absolute;
  bottom: 0;
  right: 0;
}
.wish-list .women-black .women {
  font-weight: 700;
  padding-bottom: 10px;
  font-size: 22px;
}
.wish-list .women-black h4 {
  font-weight: 600;
  font-size: 22px;
}
.wish-list .women-black .order-place {
  padding-top: 5px;
}
.wish-list .women-black .order-place h6 {
  font-weight: 700;
}
.wish-list .women-black .order-place h6 span {
  font-weight: 500;
  color: grey;
  padding-left: 6px;
}
.wish-list .women-black .view-sec a {
  color: #08AAE3;
  text-decoration: none;
}
.wish-list .women-black .add-btn .add-cart button {
  background: transparent;
  padding: 6px 10px;
  border: 1px solid #183823;
  border-radius: 5px;
  color: #000;
  transition-duration: 0.4s;
}
.wish-list .women-black .add-btn .add-cart button:hover {
  background: #183823;
  color: #fff;
  transition-duration: 0.4s;
}
.wish-list .women-black .add-btn button {
  margin-top: 20px;
  color: #08AAE3;
  background: transparent;
  border: none;
}
.wish-list .footer-sec {
  background-color: #00071B;
  color: #fff;
}
.wish-list .logo-sec img {
  margin-left: 6%;
  margin-bottom: 2%;
}
.wish-list .search-sec {
  text-align: left;
  position: relative;
}
.wish-list .search-sec input {
  background: transparent;
  border: 1px solid #fff;
  border-radius: 2px;
  padding: 2px 7px;
  color: #fff;
  padding-left: 30px;
}
.wish-list .mail-box-sec {
  position: absolute;
  left: 5px;
}
.wish-list .footer-menu h6 {
  font-size: 18px;
}
.wish-list .footer-menu ul {
  list-style: none;
  padding: 0;
}
.wish-list .footer-menu ul li {
  font-size: 14px;
  padding-top: 8px;
}
.wish-list .media-icons img {
  padding-left: 15px;
}
.wish-list .delete_btn {
  position: relative;
  height: 100%;
}
.wish-list .delete_btn a {
  position: absolute;
  bottom: 0px;
  right: 0px;
  color: #ff0000;
  font-size: 22px;
}

@media (max-width: 640px) {
  .wish-list .wish_list_section .px-5 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .wish-list .wish_list_section .d-flex {
    align-items: center;
  }
  .wish-list .wish_list_section .order-place h6 {
    font-size: 14px;
  }
  .wish-list .wish_list_section h3 {
    font-size: 14px;
    margin-bottom: 0px;
  }
  .wish-list .wish_list_section .heading-sec .heart-img img {
    margin-top: 0px;
    height: inherit;
    width: 20px;
  }
  .wish-list .wish_list_section .heading-sec h6 {
    padding: 8px 0px 0px 6px;
    font-size: 12px;
  }
}/*# sourceMappingURL=BuyerWishlistContent.css.map */