.cart {

    h1 {
        font-size: 36px;
    }

    .btn_primary {
        text-decoration: none;
        background: var(--var-light-blue-color);
        color: var(--var-white-color);
        text-transform: uppercase;
        padding: 10px 16px;
    }

    .btn_primary:hover {
        background: var(--var-light-background-color);
        color: var(--var-white-color);
    }

    input,
    select {
        outline: transparent;
    }


    /* Shopping Cart */
    .shopping_cart {
        position: relative;
        padding: 45px 0px 10px;
    }

    .shopping_cart_heading table {
        width: 100%;
    }

    .shopping_cart_heading th {
        background: #D9D9D9;
        padding: 12px;
        height: 48px;
    }

    .checkout_table th {
        padding: 12px 45px;
    }

    .shopping_cart_heading td {
        text-align: center;
        padding: 12px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;


    }

    .shopping_cart_heading table td:nth-child(1) {
        width: 4%;
    }

    .shopping_cart_heading table td:nth-child(2) {
        width: 10%;

    }

    .red_circle {
        color: #EE5858;
    }

    .shopping_cart_heading table td:nth-child(3) {
        width: 25%;
    }

    .checkout_table table td:nth-child(1) {
        width: 10%;
    }

    .checkout_table table td:nth-child(2) {
        width: 25%;
    }

    .shop-page-table p {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: var(--var-primary-color);
    }

    .shopping_cart_heading table td:nth-last-child(1) {
        color: #191C1F;
        font-weight: 500;
    }

    .shopping_cart_heading h2 {
        font-size: 24px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 10px;

    }

    .return_shop_section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px #ddd solid;
        padding: 25px 0px;
        margin-top: 15px;
    }

    .return_shop_section a {
        color: var(--var-light-blue-color);
        text-align: center;
        text-decoration: none;
        width: 100%;
        display: block;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        padding: 12px 20px;
        min-height: 40px;
        border: 1px var(--var-light-blue-color) solid;
        transition-duration: 0.4s;
        border-radius: 4px;
    }

    .return_shop_section a:hover {
        background-color: var(--var-light-blue-color);
        color: var(--var-white-color);
        transition-duration: 0.4s;
    }

    .shop-page-table h2 {
        font-size: 24px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 10px;

    }

    .checkout_page_inner {
        padding: 5px 20px 5px 20px;
    }

    .checkout_page h4 {
        background: #D9D9D9;
        width: 100%;
        font-size: 20px;
        font-weight: 600;
        color: var(--var-primary-color);
        height: 48px;
        line-height: 48px;
        padding-left: 10px;
    }

    .shop-page-table table {
        width: 100%;
    }

    .shop-page-table table tr {
        margin-bottom: 5px;
    }

    .shop-page-table table td {
        font-size: 14px;
        color: #5F6C72;
        font-weight: 400;
        padding: 0px 0px 10px 0px;
    }

    .shop-page-table table td strong {
        color: var(--var-primary-color);
        font-weight: 500;
    }

    .shop-page-table table th {
        font-size: 16px;
        color: var(--var-dark-color);
        font-weight: 500;
        padding: 10px 0px 10px 0px;
        border-top: 1px #E4E7E9 solid;
    }

    /* Shipping Address */
    .shipping_address h2 {
        font-size: 24px;
        font-weight: 700;
        line-height: 24px;
        color: var(--var-primary-color);
    }

    .shipping_address h2 span a {
        color: #a3a3a3;
        font-size: 18px;
        text-decoration: none;
    }

    .shipping_address h5 {
        font-size: 18px;
        font-weight: 700;
        line-height: 20px;
        color: var(--var-primary-color);
    }

    .shipping_address p {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }

    .shipping_address h6 {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        color: var(--var-primary-color);
    }

    .shipping_address h6 span {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #5F6C72;
    }

    .shipping_address h3 a {
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        color: #191C1F;
        position: relative;
        text-decoration: none;
        vertical-align: middle;
    }

    .shipping_address h3 a i {
        color: var(--var-light-blue-color);
        border: 1px var(--var-light-blue-color) solid;
        width: 25px;
        height: 25px;
        text-align: center;
        line-height: 25px;
        border-radius: 50%;
    }

    /* Cart Items */
    .cart_items {
        position: relative;
        padding: 10px 0px 45px;
    }

    .cart_items_heading {
        border-bottom: 1px solid #E4E7E9;
        margin: 25px 0px;
    }

    .cart_items_heading h3 {
        font-size: 24px;
        font-weight: 700;
        line-height: 24px;
        color: var(--var-primary-color);
    }

    .cart_items_heading p {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: var(--var-primary-color);
    }

    .cart_details_inner {
        box-shadow: 0px 0px 30px 0px #0000000D;
        border: 0;
        border-radius: 10px;
    }

    .cart_details_inner img {
        object-fit: contain;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        width: 360px;
        height: 240px;
        text-align: center;
        margin: 0 auto;
    }

    .cart_details_inner {
        text-align: center;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1607843137);
        overflow: hidden;
        border: 1px #ddd solid;
        margin-bottom: 25px;
    }

    .slider .slick-list {
        padding: 0 10% 0 20px !important;
    }

    .cart_details_inner h5 {
        font-size: 18px;
        font-weight: 700;
        line-height: 22.59px;
        color: var(--var-primary-color);
        text-align: left;
    }

    .cart_details_inner h6 {
        font-size: 16px;
        font-weight: 400;
        color: var(--var-heading-color);
        line-height: 20px;
        text-align: left;
    }

    .cart_details_inner h6 span {
        color: #848484;
        font-family: "Libre Baskerville", serif;
    }

    .cart_details_inner h2 {
        font-size: 18px;
        font-weight: 700;
        color: var(--var-heading-color);
        line-height: 30px;
        text-align: left;
    }

    .cart_details_inner h2 span {
        color: #848484;
        font-size: 14px;
        font-weight: 400;
    }

    .cart_details_inner h2 strong {
        color: var(--var-green-color);
        font-size: 14px;
        font-weight: 700;
    }

    .cart_info_box {
        box-shadow: 0px 4px 4px 0px #00000029;
        overflow: hidden;
        border: 1px #ddd solid;
        margin-bottom: 25px;
    }

    .cart_info_btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .cart_info_btn_left button {
        display: inline-block;
        padding: 0px 8px 0px 0px;
    }

    .cart_info_btn_right a {
        background-color: var(--var-light-blue-color);
        color: var(--var-white-color);
        text-decoration: none;
        padding: 8px 20px;
        border-radius: 20px;
        cursor: pointer;
    }

    .cart_info_btn_right a:hover {
        color: #fff;
    }

    .cart_info_btn_left img {
        width: 20px;
    }

    .cart_info_btn_left i {
        font-size: 20px;
    }

    .cart_info_btn_left button {
        color: var(--var-green-color);
        border: 0px;
        background: transparent; 
    }
.share_btn button{
    margin: 0px 5px 0px 5px;
}
.share_btn button svg{
    width: 30px;
    height: 30px;
}
.share_btn1{
    background-color: #183823 !important;
    border: 0px;
}
.modal-body.share_btn {
    padding: 35px 20px 45px 20px;
}
.share_popup .modal-dialog {
    box-shadow: none;
    overflow: hidden;
}
    /*Check Out*/

.order-place h3 {
    color: #08AAE3;
}

.order-image img {
    height: 320px;
}

    .continue-btn button {
        border: none;
        padding: 8px 38px;
        font-size: 18px;
        border-radius: 10px;
        color: #fff;
        background: linear-gradient(92.83deg, #08AAE3 -74.66%, #FF9811 343.14%);
        position: relative;
        transition-duration: 0.4s;
    }

    .continue-btn button:hover {
        background: #252f3d;
        transition-duration: 0.4s;
    }

    .modal-dialog {
        box-shadow: 0px 0px 5.5px 5px #00000040;
        overflow: hidden;
    }

    .order-place h5 {
        font-size: 20px;
        font-weight: 700;
        line-height: 20px;
        color: #272727;
    }

    .shipping-sec h5 {
        font-size: 20px;
        font-weight: 700;
        line-height: 20px;
        color: #272727;
    }

    .slick-slide {
        padding: 0px 30px 0px 0px;
    }

    /* Checkout Css */
    .order-place h3 {
        color: #08AAE3;
    }

    .order-image img {
        height: 320px;
    }

    .thank-you button {
        border: none;
        padding: 8px 16px;
        border-radius: 10px;
        color: #fff;
        background: linear-gradient(92.83deg, #08AAE3 -74.66%, #FF9811 343.14%);
    }

    .modal-dialog {
        box-shadow: 0px 0px 5.5px 5px #00000040;
        overflow: hidden;
    }


    // breadcrumb
    .breadcrumb_section {
        position: relative;
        background-color: #EDEDED;
        box-shadow: 0px 4px 4px 0px #00000040;
        padding: 10px 0px;
    }

    .breadcrumb_section_content h4 {
        font-size: 14px;
        color: var(--var-primary-color);

    }

    .breadcrumb_section_content ul {
        list-style-type: none;
        padding-left: 0px;
        margin-bottom: 0px;
    }

    .breadcrumb_section_content ul li {
        display: inline-block;
        font-size: 14px;
        font-weight: 400;
        padding: 0px 4px 0px 0px;
    }

    .breadcrumb_section_content ul li a {
        text-decoration: none;
        color: #5F6C72;

    }

    .breadcrumb_section_content ul li:nth-child(1) a {
        text-decoration: none;
        color: #5F6C72 !important;
    }

    .breadcrumb_section_content ul li:nth-last-child(1) a {
        text-decoration: none;
        color: var(--var-light-blue-color);
    }


    .quantity_number {
        display: flex;
        border-radius: 0px;
        justify-content: space-between;
        border: 1px #ddd solid;
        min-height: 40px;
        border-radius: 4px;
    }

    .quantity_number .btn {
        cursor: pointer;
        padding: 4px 6px 4px 6px;
    }

    .quantity_number .quantity {
        border: none;
        text-align: center;
        font-size: 16px;
        color: #43484D;
        font-weight: 300;
        background: transparent;
        width: 25px;
    }

    .quantity_number .btn {
        cursor: pointer;
        padding: 4px 6px 4px 6px;
        font-size: 22px;
    }

    .quantity_section {
        display: grid;
        grid-template-columns: 27% 50% 20%;
        align-items: center;
        grid-column-gap: 10px;
        grid-row-gap: 10px;
        margin-top: 25px;
        justify-content: space-between;
    }


    .add_to_cart a {
        background-color: #183823;
        color: var(--var-white-color);
        text-align: center;
        text-decoration: none;
        width: 100%;
        display: block;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        padding: 12px 0px;
        min-height: 36px;
        border-radius: 4px;
    }

    .add_to_cart a:hover {
        color: #fff;
    }

    .order-place h3 {
        color: #08AAE3;
        font-size: 30px;
        font-weight: 700;
    }

}


@media (max-width:992px) {
    .cart {
        .checkout_table th {
            padding: 12px 10px;
        }

        .cart_details_inner img {
            object-fit: contain;
            width: 360px;
            height: 240px;

        }
    }
}

@media (max-width:767px) {
    .cart {
        .shipping_address h3 a {

            margin-bottom: 25px;
            display: inline-block;
        }
    }
}

@media (max-width:640px) {
    .cart {
        .slick-slider {
            margin-right: 20px;
            margin-left: 0px;
            padding: 0px 20px 0px 20px;
        }
    }
}

@media (max-width:576px) {
    .cart {
        .shopping_cart_heading td {
            font-size: 8px;
            line-height: 10px;
            padding: 6px;
        }

        .shopping_cart_heading.checkout_table th {
            padding: 6px !important;
        }

        .quantity_number .btn {
            font-size: 12px;
        }

        .quantity_number .quantity {
            font-size: 12px;
            width: 20px;
        }

        .shopping_cart_heading th {
            padding: 6px;
            font-size: 12px;
        }

        .return_shop_section a {
            font-size: 10px;
            min-height: 40px;
        }

        .shopping_cart_heading table td:nth-child(3) {
            width: 15%;
        }

        .quantity_number {
            min-height: 32px;
        }
    }
}