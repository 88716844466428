.return-order {
  /* Shopping Cart */
  /* Cancel Order */
  /* Shopping Cart */
  /* Return Order */
  /* Return Order */
}
.return-order .shopping_cart {
  position: relative;
  padding: 45px 0px 10px;
}
.return-order .shopping_cart_heading table {
  width: 100%;
}
.return-order .shopping_cart_heading th {
  background: #D9D9D9;
  padding: 12px;
  height: 48px;
}
.return-order .checkout_table th {
  padding: 12px 45px;
}
.return-order .shopping_cart_heading td {
  text-align: center;
  padding: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.return-order .shopping_cart_heading table td:nth-child(1) {
  width: 4%;
}
.return-order .shopping_cart_heading table td:nth-child(2) {
  width: 10%;
}
.return-order .order_section {
  position: relative;
  padding: 45px 0px;
}
.return-order .order_section_inner {
  position: relative;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  padding: 20px 10px 20px 10px;
}
.return-order .track_order_section_1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px #ddd solid;
  padding-bottom: 20px;
}
.return-order .track_order_section_left a {
  border: 1px solid #183823;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.59px;
  background: rgba(8, 170, 227, 0.0705882353);
  color: var(--var-heading-color);
  text-decoration: none;
  padding: 6px;
  border-radius: 14px;
  display: inline-block;
}
.return-order .track_order_section_left a span {
  color: #848484;
  line-break: anywhere;
}
.return-order .track_order_section_right a {
  font-size: 18px;
  font-weight: 600;
  line-height: 25.1px;
  background: var(--var-light-blue-color);
  color: var(--var-white-color);
  border-radius: 14px;
  padding: 6px 12px;
  text-decoration: none;
  display: inline-block;
}
.return-order .track_order_section_right a i {
  padding-right: 6px;
}
.return-order .track_order_details {
  display: grid;
  grid-template-columns: 7% 24% 15% 15% 15% 20%;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: space-between;
  padding: 15px 10px 15px 10px;
  align-items: center;
}
.return-order .track_order_details_main p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--var-primary-color);
  margin-bottom: 0px;
}
.return-order .track_order_details_main h6 {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: var(--var-primary-color);
}
.return-order .track_order_details_main h6 a {
  text-decoration: none;
  color: #08aae3;
}
.return-order .track_order_details_main .btn_primary {
  border-radius: 19px;
  font-size: 14px;
  text-transform: none;
  padding: 6px 12px;
}
.return-order .track_order_details_main .cancel_order {
  border: 1px solid #08AAE3;
  font-size: 14px;
  font-weight: 500;
  line-height: 22.59px;
  background: rgba(8, 170, 227, 0.0705882353);
  color: var(--var-heading-color);
  text-decoration: none;
  padding: 6px 12px;
  border-radius: 19px;
  transition-duration: 0.4s;
}
.return-order .track_order_details_main .cancel_order:hover {
  background: var(--var-light-blue-color);
  color: var(--var-white-color);
  transition-duration: 0.4s;
}
.return-order .cancel_order_heading {
  display: grid;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  grid-template-columns: 70% 30%;
}
.return-order .cancel_order_heading_left {
  position: relative;
}
.return-order .cancel_order_heading_left h2 {
  font-size: 30px;
  font-weight: 700;
  line-height: 24px;
  color: var(--var-dark-color);
}
.return-order .cancel_order_search {
  position: relative;
}
.return-order .cancel_order_search input {
  border: 1px #ddd solid;
  padding-left: 30px;
  width: 100%;
  cursor: pointer;
  border-radius: 4px;
  height: 37px;
}
.return-order .cancel_order_search i {
  position: absolute;
  left: 10px;
  top: 13px;
  color: #898C8C;
  font-size: 12px;
}
.return-order .shopping_cart {
  position: relative;
  padding: 45px 0px 10px;
}
.return-order .shopping_cart_heading table {
  width: 100%;
}
.return-order .shopping_cart_heading th {
  background: #D9D9D9;
  padding: 12px;
  height: 48px;
}
.return-order .checkout_table th {
  padding: 12px 45px;
}
.return-order .shopping_cart_heading td {
  text-align: center;
  padding: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.return-order .shopping_cart_heading table td:nth-child(1) {
  width: 4%;
}
.return-order .shopping_cart_heading table td:nth-child(2) {
  width: 10%;
}
.return-order .red_circle {
  color: #EE5858;
}
.return-order .shopping_cart_heading table td:nth-child(3) {
  width: 25%;
}
.return-order .checkout_table table td:nth-child(1) {
  width: 10%;
}
.return-order .checkout_table table td:nth-child(2) {
  width: 25%;
}
.return-order .shop-page-table p {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--var-primary-color);
}
.return-order .shopping_cart_heading table td:nth-last-child(1) {
  color: #191C1F;
  font-weight: 500;
}
.return-order .shopping_cart_heading h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 10px;
}
.return-order .return_shop_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px #ddd solid;
  padding: 25px 0px;
  margin-top: 15px;
}
.return-order .return_shop_section a {
  color: var(--var-light-blue-color);
  text-align: center;
  text-decoration: none;
  width: 100%;
  display: block;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 15px 20px;
  min-height: 56px;
  border: 1px var(--var-light-blue-color) solid;
  transition-duration: 0.4s;
}
.return-order .return_shop_section a:hover {
  background-color: var(--var-light-blue-color);
  color: var(--var-white-color);
  transition-duration: 0.4s;
}
.return-order .shop-page-table h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 10px;
}
.return-order .checkout_page_inner {
  padding: 5px 20px 5px 20px;
}
.return-order .checkout_page h4 {
  background: #D9D9D9;
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  color: var(--var-primary-color);
  height: 48px;
  line-height: 48px;
  padding-left: 10px;
}
.return-order .shop-page-table table {
  width: 100%;
}
.return-order .shop-page-table table tr {
  margin-bottom: 5px;
}
.return-order .shop-page-table table td {
  font-size: 14px;
  color: #5F6C72;
  font-weight: 400;
  padding: 0px 0px 10px 0px;
}
.return-order .shop-page-table table td strong {
  color: var(--var-primary-color);
  font-weight: 500;
}
.return-order .shop-page-table table th {
  font-size: 18px;
  color: var(--var-dark-color);
  font-weight: 700;
  padding: 10px 0px 10px 0px;
  border-top: 1px #E4E7E9 solid;
  border-bottom: 1px #E4E7E9 solid;
}
.return-order .delivery_payment {
  text-transform: capitalize;
  margin-top: 10px;
  font-weight: 700;
  font-size: 20px;
}
.return-order .quantity_number {
  display: flex;
  border-radius: 0px;
  justify-content: space-between;
  border: 1px #ddd solid;
  min-height: 40px;
  border-radius: 4px;
}
.return-order .quantity_number .btn {
  cursor: pointer;
  padding: 4px 6px 4px 6px;
}
.return-order .quantity_number .quantity {
  border: none;
  text-align: center;
  font-size: 16px;
  color: #43484D;
  font-weight: 300;
  background: transparent;
  width: 25px;
}
.return-order .quantity_number .btn {
  cursor: pointer;
  padding: 4px 6px 4px 6px;
  font-size: 26px;
}
.return-order .return_order_form .return_order_form_inner {
  display: grid;
  margin-bottom: 25px;
  float: left;
  width: 100%;
  grid-template-columns: 20% 80%;
}
.return-order .return_order_form {
  padding-left: 45px;
}
.return-order .return_order_form .return_order_form_inner span {
  float: left;
  font-size: 22px;
  font-weight: 600;
  line-height: 24px;
}
.return-order .return_order_form select {
  height: 44px;
  padding: 12px 16px 12px 16px;
  border-radius: 4px;
  border: 1px var(--var-light-blue-color) solid;
  width: 100%;
}
.return-order .return_order_form input {
  height: 44px;
  padding: 12px 16px 12px 16px;
  border-radius: 4px;
  border: 1px var(--var-light-blue-color) solid;
  width: 100%;
}
.return-order .return_order_form textarea {
  padding: 12px 16px 12px 16px;
  border-radius: 4px;
  border: 1px var(--var-light-blue-color) solid;
  width: 100%;
}
.return-order textarea {
  outline: none;
}
.return-order .return_order_form p span {
  width: 20%;
  float: left;
  font-size: 22px;
  font-weight: 600;
  line-height: 24px;
}
.return-order .return_order_form .custom-file {
  border-radius: 4px;
  border: 1px var(--var-light-blue-color) solid;
  width: 100%;
  display: block;
  float: left;
}
.return-order .return_order_form_inner_btn button {
  background: #183823;
  color: #fff;
  border: 0px;
  border-radius: 6px;
  padding: 6px 12px;
}
.return-order .return_order_form_inner label {
  font-size: 14px;
  width: 100%;
  text-align: center;
  padding: 30px 12px;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.37px;
}
.return-order .return_order_form_inner label i {
  font-size: 45px;
  display: block;
  color: var(--var-light-blue-color);
  margin-bottom: 10px;
}
.return-order .custom-file-input {
  cursor: pointer;
  display: none;
}
.return-order .return_order_form_inner a {
  background: #183823;
  display: inline-block;
  color: #fff;
  text-decoration: none;
  padding: 10px 70px;
  font-size: 24px;
  font-weight: 700;
  border-radius: 10px;
}
.return-order .order_details .fa-plus {
  color: #a3a3a3;
  width: 30px;
  height: 30px;
  border: 1px #a3a3a3 solid;
  border-radius: 50%;
  line-height: 30px;
  text-align: center;
  font-size: 22px;
}
.return-order .order_details .fa-trash {
  color: #a3a3a3;
}

@media (max-width: 992px) {
  .return-order .return_order_form .return_order_form_inner span {
    font-size: 17px;
    line-height: 22px;
  }
}
@media (max-width: 575px) {
  .return-order .return_order_form .return_order_form_inner span {
    font-size: 17px;
    line-height: 22px;
  }
  .return-order .checkout_table th {
    padding: 4px 17px;
    font-size: 14px;
  }
  .return-order .return-order .checkout_page h4 {
    font-size: 14px;
    height: 40px;
    line-height: 40px;
  }
  .return-order .return_order_form .return_order_form_inner {
    grid-template-columns: 1fr;
  }
  .return-order .return_order_form {
    padding-left: 0px;
  }
}/*# sourceMappingURL=BuyerReturnOrderContent.css.map */