body {
    font-family: "Mulish", sans-serif !important;
}



.noscroll {
    overflow: hidden !important;
}

.noscroll::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    background: #0000003f;
    z-index: 1;
}

.noscroll1 {
    overflow: hidden !important;
}

.noscroll1::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    background: #0000003f;
    z-index: 1;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
    --bs-gutter-x: 3rem !important;
}
.login .container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
    --bs-gutter-x: 1.5rem !important;
}
    .breadcrumb_section {
        position: relative;
        background-color: #EDEDED;
        box-shadow: 0px 4px 4px 0px #00000040;
        padding: 10px 0px;
    }

    .breadcrumb_section_content h4 {
        font-size: 14px;
        color: var(--var-primary-color);

    }

    .breadcrumb_section_content ul {
        list-style-type: none;
        padding-left: 0px;
        margin-bottom: 0px;
    }

    .breadcrumb_section_content ul li {
        display: inline-block;
        font-size: 14px;
        font-weight: 400;
        padding: 0px 4px 0px 0px;
    }

    .breadcrumb_section_content ul li a {
        text-decoration: none;
        color: #5F6C72;
    }

    .breadcrumb_section_content ul li:nth-child(1) a {
        text-decoration: none;
        color: #5F6C72 !important;
    }

    .breadcrumb_section_content ul li:nth-last-child(1) a {
        text-decoration: none;
        color: var(--var-light-blue-color);
    }
.pagination-section .pagination{
    height: auto !important;
}
.pagination-section .pagination li {
    line-height: 32px;
    width: 35px;
    height: 35px;
    border: 0;
    border: 1px var(--var-light-blue-color) solid;
    border-radius: 50px;
    font-weight: 600;
    background-position: 0 -45px;
    transition: all 0.5s ease-in-out;
    background: transparent;
    text-align: center;
    background-color: #00000008;
    margin: 0px 5px 0px 5px;
}
.pagination-section .pagination li a{
    text-decoration: none;
    color: #222;
}
.pagination-section .pagination li.active{
    background: var(--var-light-blue-color);
    background-repeat: no-repeat;
    color: #fff;
    background-position: 0 0;
    font-weight: 600;
}
.pagination-section .pagination li.active a{
    color: #fff;
}

.rated {
    color: #fdbf05;
  }

  .filled-heart {
    color: rgb(228, 22, 22) !important;
  }
.signin-goog-login-cls svg {
    width: 20px !important;
    height: 20px !important;
}

   /* Slider wrapper */
   .slider {
    width: 100%;
    overflow: hidden;
    /* Ensure items are inline-flex for horizontal display */
}

/* Slick Slider Item */
.slider__item {
    display: inline-flex;

    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding: 0px 30px 0px 0px;
}

/* Ensure padding is consistent */


/* Custom Slider */
.custom-slider {
    width: 100%;
    margin: auto;
}

/* Slick Slider Navigation */
.slick-prev,
.slick-next {
    position: absolute;
    line-height: 0;
    top: 50%;
    width: 30px;
    height: 30px;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    border-radius: 50px;
    background: #043e46;
    display: none !important;
    /* Remove this if you want to display arrows */
}

.slick-next {
    right: -30px;
}

.slick-prev {
    left: -30px;
}

.slick-next:before {
    content: '\003e';
    font-size: 1.2em;
    font-weight: 1000;
    padding-left: 12px;
    color: white;
}

.slick-prev:before {
    content: '\003c';
    font-size: 1.2em;
    font-weight: 1000;
    padding-left: 9px;
    color: white;
}

.slick-slider {
    margin: 0 -15px;
}

/* Ensure Slick Track and Slides are displayed correctly */
.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.slick-slide {
    display: inline-block;
    float: none !important;
    /* Ensures no float styling affects it */
    height: auto !important;
    /* Ensures auto height */
}

/* Prevent user selection on the slider */
.slick-slider {
    user-select: none;
}

/* Optional: adjust padding/margins as needed */
.slider .slick-list {
    padding: 0 10% 0 15px !important;
    ;
}

.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
}



/* Optional: to always show navigation buttons */
.slick-prev,
.slick-next {
    display: block !important;
}
.empty_data {
    font-size: 22px;
    color: #000;
    font-weight: 600;
}

@media (min-width:1450px){
    .container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
    max-width: 1320px;
    position: relative;
}
}

.line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }

  .filled-star {
    color: gold;
}

.empty-star {
    color: lightgray;
}
